// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { PropTypes } from "prop-types";
import { Popover, PopoverBody } from "reactstrap";

import CardRarityImage from "./CardRarityImage";
import CardImage from "./CardImage";

// -----------------------------------------------------------------------------
// REVIEW
export default class CardRarityImagePopover extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			popoverOpen: false
		};
	}

	toggle() {
		console.error("toggle " + this.props.cardId);
		this.setState({
			popoverOpen: !this.state.popoverOpen
		});
	}

	render() {
		const _deckLocation = this.props.deckLocation ? this.props.deckLocation : "none";
		const id = "crpo-" + _deckLocation + "-" + this.props.cardId + this.props.cardVariation;

		return (
			<span style={{ cursor: "pointer" }}>
				<CardRarityImage id={id} onClick={this.toggle} cardId={this.props.cardId} style={this.props.style} size={this.props.size} rarity={this.props.rarity} />
				<Popover placement="right" isOpen={this.state.popoverOpen} target={id} toggle={this.toggle} onClick={this.toggle}>
					<PopoverBody>
						<CardImage cardId={this.props.cardId} multiface={this.props.multiface} cardVariation={this.props.cardVariation} />
					</PopoverBody>
				</Popover>
			</span>);
	}
}

CardRarityImagePopover.propTypes = {
	cardId: PropTypes.number.isRequired,
	multiface: PropTypes.number,
	cardVariation: PropTypes.string,
	rarity: PropTypes.string,
	size: PropTypes.string,
	style: PropTypes.object,
	deckLocation: PropTypes.string.isRequired
};