// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// -----------------------------------------------------------------------------
// REVIEW
const LinkToDeckSearchResults = (props) => (
	<Link to={getSearchUrl(props)}>
		{(props.children) ? props.children : "<missing>"}
	</Link>
);

LinkToDeckSearchResults.propTypes = {
	children: PropTypes.node.isRequired,
	formatIdFilter: PropTypes.string,
	locationFilter: PropTypes.string
};

export default LinkToDeckSearchResults;

// -----------------------------------------------------------------------------
function getSearchUrl(props) {
	const path = "/decks";
	let search;

	if (props.formatIdFilter ||
		props.locationFilter) {

		if (props.formatIdFilter) {
			search = addSearchParameter(search, "formats=" + encodeURIComponent(props.formatIdFilter));
		}

		if (props.locationFilter) {
			search = addSearchParameter(search, "location=" + encodeURIComponent(props.locationFilter));
		}
	}

	return { pathname: path, search: search };
}

function addSearchParameter(existingSearchParameters, newParameter) {
	if (!existingSearchParameters) {
		return `?${newParameter}`;
	} else {
		return `${existingSearchParameters}&${newParameter}`;
	}
}