// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

// -------------------------------------------------------------------------------------------------
// Parse date string or number into native date object.
// REVIEW
export function parseDate(value) {
	if (!value) {
		return null;
	} else if (typeof (value) === "number") {
		return new Date(value);
	} else if (typeof (value) === "string") {
		if (value.startsWith("/Date(") && value.endsWith(")/")) {
			const numberOfMilliseconds = value.substring(6, value.length - 2);
			return new Date(parseInt(numberOfMilliseconds));
		} else {
			return new Date(value);
		}
	} else {
		return null;
	}
}