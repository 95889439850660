// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import { logInternalError } from "./logging";

// -------------------------------------------------------------------------------------------------
// Parse browser URL search parameters.
// REVIEW
export function parseUrlSearchParameters(url) {
	if (!url || url.length === 0) {
		return {};
	} else if (url[0] !== "?") {
		logInternalError("Invalid URL search parameters: " + url);
		return {};
	}

	// Remove prepended ?
	const parameters = url.slice(1).split("&");
	let result = {};

	parameters.forEach((value) => {
		if (value) {
			let pair = value.split("=");

			if (pair[0]) {
				result[pair[0]] = decodeURIComponent(pair[1] || "");
			} else {
				logInternalError("Missing URL search key: " + value);
			}
		}
	});

	return JSON.parse(JSON.stringify(result));
}