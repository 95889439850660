// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
//import { useQuery } from "react-query";
import { Helmet } from "react-helmet";

import PageErrorBoundary from "global/errors/PageErrorBoundary";
//import LoadingSpinner from "global/loading/LoadingSpinner";

//import { constructGetTournamentsUrl } from "api";

import HomePageMetagamePanel from "./Old.HomePageMetagamePanel";
import HomePageTournamentsPanel from "./Old.HomePageTournamentsPanel";
import HomePageTopCardsPanel from "./HomePageTopCardsPanel";
//import HomePagePricesPanel from "./Old.HomePagePricesPanel";
import { getCurrentDateIso8601 } from "global/utilities/Date";

//async function getTournaments() {
//	const response = await fetch(constructGetTournamentsUrl());
//	const data = await response.json();
//	return data;
//}

//async function getCard() {
//	const response = await fetch(getCardApi(651820));
//	const data = await response.json();
//	return data;
//}

// -----------------------------------------------------------------------------
// REVIEW
// FUTURE: Add advert elements
// https://medium.com/better-programming/diving-into-react-suspense-render-as-you-fetch-for-rest-apis-bf2d20ea7c59
// https://github.com/dai-shi/react-suspense-fetch/tree/master/examples/06_reactlazy/src
// https://github.com/topics/react-suspense
// https://github.com/CharlesStover/fetch-suspense
// https://github.com/CharlesStover/fetch-suspense/blob/master/src/fetch-suspense.ts
// https://medium.com/@Charles_Stover/react-suspense-with-the-fetch-api-a1b7369b0469
// https://css-tricks.com/react-suspense-in-practice/
function HomePage() {
	//const getTournamentsUrl = constructGetTournamentsUrl(apiFilterParameters);
	//const tournamentsResource = fetchResource(getTournamentsUrl);
	//const tournaments = useQuery(0, getTournaments);
	//const card = prefetch(getCard, []);
	return (
		<PageErrorBoundary>
			<Container>
				{/* <div>Ad</div> */}
				<Row>
					<Col>
						<Breadcrumbs />
					</Col>
				</Row>

				<HomePageMetagamePanel />

				{/* <div>Ad</div> */}
				<HomePageTopCardsPanel />

				{/* <div>Ad</div> */}
				<HomePageTournamentsPanel />

				{/* <div>Ad</div> */}
				{/*<HomePagePricesPanel />*/}

			</Container>
		</PageErrorBoundary>
	);
}

export default HomePage;

// -----------------------------------------------------------------------------
function Breadcrumbs() {
	const rootDomain = `${window.location.protocol}//${window.location.hostname}`;
	const title = "MTG Top Decks | Magic the Gathering";
	const shortTitle = "MTG Top Decks";
	const description = "Premier Magic: The Gathering content and analysis, for fans, by fans";
	const date = getCurrentDateIso8601();
	const breadcrumbsLdJson = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement:": [{
			"@type": "ListItem",
			"position": 1,
			"name": "Home"
		}]
	};

	// TODO: Add potential action for action, once added
	const pageLdJson = {
		"@context": "https://schema.org",
		"@type": "WebSite",
		"url": rootDomain,
		"description": description
	};

	return (
		<>
			<Helmet>
				<title>{title}</title>
				<meta name="description" content={description} />
				<meta property="og:title" content={shortTitle} />
				<meta property="og:description" content={description} />
				<meta property="og:url" content={window.location.href} />
				<meta property="og:modified_time" content={date} />
				<meta property="og:site_name" content="MTG Top Decks" />
				<script type="application/ld+json">{JSON.stringify(breadcrumbsLdJson)}</script>
				<script type="application/ld+json">{JSON.stringify(pageLdJson)}</script>
			</Helmet>

			<Breadcrumb>
				<BreadcrumbItem active>Home</BreadcrumbItem>
			</Breadcrumb>
		</>
	);
}