// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React, { Suspense } from "react";
import { PropTypes } from "prop-types";
import { useLocation } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Table } from "reactstrap";
import { Helmet } from "react-helmet";

import PageErrorBoundary from "global/errors/PageErrorBoundary";
import ErrorLoadingSection from "global/errors/ErrorLoadingSection";
import LoadingSpinner from "global/loading/LoadingSpinner";
import ComingSoonPage from "global/errors/ComingSoonPage";
import Price from "global/utilities/Price";
import LinkToHomePage from "common/links/LinkToHomePage";
import LinkToCard from "common/links/LinkToCard";
import CardRarityImagePopover from "common/CardRarityImagePopover";

import { constructGetCardsUrl } from "api";
import { cardDisplaySummarySchema } from "apiSchemas";
import { fetchResource } from "global/loading/fetchResource";
import { parseUrlSearchParameters } from "global/utilities/parseUrlSearchParameters";
import { getCardVariationId } from "common/cardIdUtilities";
import { getCurrentDateIso8601 } from "global/utilities/Date";

// -------------------------------------------------------------------------------------------------
// Page that prompts for card search parameters or search results.
// REVIEW
export default function CardsPage() {
	const location = useLocation();
	const urlFilterParameters = parseUrlSearchParameters(location.search);
	const cardSet = urlFilterParameters.set;

	return (
		<PageErrorBoundary>
			{cardSet ?
				<CardsPageContents cardSet={cardSet} /> :
				<ComingSoonPage />
			}
		</PageErrorBoundary>
	);
}

// -------------------------------------------------------------------------------------------------
export function CardsPageContents({ cardSet }) {
	const getCardsUrl = constructGetCardsUrl(cardSet);
	const cardsResource = fetchResource(getCardsUrl);

	return (
		<Container>
			<Suspense fallback={<LoadingSpinner />}>
				<CardsPageContentsLoader cardsResource={cardsResource} />
			</Suspense>
		</Container>
	);
}

CardsPageContents.propTypes = {
	cardSet: PropTypes.string,
	displayOptions: PropTypes.object
};

// -------------------------------------------------------------------------------------------------
function CardsPageContentsLoader({ cardsResource }) {
	const cards = cardsResource.data;
	const cardState = cardsResource.state;

	return (
		<>
			{cards &&
				<Row>
					<Col>
						<Breadcrumbs />
					</Col>
				</Row>
			}
			{cards &&
				<CardsSearchResultsSection cards={cards} />
			}
			{cardState && cardState.isError &&
				<ErrorLoadingSection errorMessage={cardState.errorMessage} />
			}
		</>
	);
}

CardsPageContentsLoader.propTypes = {
	cardsResource: PropTypes.object
};

// -------------------------------------------------------------------------------------------------
export function CardsSearchResultsSection({ cards }) {
	return (
		cards &&
		<Table size="sm">
			<thead>
				<tr>
					<th>Card</th>
					<th className="text-end">Price</th>
				</tr>
			</thead>

			<tbody>
				{cards.map(card =>
					<CardsSearchResultsRow
						key={getCardVariationId(card.fullCard.cardId, card.fullCard.cardVariation)}
						card={card} />
				)}
			</tbody>
		</Table>
	);
}

CardsSearchResultsSection.propTypes = {
	cards: PropTypes.arrayOf(PropTypes.shape(cardDisplaySummarySchema)).isRequired
};

// -------------------------------------------------------------------------------------------------
function CardsSearchResultsRow({ card }) {
	return (
		card &&
		<tr>
			<td>
				<CardRarityImagePopover cardId={card.fullCard.cardId} multiface={card.fullCard.multiface} cardVariation={card.fullCard.cardVariation} rarity={card.fullCard.rarity} size="Small" style={{ maxHeight: 16, maxWidth: 24 }} deckLocation="card" />
				{" "}
				<LinkToCard cardId={card.fullCard.cardId} cardVariation={card.fullCard.cardVariation} multiface={card.fullCard.multiface}>
					{card.fullCard.cardName} {card.fullCard.cardVariation ? "(" + card.fullCard.cardVariation + ")" : ""}
				</LinkToCard>
			</td>
			<td className="text-end">
				<Price value={card.currentVersion.price} />
			</td>
		</tr>
	);
}

CardsSearchResultsRow.propTypes = {
	card: PropTypes.shape(cardDisplaySummarySchema).isRequired
};

// -------------------------------------------------------------------------------------------------
function Breadcrumbs() {
	const rootDomain = `${window.location.protocol}//${window.location.hostname}`;
	const title = "Cards | Magic the Gathering | MTG Top Decks";
	const shortTitle = "Cards";
	const description = "Search results for Magic the Gathering cards";
	const date = getCurrentDateIso8601();
	const breadcrumbsLdJson = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement:": [{
			"@type": "ListItem",
			"position": 1,
			"name": "Home",
			"item": rootDomain
		},
		{
			"@type": "ListItem",
			"position": 2,
			"name": "Cards"
		}]
	};

	return (
		<>
			<Helmet>
				<title>{title}</title>
				<meta name="description" content={description} />
				<meta property="og:title" content={shortTitle} />
				<meta property="og:description" content={description} />
				<meta property="og:url" content={window.location.href} />
				<meta property="og:modified_time" content={date} />
				<meta property="og:site_name" content="MTG Top Decks" />
				<script type="application/ld+json">{JSON.stringify(breadcrumbsLdJson)}</script>
			</Helmet>

			<Breadcrumb>
				<BreadcrumbItem><LinkToHomePage>Home</LinkToHomePage></BreadcrumbItem>
				<BreadcrumbItem>Cards</BreadcrumbItem>
			</Breadcrumb>
		</>
	);
}

Breadcrumbs.propTypes = {
};