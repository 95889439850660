// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";

// -----------------------------------------------------------------------------
// Link to specific tournament.
// REVIEW
const LinkToTournament = ({ children, tournamentId }) => (
	<Link to={getLinkUrl(tournamentId)}>
		{(children) ? children : "<missing>"}
	</Link>
);

LinkToTournament.propTypes = {
	children: PropTypes.node.isRequired,
	tournamentId: PropTypes.string.isRequired
};

export default LinkToTournament;

// -----------------------------------------------------------------------------
function getLinkUrl(tournamentId) {
	const tournament = (tournamentId) ? encodeURIComponent(tournamentId) : "missing";
	return `/tournaments/${tournament}`;
}