// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import { parseDate } from "./parse";

// -------------------------------------------------------------------------------------------------
// Format given date into to user-friendly format.
// FUTURE: Add support for user customizable date formats
// REVIEW
export function formatDate(value) {
	if (value) {
		const options = { year: "numeric", month: "short", day: "numeric", timeZone: "utc" };
		const date = parseDate(value);
		return date.toLocaleDateString("en-US", options);
	} else {
		return "";
	}
}

// FUTURE: Add support alternate locales
export function formatPercentage(value, decimalPlaces) {
	if (typeof (value) === "number") {
		const options = {
			style: "percent",
			useGrouping: true,
			maximumFractionDigits: decimalPlaces,
			minimumFractionDigits: decimalPlaces
		};

		return value.toLocaleString("en-US", options);
	} else {
		return "";
	}
}

// FUTURE: Add support alternate locales
export function formatDecimal(value, decimalPlaces) {
	if (typeof (value) === "number") {
		const options = {
			style: "decimal",
			useGrouping: true,
			maximumFractionDigits: decimalPlaces,
			minimumFractionDigits: decimalPlaces
		};

		return value.toLocaleString("en-US", options);
	} else {
		return "";
	}
}

// FUTURE: Add support alternate locales
export function formatInteger(value) {
	if (typeof (value) === "number") {
		const options = {
			style: "decimal",
			useGrouping: true,
			maximumFractionDigits: 0,
			minimumFractionDigits: 0
		};

		return value.toLocaleString("en-US", options);
	} else {
		return "";
	}
}

export function formatIntegerRange(lowerValue, upperValue) {
	if (lowerValue === upperValue) {
		return formatInteger(lowerValue);
	} else {
		return formatInteger(lowerValue) + " - " + formatInteger(upperValue);
	}
}

// FUTURE: Add support alternate currencies
// FUTURE: Add support alternate locales
export function formatPrice(value, decimalPlaces) {
	if (typeof (value) === "number") {
		const options = {
			style: "currency",
			currency: "USD",
			useGrouping: true,
			maximumFractionDigits: decimalPlaces,
			minimumFractionDigits: decimalPlaces
		};

		return value.toLocaleString("en-US", options);
	} else {
		return "";
	}
}

export function formatRank(value) {
	let lastDigit;

	if (typeof (value) === "number") {
		lastDigit = value % 10;
	} else if (typeof (value) === "string") {
		const trimmedValue = value.trim();
		lastDigit = trimmedValue[trimmedValue.length - 1];
	} else {
		return "";
	}

	let suffix;

	if (lastDigit === 1 || lastDigit === "1") {
		suffix = "st";
	} else if (lastDigit === 2 || lastDigit === "2") {
		suffix = "nd";
	} else if (lastDigit === 3 || lastDigit === "3") {
		suffix = "rd";
	} else {
		suffix = "th";
	}

	return value + suffix;
}

export function joinString(array, separator) {
	if (array && Array.isArray(array)) {
		let output = "";

		for (let index = 0; index < array.length; index++) {
			if (index === 0) {
				output = array[index];
			} else {
				output = output.concat(separator, array[index]);
			}
		}

		return output;
	} else {
		return null;
	}
}