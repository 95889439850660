// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import PropTypes from "prop-types";

import { logUserAction, logUnknownRoute } from "./logging";

// REVIEW
export default class Logger extends React.Component {
	componentDidMount() {
		if (this.props.unknownRoute) {
			logUnknownRoute(this.props.unknownRoute);
		} else if (this.props.userAction) {
			logUserAction(this.props.userAction);
		}
	}

	render() {
		return null;
	}
}

Logger.propTypes = {
	unknownRoute: PropTypes.object,
	userAction: PropTypes.string
};