// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React, { Suspense } from "react";
import { PropTypes } from "prop-types";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Helmet } from "react-helmet";

import PageErrorBoundary from "global/errors/PageErrorBoundary";
import ErrorLoadingSection from "global/errors/ErrorLoadingSection";
import LoadingSpinner from "global/loading/LoadingSpinner";
import LinkToHomePage from "common/links/LinkToHomePage";
import LinkToDeckSearchResults from "common/links/LinkToDeckSearchResults";
import DeckPanel from "./DeckPanel";

import { constructGetDeckUrl } from "api";
import { deckSchema } from "apiSchemas";
import { fetchResource } from "global/loading/fetchResource";
import { getCurrentDateIso8601 } from "global/utilities/Date";
import { formatDate } from "global/utilities/format";
import { getFormatDisplayName } from "common/resources/formatNames";

// -------------------------------------------------------------------------------------------------
// Page that shows details of a single deck.
// REVIEW
export default function DeckPage() {
	const { deckId } = useParams();

	// FUTURE: Read optional price data source name
	//const location = useLocation();
	//const urlFilterParameters = parseUrlSearchParameters(location.search);

	return (
		<PageErrorBoundary>
			<DeckPageContents deckId={deckId} />
		</PageErrorBoundary>
	);
}

// -------------------------------------------------------------------------------------------------
export function DeckPageContents({ deckId, displayOptions }) {
	const getDeckUrl = constructGetDeckUrl(deckId);
	const deckResource = fetchResource(getDeckUrl);

	return (
		<Container>
			<Suspense fallback={<LoadingSpinner />}>
				<DeckPageContentsLoader
					deckResource={deckResource}
					displayOptions={displayOptions} />
			</Suspense>
		</Container>
	);
}

DeckPageContents.propTypes = {
	deckId: PropTypes.string.isRequired,
	displayOptions: PropTypes.object
};

// -------------------------------------------------------------------------------------------------
function DeckPageContentsLoader({ deckResource, displayOptions }) {
	const deck = deckResource.data;
	const deckState = deckResource.state;

	return (
		<>
			{deck &&
				<Row>
					<Col>
						<Breadcrumbs
							deck={deck} />
					</Col>
				</Row>
			}
			{deck &&
				<Row>
					<Col>
						<DeckPanel
							deck={deck}
							displayOptions={displayOptions} />
					</Col>
				</Row>
			}
			{deckState && deckState.isError &&
				<ErrorLoadingSection errorMessage={deckState.errorMessage} />
			}
		</>
	);
}

DeckPageContentsLoader.propTypes = {
	deckResource: PropTypes.object,
	displayOptions: PropTypes.object
};

// -------------------------------------------------------------------------------------------------
function Breadcrumbs({ deck }) {
	const deckName = deck && deck.playerName;
	const archetypeName = deck && deck.archetypeName;
	const formatName = getFormatDisplayName(deck.formatId);
	const deckDate = formatDate(deck.date);
	const rootDomain = `${window.location.protocol}//${window.location.hostname}`;
	const title = `${deckName} (${archetypeName}) | Magic the Gathering | MTG Top Decks`;
	const shortTitle = `${deckName} (${archetypeName})`;
	const description = `Deck details for ${deckName} (${archetypeName}) - ${formatName} on ${deckDate}`;
	const keywords = `Magic the Gathering, cards, archetypes, metagame, decks, tournaments`;
	const date = getCurrentDateIso8601();
	const breadcrumbsLdJson = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement:": [{
			"@type": "ListItem",
			"position": 1,
			"name": "Home",
			"item": rootDomain
		},
		{
			"@type": "ListItem",
			"position": 2,
			"name": "Decks",
			"item": rootDomain + "/decks"
		},
		{
			"@type": "ListItem",
			"position": 3,
			"name": deckName
		}]
	};

	const pageLdJson = {
		"@context": "https://schema.org",
		"@type": "Article",
		"description": description,
		"keywords": keywords,
		"dateCreated": date,
		"dateModified": date,
		"headline": title,
		"image": [
			rootDomain + "/assets/logo.png"
		],
		"author": [{
			"@type": "Organization",
			"name": "MTG Top Decks",
			"url": rootDomain
		}]
	};

	return (
		<>
			<Helmet>
				<title>{title}</title>
				<meta name="description" content={description} />
				<meta property="og:title" content={shortTitle} />
				<meta property="og:description" content={description} />
				<meta property="og:type" content="article" />
				<meta property="og:url" content={window.location.href} />
				<meta property="og:modified_time" content={date} />
				<meta property="og:site_name" content="MTG Top Decks" />
				<script type="application/ld+json">{JSON.stringify(breadcrumbsLdJson)}</script>
				<script type="application/ld+json">{JSON.stringify(pageLdJson)}</script>
			</Helmet>

			<Breadcrumb>
				<BreadcrumbItem><LinkToHomePage>Home</LinkToHomePage></BreadcrumbItem>
				<BreadcrumbItem><LinkToDeckSearchResults>Decks</LinkToDeckSearchResults></BreadcrumbItem>
				<BreadcrumbItem active>{deckName}</BreadcrumbItem>
			</Breadcrumb>
		</>
	);
}

Breadcrumbs.propTypes = {
	deck: PropTypes.shape(deckSchema).isRequired
};