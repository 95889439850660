// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// -----------------------------------------------------------------------------
// Link to all archetypes within a specific format period.
// REVIEW
const LinkToMetagameFormatPeriod = ({ children, formatId, formatPeriodId }) => (
	<Link to={getLinkUrl(formatId, formatPeriodId)}>
		{(children) ? children : "<missing>"}
	</Link>
);

LinkToMetagameFormatPeriod.propTypes = {
	children: PropTypes.node.isRequired,
	formatId: PropTypes.string.isRequired,
	formatPeriodId: PropTypes.string
};

export default LinkToMetagameFormatPeriod;

// -----------------------------------------------------------------------------
function getLinkUrl(formatId, formatPeriodId) {
	const format = formatId ? encodeURIComponent(formatId) : "missing";
	const formatPeriod = formatPeriodId ? encodeURIComponent(formatPeriodId) : null;

	if (formatPeriod) {
		return `/metagames/${format}?period=${formatPeriodId}`;
	} else {
		return `/metagames/${format}`;
	}
}