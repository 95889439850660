// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React, { useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";

import { getLocalStorage, setLocalStorage } from "global/utilities/browserStorage";

// -------------------------------------------------------------------------------------------------
export default function AcceptCookiesPrompt() {
	const [isToastOpen, setIsToastOpen] = useState(haveAcceptedCookies());
	const acceptCookies = () => {
		setAcceptedCookies();
		setIsToastOpen(false);
	};

	return (
		<ToastContainer position="top-center" className="text-center">
			<Toast bg="light" className="m-3 p-3" show={isToastOpen} aria-live="assertive" aria-atomic="true">
				<Toast.Body>
					<p>MTG Top Decks uses cookies for functional and analytical purposes. Please read our <Link to="/cookies">Cookie Policy</Link> for more information.</p>
					<hr />
					<Button color="primary" style={{ cursor: "pointer" }} onClick={() => acceptCookies()}>I agree</Button>
				</Toast.Body>
			</Toast>
		</ToastContainer>
	);
}

// -------------------------------------------------------------------------------------------------
function haveAcceptedCookies() {
	return !getLocalStorage("acceptedCookies");
}

// -------------------------------------------------------------------------------------------------
function setAcceptedCookies() {
	setLocalStorage("acceptedCookies", true);
}