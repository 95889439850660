// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { PropTypes } from "prop-types";

import { getWebSiteEndpoints } from "webSiteEndpoints";
import Image from "global/utilities/Image";

// -----------------------------------------------------------------------------
// REVIEW
// TODO: Add support for different sizes
const CardImage = ({ cardId, multiface, language, cardVariation }) => (
	<>
		{/* TODO: Add small margin between images */}
		<Image
			alt={cardId ? cardId.toString() + "-front" : "0-front"}
			width={244}
			src={getCardUrl(cardId, false, language, cardVariation)}
			fallbackSrc={getCardBackUrl()} />

		{(!isDoubleFaced(multiface)) ? null :
			<Image
				src={getCardUrl(cardId, true, language, cardVariation)}
				fallbackSrc={getCardBackUrl()}
				alt={cardId ? cardId.toString() + "-back" : "0-back"}
				width={244} />}
	</>
);

CardImage.propTypes = {
	cardId: PropTypes.number.isRequired,
	multiface: PropTypes.number,
	language: PropTypes.string,
	cardVariation: PropTypes.string
};

export default CardImage;

// -----------------------------------------------------------------------------
function isDoubleFaced(multiface) {
	if (multiface) {
		// Transform = 1, Meld = 4, Modal DF = 6, Token DF = 7
		return (multiface === 1 || multiface === 4 || multiface === 6 || multiface === 7);
	} else {
		return false;
	}
}

function getCardUrl(cardId, isCardBack, specificLanguage, specificVariation) {
	const frontOrBack = isCardBack ? "b" : "f";
	const language = specificLanguage ? encodeURIComponent(specificLanguage) : "en";
	const cardVariation = specificVariation ? encodeURIComponent(specificVariation) : "a";

	if (cardId) {
		const cardSetCodeId = cardId & 0xFFF;
		const url = `${getWebSiteEndpoints().images}cards/${encodeURIComponent(cardSetCodeId)}-${language}-medium/` +
			`${encodeURIComponent(cardId)}${cardVariation}-${language}-${frontOrBack}.jpg`;

		return url;
	}

	return null;
}

function getCardBackUrl() {
	return getWebSiteEndpoints().images + "symbols/medium/cardback.png";
}