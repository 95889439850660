// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { PropTypes } from "prop-types";

import { getWebSiteEndpoints } from "webSiteEndpoints";
import Image from "global/utilities/Image";

// -----------------------------------------------------------------------------
// Image of card set symbol
// REVIEW
const CardSetImage = ({ cardSetId, style }) => (
	<Image
		src={getCardSetImageUrl(cardSetId)}
		alt={cardSetId ? cardSetId.toString() : "missing set ID"}
		style={style} />
);

CardSetImage.propTypes = {
	cardSetId: PropTypes.number.isRequired,
	style: PropTypes.object
};

export default CardSetImage;

// -----------------------------------------------------------------------------
function getCardSetImageUrl(cardSetId) {
	if (cardSetId) {
		return `${getWebSiteEndpoints().images}sets/general/${encodeURIComponent(cardSetId)}-all.svg`;
	} else {
		return null;
	}
}