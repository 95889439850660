// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { Container, Col, Row } from "reactstrap";
import { useLocation } from "react-router-dom";

import PageErrorBoundary from "./PageErrorBoundary";
import Logger from "global/utilities/Logger";

// -----------------------------------------------------------------------------
// Error page when loading page that isn't mapped.
// REVIEW
function UnknownRoutePage() {
	const location = useLocation();

	return (
		<PageErrorBoundary>
			<Container>
				<Row>
					<Col>
						<div className="p-5 mb-4 bg-secondary rounded-3 text-center">
							<h1 className="display-3 text-primary">
								:-(
							</h1>
							<p className="lead">
								Oops, unknown site page for:
							</p>
							<p>
								<code>{(location && location.pathname) ? location.pathname : "<no URL provided>"}</code>
							</p>
							<p>
								This error has been logged and will be investigated by our administrators
								{" "}
								<span className="fas fa-directions" />
							</p>
							<Logger unknownRoute={location} />
						</div>
					</Col>
				</Row>
			</Container>
		</PageErrorBoundary>
	);
}

export default UnknownRoutePage;