// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

// REVIEW
function getLocalStorage(keyName) {
	if (typeof(Storage) !== "undefined") {
		return localStorage.getItem(keyName);
	}

	return null;
}

function setLocalStorage(keyName, value) {
	if (typeof(Storage) !== "undefined") {
		localStorage.setItem(keyName, value);
	}
}

function clearLocalStorage(keyName) {
	if (typeof(Storage) !== "undefined") {
		localStorage.removeItem(keyName);
	}
}

function getSessionStorage(keyName) {
	if (typeof(Storage) !== "undefined") {
		return sessionStorage.getItem(keyName);
	}

	return null;
}

function setSessionStorage(keyName, value) {
	if (typeof(Storage) !== "undefined") {
		sessionStorage.setItem(keyName, value);
	}
}

function clearSessionStorage(keyName) {
	if (typeof(Storage) !== "undefined") {
		sessionStorage.removeItem(keyName);
	}
}

export {
	getLocalStorage,
	setLocalStorage,
	clearLocalStorage,
	getSessionStorage,
	setSessionStorage,
	clearSessionStorage
};