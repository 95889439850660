// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { PropTypes } from "prop-types";
import { Table } from "reactstrap";

import FormattedDate from "global/utilities/Date";
import { formatRank } from "global/utilities/format";
import Price from "global/utilities/Price";
import LinkToDeck from "common/links/LinkToDeck";
import TableCellItem from "common/TableCellItem";
import ColorIdentity from "common/ColorIdentity";
import LinkToDeckSearchResults from "common/links/LinkToDeckSearchResults";

import { deckSchema } from "apiSchemas";
import { getFormatDisplayName } from "common/resources/formatNames";

// -------------------------------------------------------------------------------------------------
// Table that shows list of linked tournament summaries.
// REVIEW
export default function DecksTable({ decks, displayOptions }) {
	const tableNotStriped = displayOptions && displayOptions.showTableNotStriped;
	const tableBordered = displayOptions && displayOptions.showTableBordered;

	return (
		<Table striped={!tableNotStriped} bordered={tableBordered} size="sm">
			<thead>
				<tr>
					<th>Deck</th>
					<th>Player</th>
					<th>Date</th>
					<th>Finish</th>
					<th>Format</th>
					<th className="d-none d-lg-table-cell">Source</th>
					<th className="text-end d-none d-lg-table-cell">Price</th>
				</tr>
			</thead>

			<tbody>
				{decks && decks.length > 0 && decks.map(deck =>
					<DecksTableRow
						key={deck.deckId}
						deck={deck} />
				)}
				{(!decks || decks.length === 0) &&
					<tr>
						<td className="text-muted text-center" colSpan={6}>
							No decks found
						</td>
					</tr>
				}
			</tbody>
		</Table>
	);
}

DecksTable.propTypes = {
	decks: PropTypes.arrayOf(PropTypes.shape(deckSchema)).isRequired,
	displayOptions: PropTypes.object
};

// -------------------------------------------------------------------------------------------------
// TODO: Consider reversing archetype / player name so player can link to deck and archetype name can link to archetype
function DecksTableRow({ deck }) {
	return (
		<tr>
			<td style={{ maxWidth: "150px" }}>
				<TableCellItem>
					<LinkToDeck deck={deck}>
						{deck.archetypeVariationDisplayName ? deck.archetypeVariationDisplayName : "<unknown archetype>"}
					</LinkToDeck>
				</TableCellItem>
				{" "}
				{/* FUTURE: Currently doesn't show ellipse properly if color identity is inside TableCellItem */}
				<ColorIdentity identity={deck.colorIdentity} />
			</td>

			{/* TODO: Add player name filter component */}
			<td className="text-nowrap" style={{ maxWidth: "100px" }}>
				<TableCellItem>
					{deck.playerName}
				</TableCellItem>
			</td>

			<td className="text-nowrap" style={{ maxWidth: "50px" }}>
				<TableCellItem>
					<FormattedDate value={deck.date} />
				</TableCellItem>
			</td>

			<td className="text-nowrap" style={{ maxWidth: "50px" }}>
				<TableCellItem>
					{getDeckFinish(deck)}
				</TableCellItem>
			</td>

			<td className="text-nowrap" style={{ maxWidth: "50px" }}>
				<TableCellItem>
					<LinkToDeckSearchResults key={deck.formatId} formatIdFilter={deck.formatId}>
						{getFormatDisplayName(deck.formatId)}
						{" "}
					</LinkToDeckSearchResults>
				</TableCellItem>
			</td>

			<td className="text-nowrap d-none d-lg-table-cell" style={{ maxWidth: "100px" }}>
				<TableCellItem>
					{deck.tournamentName}
				</TableCellItem>
			</td>

			<td className="text-end text-nowrap d-none d-lg-table-cell" style={{ maxWidth: "50px" }}>
				<Price value={deck.price} />
			</td>
		</tr>
	);
}

DecksTableRow.propTypes = {
	deck: PropTypes.shape(deckSchema).isRequired
};

// -------------------------------------------------------------------------------------------------
function getDeckFinish(deck) {
	if (deck.finish) {
		return formatRank(deck.finish);
	} else if (deck.matchRecord) {
		return deck.matchRecord;
	} else {
		return null;
	}
}
