// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React, { Suspense } from "react";
import { PropTypes } from "prop-types";
import { Container, Row, Col } from "reactstrap";
import { Table } from "reactstrap";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Helmet } from "react-helmet";

import PageErrorBoundary from "global/errors/PageErrorBoundary";
import ErrorLoadingSection from "global/errors/ErrorLoadingSection";
import LoadingSpinner from "global/loading/LoadingSpinner";
import FormattedDate from "global/utilities/Date";
import LinkToHomePage from "common/links/LinkToHomePage";
import LinkToCards from "common/links/LinkToCards";
import CardSetImage from "common/CardSetImage";

import { constructGetCardSetsUrl } from "api";
import { cardSetSchema } from "apiSchemas";
import { fetchResource } from "global/loading/fetchResource";
import { getCurrentDateIso8601 } from "global/utilities/Date";

// -------------------------------------------------------------------------------------------------
// Page that shows list of all card sets.
// REVIEW
export default function CardPage() {
	return (
		<PageErrorBoundary>
			<CardSetsPageContents />
		</PageErrorBoundary>
	);
}

// -------------------------------------------------------------------------------------------------
export function CardSetsPageContents() {
	const getCardSetsUrl = constructGetCardSetsUrl();
	const cardSetsResource = fetchResource(getCardSetsUrl);

	return (
		<Container>
			<Suspense fallback={<LoadingSpinner />}>
				<CardSetsPageContentsLoader cardSetsResource={cardSetsResource} />
			</Suspense>
		</Container>
	);
}

CardSetsPageContents.propTypes = {
	displayOptions: PropTypes.object
};

// -------------------------------------------------------------------------------------------------
function CardSetsPageContentsLoader({ cardSetsResource }) {
	const cardSets = cardSetsResource.data;
	const cardState = cardSetsResource.state;

	return (
		<>
			<Row>
				<Col>
					<Breadcrumbs />
				</Col>
			</Row>
			{cardSets &&
				<CardSetsSection cardSets={cardSets} />
			}
			{cardState && cardState.isError &&
				<ErrorLoadingSection errorMessage={cardState.errorMessage} />
			}
		</>
	);
}

CardSetsPageContentsLoader.propTypes = {
	cardSetsResource: PropTypes.object
};

// -------------------------------------------------------------------------------------------------
export function CardSetsSection({ cardSets }) {
	return (
		cardSets &&
		<Table size="sm">
			<thead>
				<tr>
					<th>Set</th>
					<th>Type</th>
					<th className="text-end"># of Cards</th>
					<th>Release Date</th>
				</tr>
			</thead>

			<tbody>
				{cardSets.map(cardSet =>
					<CardSetRow key={cardSet.setId} cardSet={cardSet} />
				)}
			</tbody>
		</Table>
	);
}

CardSetsSection.propTypes = {
	cardSets: PropTypes.arrayOf(PropTypes.shape(cardSetSchema)).isRequired
};

// -------------------------------------------------------------------------------------------------
export function CardSetRow({ cardSet }) {
	return (
		cardSet &&
		<tr>
			<td className={cardSet.parentCardSetId ? "ps-4" : ""}>
				<CardSetImage cardSetId={cardSet.cardSetId} style={{ width: 16, height: 16 }} />
				{" "}
				<LinkToCards cardSetId={cardSet.cardSetId}>{cardSet.cardSetName}</LinkToCards>
			</td>
			<td>
				{cardSet.cardSetCategory}
			</td>
			<td className="text-end">
				{cardSet.numberOfCards}
			</td>
			<td>
				<FormattedDate value={cardSet.releaseDate} />
			</td>
		</tr>
	);
}

CardSetRow.propTypes = {
	cardSet: PropTypes.shape(cardSetSchema).isRequired
};

// -------------------------------------------------------------------------------------------------
function Breadcrumbs() {
	const rootDomain = `${window.location.protocol}//${window.location.hostname}`;
	const title = "Card Sets | Magic the Gathering | MTG Top Decks";
	const shortTitle = "Card Sets";
	const description = "List of all Magic the Gathering card sets";
	const date = getCurrentDateIso8601();
	const breadcrumbsLdJson = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement:": [{
			"@type": "ListItem",
			"position": 1,
			"name": "Home",
			"item": rootDomain
		},
		{
			"@type": "ListItem",
			"position": 2,
			"name": "Cards"
		}]
	};

	return (
		<>
			<Helmet>
				<title>{title}</title>
				<meta name="description" content={description} />
				<meta property="og:title" content={shortTitle} />
				<meta property="og:description" content={description} />
				<meta property="og:url" content={window.location.href} />
				<meta property="og:modified_time" content={date} />
				<meta property="og:site_name" content="MTG Top Decks" />
				<script type="application/ld+json">{JSON.stringify(breadcrumbsLdJson)}</script>
			</Helmet>

			<Breadcrumb>
				<BreadcrumbItem><LinkToHomePage>Home</LinkToHomePage></BreadcrumbItem>
				<BreadcrumbItem>Card Sets</BreadcrumbItem>
			</Breadcrumb>
		</>
	);
}

Breadcrumbs.propTypes = {};