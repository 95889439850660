// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { Container, Row, Col } from "reactstrap";
import { NavLink } from "reactstrap";
import { Link as RoutingNavLink } from "react-router-dom";

// -----------------------------------------------------------------------------
// REVIEW
export default function SiteFooterBar() {
	return (
		// TODO: Move copyright to central location
		<footer className="bg-secondary container-fluid footer d-none d-lg-block">
			<Container style={{ padding: 10 }} >
				<Row className="text-center">
					<Col>
						Copyright Shrewd Apps <span>&#169;</span> 2024. All rights reserved.
						<NavLink tag={RoutingNavLink} to="/about">
							About | Legal | Contact
						</NavLink>
						<p><small style={{ fontSize: "70%" }}>
							MTG Top Decks is unofficial Fan Content permitted under the Fan Content Policy.
							Not approved / endorsed by Wizards. Portions of the materials used are property of
							Wizards of the Coast. © Wizards of the Coast LLC.
						</small></p>
					</Col>
				</Row>
			</Container>
		</footer>
	);
}