// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import ReactGA from "react-ga4";
import { isAnalyticsDisabled } from "global/utilities/analytics";

// REVIEW
/* eslint-disable no-console */
function logUserAction(eventName) {
	console.log("Action: " + (eventName) ? eventName : "<no action>");

	setAnalyticsEvent({
		category: "User",
		action: eventName
	});
}

function logInternalError(errorMessage) {
	console.log("Internal error: " + errorMessage);

	setAnalyticsEvent({
		category: "Error",
		action: errorMessage
	});
}

function logUnknownRoute(location) {
	console.log("Unknown route: " + formatLocation(location));

	setAnalyticsEvent({
		category: "Unknown Route",
		action: formatLocation(location)
	});
}

function logPageLoad(location) {
	console.log("Page view: " + formatLocation(location));

	setAnalyticsSend({
		hitType: "pageview",
		page: formatLocation(location)
	});
}

function logDebugging(message) {
	console.log("Debugging message: " + (message) ? message : "<no message>");

	// setAnalyticsEvent({
	// 	category: "Debugging message",
	//	action: (message) ? message : "<no message>"
	// });
}

function logObjectDebugging(object) {
	console.log("Dump object:" + (object) ? JSON.stringify(object) : "<null>");
}

function logTiming(category, variable, elapsedTime) {
	console.log("Timing: " + (category) ? category : "<no category> (" + elapsedTime + ")");

	setAnalyticsEvent({
		category: "Timing",
		action: category,
		variable: variable,
		value: elapsedTime
	});
}

function logFeedback(message) {
	// TODO: Move to separate feedback component
	console.log("Feedback: " + message);
}

function setAnalyticsSend(value) {
	if (!isAnalyticsDisabled()) {
		ReactGA.send(value);
	}
}

function setAnalyticsEvent(value) {
	if (!isAnalyticsDisabled()) {
		ReactGA.event(value);
	}
}

function formatLocation(location) {
	const pathname = (location && location.pathname) ? location.pathname : "<no path>";
	const search = (location && location.search) ? location.search : "";

	return pathname + search;
}

export {
	logUserAction,
	logInternalError,
	logUnknownRoute,
	logPageLoad,
	logDebugging,
	logObjectDebugging,
	logTiming,
	logFeedback
};