// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import { prefetch } from "react-suspense-fetch";
//import useSWR from "swr";
import axios from "axios";

// -------------------------------------------------------------------------------------------------
// CREATING
// TODO: Remove SWR and axios from dependencies
export function fetchResource(url) {
	console.log(`Prefetching: ${url}`);

	// https://medium.com/better-programming/diving-into-react-suspense-render-as-you-fetch-for-rest-apis-bf2d20ea7c59
	// https://github.com/dai-shi/react-suspense-fetch
	return prefetch(fetchUrl, url);
}

// -------------------------------------------------------------------------------------------------
function fetchUrl(url) {
	return fetchUrlWithToken(url, null);
}

// -------------------------------------------------------------------------------------------------
async function fetchUrlWithToken(url, continuationToken) {
	let headers = {};

	if (continuationToken) {
		headers["Continuation-Token"] = continuationToken;
	}

	const options = {
		method: "get",
		headers: headers
	};

	const response = await fetch(url, options);

	if (response && response.ok) {
		const data = await response.json();
		const hasMoreData = response.headers && response.headers.get("continuation-token");
		const continuationToken = hasMoreData ? response.headers.get("continuation-token") : null;
		const loadMoreData = !hasMoreData ? null : async () => {
			return await fetchUrlWithToken(url, continuationToken);
		};

		return {
			data: data,
			state: {
				url: url,
				status: response.status,
				response: response,
				hasMoreData: hasMoreData,
				loadMoreData: loadMoreData,
			}
		};
	} else {
		const body = await response.text();
		const message = `Server status: ${response.status} (${body})`;

		console.log(`Request error: ${message}`);

		return {
			data: undefined,
			state: {
				url: url,
				status: response.status,
				response: response,
				isError: true,
				errorMessage: message
			}
		};
	}
}

// -------------------------------------------------------------------------------------------------
// OBSOLETE: Remove once shown new is sufficient
// eslint-disable-next-line
async function fetchUrlWithTokenOld(url, continuationToken) {
	let headers = {};

	if (continuationToken) {
		headers["Continuation-Token"] = continuationToken;
	}

	const request = {
		method: "get",
		url: url,
		headers: headers
	};

	const result = await axios(request)
		.then(response => {
			const hasMoreData = response && response.headers && response.headers["continuation-token"];
			const continuationToken = hasMoreData ? response.headers["continuation-token"] : null;
			const loadMoreData = !hasMoreData ? null : async () => {
				// eslint-disable-next-line
				return await fetchUrlWithTokenOld(url, continuationToken);
			};

			return {
				data: response.data,
				state: {
					response: response,
					hasMoreData: hasMoreData,
					loadMoreData: loadMoreData,
				},
			};
		})
		.catch(errorResponse => {
			return {
				state: {
					response: errorResponse,
					isError: true,
					errorMessage: errorResponse.message
				},
			};
		});

	return result;
}