// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
//import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
//import { Table } from "reactstrap";
//import { Badge } from "reactstrap";

import { constructGetTopNewCardsUrl } from "api";
//import { metagameMultiFormatCardStats } from "apiSchemas";

import FetchSection from "global/loading/FetchSection";

//import LinkToMetagameFormats from "common/links/LinkToMetagameFormats";
//import LinkToMetagameFormatPeriod from "common/links/LinkToMetagameFormatPeriod";
import LinkToTopCards from "common/links/LinkToTopCards";
//import { getFormatDisplayName } from "common/resources/formatNames";
//import { formatInteger, formatPercentage, formatPrice } from "global/utilities/format";
//import LinkToCard from "common/links/LinkToCard";
//import TableCellItem from "common/TableCellItem";
//import ColorIdentities from "common/ColorIdentities";
//import ColorIdentity from "common/ColorIdentity";
//import CardRarityImage from "common/CardRarityImage";
//import ManaCost from "common/ManaCost";

import { MetagameTopCardsTable } from "modules/metagame/MetagameTopCardsPage";

// -----------------------------------------------------------------------------
// Show top cards summary for the home page.
// REVIEW
// FUTURE: Only show preferred formats from user perferences
const HomePageTopCardsPanel = () =>
	<>
		<Row>
			<Col>
				<h2 className="mt-2">
					<LinkToTopCards>
						Top New Cards
					</LinkToTopCards>
				</h2>
			</Col>
		</Row>

		<FetchSection requestUrl={constructGetTopNewCardsUrl()}>
			{data =>
				<Row>
					<Col>
						{data && <MetagameTopCardsTable cards={data} />}
					</Col>
				</Row>
			}
		</FetchSection>
	</>;

export default HomePageTopCardsPanel;