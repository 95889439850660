// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import { logInternalError } from "global/utilities/logging";

// -------------------------------------------------------------------------------------------------
// List of web endpoints for each module
// REVIEW
const webSiteEndpoints = {
	dev: {
		images: "https://imagesdev.mtgtopdecks.com/",
		site: "https://dev.mtgtopdecks.com/"
	},
	prod: {
		images: "https://images.mtgtopdecks.com/",
		site: "https://mtgtopdecks.com/"
	},
};

// -------------------------------------------------------------------------------------------------
function getWebSiteEndpoints() {
	const stage = window.$deploymentStage;

	if (stage === "localhost") {
		return webSiteEndpoints.dev;
	} else if (stage === "dev") {
		return webSiteEndpoints.dev;
	} else if (stage === "prod") {
		return webSiteEndpoints.prod;
	} else {
		logInternalError(`Unexpected environment variable: ${stage}`);
		return webSiteEndpoints.dev;
	}
}

export {
	getWebSiteEndpoints
};