// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

//import React from "react";
import PropTypes from "prop-types";
//import { withRouter } from "react-router";
import { useLocation } from "react-router-dom";

import { logPageLoad } from "./logging";
import { initializeAnalytics } from "./analytics";

// -----------------------------------------------------------------------------
// Hidden helper class to execute task on each navigation action.
// REVIEW
export default function PageChangeActions({ enableAnalytics/*, enableScrollToTop*/}) {
	const location = useLocation();

	if (enableAnalytics) {
		initializeAnalytics();
		logPageLoad(location);
	}

	return null;
}

//class PageChangeActionsOld extends React.Component {
//	constructor(props) {
//		super(props);

//		if (props.enableAnalytics) {
//			initializeAnalytics();
//			logPageLoad(props.location);
//		}
//	}

//	componentDidUpdate(previousProps) {
//		if (this.props.location.pathname !== previousProps.location.pathname ||
//			this.props.location.search !== previousProps.location.search) {

//			if (this.props.enableScrollToTop) {
//				window.scrollTo(0, 0);
//			}

//			if (this.props.enableAnalytics) {
//				logPageLoad(this.props.location);
//			}
//		}
//	}

//	render() {
//		return null;
//	}
//}

PageChangeActions.propTypes = {
	//location: PropTypes.object,
	enableAnalytics: PropTypes.bool,
	enableScrollToTop: PropTypes.bool
};

//function withRouter(Component) {
//	function ComponentWithRouterProp(props) {
//		let location = useLocation();
//		let navigate = useNavigate();
//		let params = useParams();
//		return (
//			<Component
//				{...props}
//				router={{ location, navigate, params }}
//			/>
//		);
//	}

//	return ComponentWithRouterProp;
//}

//export default withRouter(PageChangeActions);