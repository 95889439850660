// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

// -----------------------------------------------------------------------------
// REVIEW
export default class UncontrolledTabs extends React.Component {
	constructor(props) {
		super(props);

		this.selectTab = this.selectTab.bind(this);
		this._isTabLoaded = [];

		let defaultTabId = props.defaultTabId;

		if (!defaultTabId && props.tabs) {
			defaultTabId = props.tabs[0].id;
		}

		this._isTabLoaded[defaultTabId] = true;
		
		this.state = {
			selectedTabId: defaultTabId
		};
	}

	selectTab(newTabId) {
		this._isTabLoaded[newTabId] = true;

		if (this.props.onTabChanged) {
			this.props.onTabChanged();
		}

		this.setState({
			selectedTabId: newTabId
		});
	}

	constructTabHeader(id, header) {
		return <NavItem key={id}>
			<NavLink
				href="#"
				className={classnames({ active: this.state.selectedTabId === id })}
				onClick={() => { this.selectTab(id); }}>

				{header}
			</NavLink>
		</NavItem>;
	}

	constructTabContents(id, contents) {
		return <React.Fragment key={id}>
			{this._isTabLoaded[id] ?
				<TabPane key={id} tabId={id}>
					{contents}
				</TabPane> : null}
		</React.Fragment>;
	}

	render() {
		return (
			<React.Fragment>
				<Nav tabs>
					{this.props.tabs ? this.props.tabs.map(tab =>
						this.constructTabHeader(tab.id, tab.header)) : null}
				</Nav>
				<TabContent activeTab={this.state.selectedTabId}>
					{this.props.tabs ? this.props.tabs.map(tab =>
						this.constructTabContents(tab.id, tab.contents)) : null}
				</TabContent>
			</React.Fragment>
		);
	}
}

UncontrolledTabs.propTypes = {
	defaultTabId: PropTypes.string,
	onTabChanged: PropTypes.func,
	tabs: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
		header: PropTypes.element.isRequired,
		contents: PropTypes.element
	})).isRequired
};