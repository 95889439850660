// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { PropTypes } from "prop-types";
import { Table } from "reactstrap";
import { Badge } from "react-bootstrap";

import ColorIdentity from "common/ColorIdentity";
import FormattedDate from "global/utilities/Date";
import Price from "global/utilities/Price";
import LinkToDeck from "common/links/LinkToDeck";
import LinkToMetagameArchetype from "common/links/LinkToMetagameArchetype";
import TableCellItem from "common/TableCellItem";

import { tournamentSchema, tournamentOverallSchema, deckSchema, tournamentRoundResultSchema } from "apiSchemas";
import { formatRank, formatInteger } from "global/utilities/format";
import { getFormatDisplayNames } from "common/resources/formatNames";
import { getLocalStorage } from "global/utilities/browserStorage";

// -------------------------------------------------------------------------------------------------
// REVIEW
export default function TournamentPanel({ tournament, displayOptions }) {
	return (
		<>
			{tournament && tournament.overall && <TournamentSummary tournamentOverall={tournament.overall} />}
			{tournament && <TournamentStandingsTable decks={tournament.decks} displayOptions={displayOptions} />}
			{tournament && <TournamentRoundResults roundResults={tournament.roundResults} />}
		</>
	);
}

TournamentPanel.propTypes = {
	tournament: PropTypes.shape(tournamentSchema).isRequired,
	displayOptions: PropTypes.object
};

// -------------------------------------------------------------------------------------------------
function TournamentSummary({ tournamentOverall }) {
	const isDebugModeEnabled = getLocalStorage("isDebugModeEnabled") == "true";

	return (
		<ul className="list-unstyled">
			<li>
				<h2>{tournamentOverall.tournamentName}</h2>
			</li>

			<li>
				<strong>Format:</strong>
				{" "}
				{getFormatDisplayNames(tournamentOverall.formatIds)}
				{isDebugModeEnabled && tournamentOverall.tournamentStages && tournamentOverall.tournamentStages.length > 0 &&
					tournamentOverall.tournamentStages.map(stage => stage.formatPeriodId).filter((value, index, self) => self.indexOf(value) === index).map(value => 
						<Badge key={value} className="m-1" bg="warning" text="light">
							{value}
						</Badge>
					)
				}
			</li>

			<li>
				<strong>Date:</strong>
				{" "}
				<FormattedDate value={tournamentOverall.date} />

			</li>
			<li>
				<strong>Organizer:</strong>
				{" "}
				{tournamentOverall.organizerName ? tournamentOverall.organizerName : "-"}
			</li>

			<li>
				<strong>Players:</strong>
				{" "}
				{/*FUTURE: Support approximate number of players same of tournament list*/}
				{tournamentOverall.numberOfPlayers ? formatInteger(tournamentOverall.numberOfPlayers) : "-"}
			</li>

			<li>
				<strong>Source:</strong>
				{" "}
				<a href={tournamentOverall.sourceSiteUrl} target="_blank" rel="nofollow noopener noreferrer">
					{tournamentOverall.sourceSiteName ? tournamentOverall.sourceSiteName : "<unknown>"}
					{" "}
					<span className="fas fa-external-link-alt" />
				</a>
			</li>
		</ul>
	);
}

TournamentSummary.propTypes = {
	tournamentOverall: PropTypes.shape(tournamentOverallSchema)
};

// -------------------------------------------------------------------------------------------------
function TournamentStandingsTable({ decks, displayOptions }) {
	const tableNotStriped = displayOptions && displayOptions.showTableNotStriped;
	const tableBordered = displayOptions && displayOptions.showTableBordered;

	return (
		<>
			<h4>Standings</h4>
			<Table striped={!tableNotStriped} bordered={tableBordered} size="sm">
				<thead>
					<tr>
						<th>Finish</th>
						<th>Player</th>
						<th>Archetype</th>
						<th className="text-end d-none d-sm-table-cell">Price</th>
					</tr>
				</thead>

				<tbody>
					{decks && decks.length > 0 && decks.map(deck =>
						<TournamentStandingsTableRow
							key={deck.deckId}
							deck={deck} />)}
					{(!decks || decks.length === 0) &&
						<tr>
							<td className="text-muted text-center" colSpan={4}>
								No decks found
							</td>
						</tr>
					}
				</tbody>
			</Table>
		</>
	);
}

TournamentStandingsTable.propTypes = {
	decks: PropTypes.arrayOf(PropTypes.shape(deckSchema)),
	displayOptions: PropTypes.object
};

// -------------------------------------------------------------------------------------------------
function TournamentStandingsTableRow({ deck }) {
	return (
		<tr>
			<td className="text-nowrap" style={{ maxWidth: "60px" }}>
				<TableCellItem>
					{getDeckFinish(deck)}
				</TableCellItem>
			</td>

			<td style={{ maxWidth: "150px" }}>
				<LinkToDeck deck={deck}>
					<TableCellItem>
						{deck.playerName}
					</TableCellItem>
				</LinkToDeck>
			</td>

			<td style={{ maxWidth: "150px" }}>
				{deck.archetypeId &&
					<LinkToMetagameArchetype
						formatId={deck.formatId}
						formatPeriodId={deck.formatPeriodId}
						archetypeId={deck.archetypeId}
						archetypeVariationId={deck.archetypeVariationsId}>

						{(deck.archetypeVariationName) ? deck.archetypeVariationName : "<unknown archetype>"}
					</LinkToMetagameArchetype>
				}
				{" "}
				<ColorIdentity identity={deck.colorIdentity} />
			</td>

			<td className="text-end text-nowrap d-none d-sm-table-cell" style={{ maxWidth: "75px" }}>
				<TableCellItem>
					{deck.price ? <Price value={deck.price} /> : "-"}
				</TableCellItem>
			</td>
		</tr>
	);
}

TournamentStandingsTableRow.propTypes = {
	deck: PropTypes.shape(deckSchema).isRequired
};

// -------------------------------------------------------------------------------------------------
function getDeckFinish(deck) {
	if (deck.finish) {
		if (deck.matchRecord) {
			return formatRank(deck.finish) + " (" + deck.matchRecord + ")";
		} else {
			return formatRank(deck.finish);
		}
	} else if (deck.matchRecord) {
		return deck.matchRecord;
	} else {
		return null;
	}
}

// -------------------------------------------------------------------------------------------------
function TournamentRoundResults({ roundResults }) {
	return (
		<>
			{roundResults &&
				<Table size="sm" striped>
					<thead>
						<tr>
							<th>Round</th>
							<th>Player 1</th>
							<th>Player 2</th>
							<th>Record</th>
						</tr>
					</thead>

					<tbody>
						{roundResults.map(i_round =>
							i_round.matchResults.map(i_match =>
								<tr key={i_round.round + i_match.firstPlayerName}>
									<td>{i_round.round}</td>
									<td>{i_match.firstPlayerName}</td>
									<td>{i_match.secondPlayerName}</td>
									<td>{i_match.gameRecord}</td>
								</tr>
							)
						)}
					</tbody>
				</Table >
			}
		</>
	);
}

TournamentRoundResults.propTypes = {
	roundResults: PropTypes.arrayOf(PropTypes.shape(tournamentRoundResultSchema))
};
