// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Helmet } from "react-helmet";

import PageErrorBoundary from "global/errors/PageErrorBoundary";

// -------------------------------------------------------------------------------------------------
// Site cookie policy information.
// REVIEW
export default function AboutCookiePage() {
	const url = "MtgTopDecks.com";
	const name = "MTG Top Decks";
	const rootDomain = `${window.location.protocol}//${window.location.hostname}`;
	const title = "Cookies Policy | Magic the Gathering | MTG Top Decks";
	const shortTitle = "Cookies Policy";
	const description = "Details of the cookie policy for MTG Top Decks";

	const breadcrumbsLdJson = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement:": [{
			"@type": "ListItem",
			"position": 1,
			"name": "Home",
			"item": rootDomain
		},
		{
			"@type": "ListItem",
			"position": 2,
			"name": "Cookies Policy"
		}]
	};

	return (
		<PageErrorBoundary>
			<Container>
				<Helmet>
					<title>{title}</title>
					<meta name="description" content={description} />
					<meta property="og:title" content={shortTitle} />
					<meta property="og:description" content={description} />
					<meta property="og:url" content={window.location.href} />
					<meta property="og:site_name" content="MTG Top Decks" />
					<script type="application/ld+json">{JSON.stringify(breadcrumbsLdJson)}</script>
				</Helmet>

				<Row>
					<Col>
						<h2>Cookies Policy of <span className="website_url">{url}</span></h2>

						<p>This website, <span className="website_url">{url}</span> (the &quot;Website&quot;), is operated by <span className="website_name">{name}</span>. This Cookies Policy was created by <a href="https://www.cookiespolicytemplate.com">Cookies Policy Template</a> for <span className="website_url">{url}</span></p>

						<h3>What are cookies?</h3>

						<p>Cookies are a small text files that are stored in your web browser that allows <span className="website_name">{name}</span> or a third party to recognize you. Cookies can be used to collect, store and share bits of information about your activities across websites, including on <span className="website_name">{name}</span> website. Information regarding cookies from this Cookies Policy are from <a href="https://www.generateprivacypolicy.com/">the Privacy Policy Generator</a>.</p>

						<p>Cookies might be used for the following purposes:</p>

						<ul>
							<li>To enable certain functions</li>
							<li>To provide analytics</li>
							<li>To store your preferences</li>
							<li>To enable ad delivery and behavioral advertising</li>
						</ul>

						<p><span className="website_name">{name}</span> uses both session cookies and persistent cookies.</p>

						<p>A session cookie is used to identify a particular visit to our Website. These cookies expire after a short time, or when you close your web browser after using our Website. We use these cookies to identify you during a single browsing session, such as when you log into our Website.</p>

						<p>A persistent cookie will remain on your devices for a set period of time specified in the cookie. We use these cookies where we need to identify you over a longer period of time. For example, we would use a persistent cookie if you asked that we keep you signed in.</p>

						<h3>How do third parties use cookies on the <span className="website_name">{name}</span> Website?</h3>

						<p>Third party companies like analytics companies and ad networks generally use cookies to collect user information on an anonymous basis. They may use that information to build a profile of your activities on the <span className="website_name">{name}</span> Website and other websites that you&apos;ve visited.</p>

						<h3>What are your cookies options?</h3>

						<p>If you don&apos;t like the idea of cookies or certain types of cookies, you can change your browser&apos;s settings to delete cookies that have already been set and to not accept new cookies. To learn more about how to do this, visit the help pages of your browser.</p>

						<p>Please note, however, that if you delete cookies or do not accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly.</p>

						<h3>Where can I find more information about cookies?</h3>

						<p>To learn more about cookies, please read <a href="https://www.privacypolicyonline.com/what-are-cookies/">&quot;What Are Cookies&quot;</a>.</p>
					</Col>
				</Row>
			</Container>
		</PageErrorBoundary>
	);
}