// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

//import React from "react";
import PropTypes from "prop-types";
//import { Link } from "react-router-dom";

// -----------------------------------------------------------------------------
// Link to all format periods for specific format.
// REVIEW
// TODO: Add text truncation
//const LinkToMetagameFormat = ({ children, formatId }) => (
//	<Link to={getLinkUrl(formatId)}>
//		{(children) ? children : "<missing>"}
//	</Link>
//);

const LinkToMetagameFormat = ({ children }) => (
	(children) ? children : "<missing>"
);

LinkToMetagameFormat.propTypes = {
	children: PropTypes.node.isRequired
};

export default LinkToMetagameFormat;

// -----------------------------------------------------------------------------
//function getLinkUrl(formatId) {
//	const format = formatId ? encodeURIComponent(formatId) : "missing";
//	return `/formats/${format}`;
//}