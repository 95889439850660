// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { PropTypes } from "prop-types";

import { getWebSiteEndpoints } from "webSiteEndpoints";
import { logInternalError } from "global/utilities/logging";
import Image from "global/utilities/Image";

// -----------------------------------------------------------------------------
// Image for individual card elements
// Ex: {W}, {T}, {0}
// REVIEW
const CardElementImage = ({ symbol, size, style }) => (
	<Image
		src={getSymbolUrl(symbol, size)}
		alt={symbol}
		style={style} />
);

CardElementImage.propTypes = {
	symbol: PropTypes.string.isRequired,
	size: PropTypes.string,
	style: PropTypes.object
};

export default CardElementImage;

// -----------------------------------------------------------------------------
function getSymbolUrl(symbol, optionalSize) {
	const size = optionalSize ? optionalSize : "Small";

	// FUTURE: Remove image map and calculate dynamically once backend has all images in all sizes
	if (SymbolImagesMap && SymbolImagesMap[symbol] && SymbolImagesMap[symbol][size]) {
		const filePath = SymbolImagesMap[symbol][size];
		const url = getWebSiteEndpoints().images + filePath;
		return url;
	} else {
		logInternalError(`Unsupported card element symbol: ${symbol}/${size}`);
		return "";
	}
}

// -----------------------------------------------------------------------------
// TODO: Remove now that this is standardized
const SymbolImagesMap =
{
	"{T}": { "Small": "symbols/small/t.png", "Medium": "symbols/medium/t.png", "Large": "symbols/large/t.png" },
	"{Q}": { "Small": "symbols/small/q.png", "Medium": "symbols/medium/q.png", "Large": "symbols/large/q.png" },

	"{W}": { "Small": "symbols/small/w.png", "Medium": "symbols/medium/w.png", "Large": "symbols/large/w.png" },
	"{B}": { "Small": "symbols/small/b.png", "Medium": "symbols/medium/b.png", "Large": "symbols/large/b.png" },
	"{R}": { "Small": "symbols/small/r.png", "Medium": "symbols/medium/r.png", "Large": "symbols/large/r.png" },
	"{G}": { "Small": "symbols/small/g.png", "Medium": "symbols/medium/g.png", "Large": "symbols/large/g.png" },
	"{U}": { "Small": "symbols/small/u.png", "Medium": "symbols/medium/u.png", "Large": "symbols/large/u.png" },

	"{C}": { "Small": "symbols/small/c.png", "Medium": "symbols/medium/c.png" },
	"{X}": { "Small": "symbols/small/x.png", "Medium": "symbols/medium/x.png", "Large": "symbols/large/x.png" },
	"{Y}": { "Small": "symbols/small/y.png", "Medium": "symbols/medium/y.png" },
	"{Z}": { "Small": "symbols/small/z.png", "Medium": "symbols/medium/z.png" },

	"{0}": { "Small": "symbols/small/0.png", "Medium": "symbols/medium/0.png", "Large": "symbols/large/0.png" },
	"{1}": { "Small": "symbols/small/1.png", "Medium": "symbols/medium/1.png", "Large": "symbols/large/1.png" },
	"{2}": { "Small": "symbols/small/2.png", "Medium": "symbols/medium/2.png", "Large": "symbols/large/2.png" },
	"{3}": { "Small": "symbols/small/3.png", "Medium": "symbols/medium/3.png", "Large": "symbols/large/3.png" },
	"{4}": { "Small": "symbols/small/4.png", "Medium": "symbols/medium/4.png", "Large": "symbols/large/4.png" },
	"{5}": { "Small": "symbols/small/5.png", "Medium": "symbols/medium/5.png", "Large": "symbols/large/5.png" },
	"{6}": { "Small": "symbols/small/6.png", "Medium": "symbols/medium/6.png", "Large": "symbols/large/6.png" },
	"{7}": { "Small": "symbols/small/7.png", "Medium": "symbols/medium/7.png", "Large": "symbols/large/7.png" },
	"{8}": { "Small": "symbols/small/8.png", "Medium": "symbols/medium/8.png", "Large": "symbols/large/8.png" },
	"{9}": { "Small": "symbols/small/9.png", "Medium": "symbols/medium/9.png", "Large": "symbols/large/9.png" },
	"{10}": { "Small": "symbols/small/10.png", "Medium": "symbols/medium/10.png", "Large": "symbols/large/10.png" },
	"{11}": { "Small": "symbols/small/11.png", "Medium": "symbols/medium/11.png", "Large": "symbols/large/11.png" },
	"{12}": { "Small": "symbols/small/12.png", "Medium": "symbols/medium/12.png", "Large": "symbols/large/12.png" },
	"{13}": { "Small": "symbols/small/13.png", "Medium": "symbols/medium/13.png", "Large": "symbols/large/13.png" },
	"{14}": { "Small": "symbols/small/14.png", "Medium": "symbols/medium/14.png", "Large": "symbols/large/14.png" },
	"{15}": { "Small": "symbols/small/15.png", "Medium": "symbols/medium/15.png", "Large": "symbols/large/15.png" },
	"{16}": { "Small": "symbols/small/16.png", "Medium": "symbols/medium/16.png", "Large": "symbols/large/16.png" },
	"{100}": { "Small": "symbols/small/100.png", "Medium": "symbols/medium/100.png", "Large": "symbols/large/100.png" },
	"{1000000}": { "Small": "symbols/small/1000000.png", "Medium": "symbols/medium/1000000.png", "Large": "symbols/large/1000000.png" },

	"{S}": { "Small": "symbols/small/s.png", "Medium": "symbols/medium/s.png", "Large": "symbols/large/s.png" },

	"{W/U}": { "Small": "symbols/small/wu.png", "Medium": "symbols/medium/wu.png", "Large": "symbols/large/wu.png" },
	"{U/B}": { "Small": "symbols/small/ub.png", "Medium": "symbols/medium/ub.png", "Large": "symbols/large/ub.png" },
	"{B/R}": { "Small": "symbols/small/br.png", "Medium": "symbols/medium/br.png", "Large": "symbols/large/br.png" },
	"{R/G}": { "Small": "symbols/small/rg.png", "Medium": "symbols/medium/rg.png", "Large": "symbols/large/rg.png" },
	"{G/W}": { "Small": "symbols/small/gw.png", "Medium": "symbols/medium/gw.png", "Large": "symbols/large/gw.png" },
	"{W/B}": { "Small": "symbols/small/wb.png", "Medium": "symbols/medium/wb.png", "Large": "symbols/large/wb.png" },
	"{U/R}": { "Small": "symbols/small/ur.png", "Medium": "symbols/medium/ur.png", "Large": "symbols/large/ur.png" },
	"{B/G}": { "Small": "symbols/small/bg.png", "Medium": "symbols/medium/bg.png", "Large": "symbols/large/bg.png" },
	"{R/W}": { "Small": "symbols/small/rw.png", "Medium": "symbols/medium/rw.png", "Large": "symbols/large/rw.png" },
	"{G/U}": { "Small": "symbols/small/gu.png", "Medium": "symbols/medium/gu.png", "Large": "symbols/large/gu.png" },

	"{W/U/P}": { "Small": "symbols/small/wup.png", "Medium": "symbols/medium/wup.png", "Large": "symbols/large/wup.png" },
	"{U/B/P}": { "Small": "symbols/small/ubp.png", "Medium": "symbols/medium/ubp.png", "Large": "symbols/large/ubp.png" },
	"{B/R/P}": { "Small": "symbols/small/brp.png", "Medium": "symbols/medium/brp.png", "Large": "symbols/large/brp.png" },
	"{R/G/P}": { "Small": "symbols/small/rgp.png", "Medium": "symbols/medium/rgp.png", "Large": "symbols/large/rgp.png" },
	"{G/W/P}": { "Small": "symbols/small/gwp.png", "Medium": "symbols/medium/gwp.png", "Large": "symbols/large/gwp.png" },
	"{W/B/P}": { "Small": "symbols/small/wbp.png", "Medium": "symbols/medium/wbp.png", "Large": "symbols/large/wbp.png" },
	"{U/R/P}": { "Small": "symbols/small/urp.png", "Medium": "symbols/medium/urp.png", "Large": "symbols/large/urp.png" },
	"{B/G/P}": { "Small": "symbols/small/bgp.png", "Medium": "symbols/medium/bgp.png", "Large": "symbols/large/bgp.png" },
	"{R/W/P}": { "Small": "symbols/small/rwp.png", "Medium": "symbols/medium/rwp.png", "Large": "symbols/large/rwp.png" },
	"{G/U/P}": { "Small": "symbols/small/gup.png", "Medium": "symbols/medium/gup.png", "Large": "symbols/large/gup.png" },

	"{P}": { "Small": "symbols/small/p.png", "Medium": "symbols/medium/p.png" },
	"{W/P}": { "Small": "symbols/small/wp.png", "Medium": "symbols/medium/wp.png" },
	"{U/P}": { "Small": "symbols/small/up.png", "Medium": "symbols/medium/up.png" },
	"{B/P}": { "Small": "symbols/small/bp.png", "Medium": "symbols/medium/bp.png" },
	"{R/P}": { "Small": "symbols/small/rp.png", "Medium": "symbols/medium/rp.png" },
	"{G/P}": { "Small": "symbols/small/gp.png", "Medium": "symbols/medium/gp.png" },
	"{2/W}": { "Small": "symbols/small/2w.png", "Medium": "symbols/medium/2w.png", "Large": "symbols/large/2w.png" },
	"{2/B}": { "Small": "symbols/small/2b.png", "Medium": "symbols/medium/2b.png", "Large": "symbols/large/2b.png" },
	"{2/R}": { "Small": "symbols/small/2r.png", "Medium": "symbols/medium/2r.png", "Large": "symbols/large/2r.png" },
	"{2/G}": { "Small": "symbols/small/2g.png", "Medium": "symbols/medium/2g.png", "Large": "symbols/large/2g.png" },
	"{2/U}": { "Small": "symbols/small/2u.png", "Medium": "symbols/medium/2u.png", "Large": "symbols/large/2u.png" },

	"{E}": { "Small": "symbols/small/e.png", "Medium": "symbols/medium/e.png" },
	"{CHAOS}": { "Small": "symbols/small/chaos.png" },
	"{PW}": { "Small": "symbols/small/pw.png", "Medium": "symbols/medium/pw.png", "Large": "symbols/large/pw.png" },
	"{∞}": { "Small": "symbols/small/∞.png", "Medium": "symbols/medium/∞.png", "Large": "symbols/large/∞.png" },
	"{HW}": { "Small": "symbols/small/hw.png", "Medium": "symbols/medium/hw.png", "Large": "symbols/large/hw.png" },
	"{HR}": { "Small": "symbols/small/hr.png", "Medium": "symbols/medium/hr.png", "Large": "symbols/large/hr.png" }
};