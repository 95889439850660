// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { PropTypes } from "prop-types";

import Price from "./Price";

// -----------------------------------------------------------------------------
// Format given price range with specified number of decimals places.
// REVIEW
// TODO: Handling missing value better, especially when both are missing
const PriceRange = ({ lowerValue, upperValue, decimalPlaces }) =>
	<>
		<Price value={lowerValue} decimalPlaces={decimalPlaces} />
		{" - "}
		<Price value={upperValue} decimalPlaces={decimalPlaces} />
	</>;

PriceRange.propTypes = {
	lowerValue: PropTypes.number,
	upperValue: PropTypes.number,
	decimalPlaces: PropTypes.number
};

PriceRange.defaultProps = {
	decimalPlaces: 2
};

export default PriceRange;