// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { Container, Col, Row } from "reactstrap";

import PageErrorBoundary from "./PageErrorBoundary";

// -----------------------------------------------------------------------------
// Placeholder for page that hasn't been built yet.
// REVIEW
const ComingSoonPage = () => (
	<PageErrorBoundary>
		<Container>
			<Row>
				<Col>
					<div className="p-5 mb-4 bg-secondary rounded-3 text-center">
						<h1 className="display-3 text-primary">
							Coming Soon
						</h1>
						<p className="lead">
							{"We haven't finished building this page yet - check back soon"}
							{" "}
							<span className="fas fa-coffee" />
						</p>
					</div>
				</Col>
			</Row>
		</Container>
	</PageErrorBoundary>
);

export default ComingSoonPage;