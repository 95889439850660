// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

// -------------------------------------------------------------------------------------------------
// List of REST API endpoints for each module
// REVIEW
const apiEndpoints = {
	dev: {
		cards: "https://apidev.mtgtopdecks.com/cards",
		decks: "https://apidev.mtgtopdecks.com/decks",
		metagames: "https://apidev.mtgtopdecks.com/metagames",
		prices: "https://apidev.mtgtopdecks.com/prices",
		settings: "https://apidev.mtgtopdecks.com/settings",
	},
	prod: {
		cards: "https://api.mtgtopdecks.com/cards",
		decks: "https://api.mtgtopdecks.com/decks",
		metagames: "https://api.mtgtopdecks.com/metagames",
		prices: "https://api.mtgtopdecks.com/prices",
		settings: "https://api.mtgtopdecks.com/settings",
	}
};

export {
	apiEndpoints
};