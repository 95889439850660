// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import { logInternalError } from "./logging";

// -----------------------------------------------------------------------------
// REVIEW
function copyToClipboard(text) {
	if (navigator.clipboard) {
		copyToClipboardNewMethod(text);
	} else {
		// BUG: Causes big flicker
		copyToClipboardOldMethod(text);
	}
}

export {
	copyToClipboard
};

// -----------------------------------------------------------------------------
function copyToClipboardOldMethod(text) {
	let textArea = document.createElement("textarea");
	textArea.value = text;
	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();

	try {
		const successful = document.execCommand("copy");

		if (!successful) {
			logInternalError("Failed to copy to clipboard");
		}
	} catch (error) {
		logInternalError("Failed to copy to clipboard: " + error);
	}

	document.body.removeChild(textArea);
}

// -----------------------------------------------------------------------------
function copyToClipboardNewMethod(text) {
	navigator.clipboard.writeText(text)
		.catch(errorResponse => logInternalError(errorResponse));
}