// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { Table } from "reactstrap";
import { Button } from "reactstrap";
import { Badge } from "react-bootstrap";

//import FormattedDate from "global/utilities/Date";
//import Price from "global/utilities/Price";
//import PriceRange from "global/utilities/PriceRange";
import { formatPrice } from "global/utilities/format";
import LinkToCard from "common/links/LinkToCard";
//import TableCellItem from "common/TableCellItem";
//import ColorIdentities from "common/ColorIdentities";
//import ColorIdentity from "common/ColorIdentity";
import CardRarityImagePopover from "common/CardRarityImagePopover";
import ManaCost from "common/ManaCost";
//import PopularityDirectionIndicator from "common/PopularityDirectionIndicator";
//import LinkToDeckSearchResults from "common/links/LinkToDeckSearchResults";

import { metagameCardStats } from "apiSchemas";
//import { getFormatDisplayName } from "common/resources/formatNames";

// -------------------------------------------------------------------------------------------------
// Table that shows list of linked tournament summaries.
// REVIEW
export default function MetagameFormatPeriodCardsTable({ cards, displayOptions }) {
	const [showAll, setShowAll] = useState(false);
	const tableNotStriped = displayOptions && displayOptions.showTableNotStriped;
	const tableBordered = displayOptions && displayOptions.showTableBordered;
	const topCardCount = 10;
	const filteredCards = showAll ? cards : cards.slice(0, topCardCount);

	return (
		<>
			<Table striped={!tableNotStriped} bordered={tableBordered} size="sm">
				<thead>
					<tr>
						<th>Card</th>
						<th className="text-end"># of Decks</th>
						<th className="text-end"># of Copies</th>
						<th className="text-end"># of Archetypes</th>
						<th className="text-end">Price</th>
					</tr>
				</thead>

				<tbody>
					{cards && cards.length > 0 && filteredCards.map(card =>
						<MetagameFormatPeriodCardsRow
							key={card.cardId}
							card={card} />
					)}
					{(!cards || cards.length === 0) &&
						<tr>
							<td className="text-muted text-center" colSpan={2}>
								No cards found
							</td>
						</tr>
					}
				</tbody>
			</Table>
			{cards && cards.length > topCardCount &&
				<div className="text-center">
					{showAll &&
						<Button style={{ cursor: "pointer" }} color="link" onClick={() => setShowAll(false)}>
							show less...
						</Button>
					}
					{!showAll &&
						<Button style={{ cursor: "pointer" }} color="link" onClick={() => setShowAll(true)}>
							show more...
						</Button>
					}
				</div>
			}
		</>
	);
}

MetagameFormatPeriodCardsTable.propTypes = {
	cards: PropTypes.arrayOf(PropTypes.shape(metagameCardStats)).isRequired,
	displayOptions: PropTypes.object
};

// -------------------------------------------------------------------------------------------------
function MetagameFormatPeriodCardsRow({ card }) {
	return (
		<tr>
			<td style={{ maxWidth: "150px" }}>
				<CardRarityImagePopover cardId={card.cardId} multiface={card.multiface} cardVariation={card.cardVariation} rarity={card.cardRarity} size="Small" style={{ maxHeight: 16, maxWidth: 24 }} deckLocation="cards"/>
				{" "}
				<LinkToCard cardId={card.cardId} multiface={card.multiface} cardVariation={card.cardVariation}>
					{card.cardName}
				</LinkToCard>
				{" "}
				<ManaCost cost={card.cardManaCost} />
				{card.isReserveList && <>{" "}<Badge pill bg="secondary" text="dark">reserved list</Badge></>}
			</td>

			<td className="text-end" style={{ maxWidth: "150px" }}>
				{card.numberOfDecks}
			</td>

			<td className="text-end" style={{ maxWidth: "150px" }}>
				{card.numberOfCopies}
			</td>

			<td className="text-end" style={{ maxWidth: "150px" }}>
				{card.numberOfArchetypes}
			</td>

			<td className="text-end" style={{ maxWidth: "150px" }}>
				{formatPrice(card.price)}
			</td>
		</tr>
	);
}

MetagameFormatPeriodCardsRow.propTypes = {
	card: PropTypes.shape(metagameCardStats).isRequired
};