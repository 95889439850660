// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { PropTypes } from "prop-types";

import { getWebSiteEndpoints } from "webSiteEndpoints";
import { logInternalError } from "global/utilities/logging";
import Image from "global/utilities/Image";
import { getCardSetId } from "common/cardIdUtilities";

// -------------------------------------------------------------------------------------------------
// Set symbol of specific rarity.
// REVIEW
function CardRarityImage({ cardId, rarity, size, style, id }) {
	const fallbackSrc = rarity === "S" ? getRarityImageUrl(cardId, "B", size) : null;
	return <Image
		src={getRarityImageUrl(cardId, rarity, size)}
		fallbackSrc={fallbackSrc}
		alt={rarity ? rarity : "missing rarity"}
		style={style}
		id={id}/>;
}

CardRarityImage.propTypes = {
	cardId: PropTypes.number.isRequired,
	rarity: PropTypes.string,
	size: PropTypes.string,
	style: PropTypes.object,
	id: PropTypes.string
};

export default CardRarityImage;

// -------------------------------------------------------------------------------------------------
function getRarityImageUrl(cardId, optionalRarity, optionalSize) {
	if (cardId) {
		const setId = getCardSetId(cardId);
		const rarity = getRarity(optionalRarity);
		const size = getSize(optionalSize);
		const url = `${getWebSiteEndpoints().images}sets/rarity/${encodeURIComponent(setId)}-${size}-${rarity}.png`;

		return url;
	} else {
		return null;
	}
}

// -------------------------------------------------------------------------------------------------
function getRarity(optionalRarity) {
	if (!optionalRarity) {
		return "c";
	}
	
	const rarity = encodeURIComponent(optionalRarity.toLowerCase());
	
	return rarity;
}

// -------------------------------------------------------------------------------------------------
function getSize(optionalSize) {
	if (!optionalSize) {
		return "small";
	} else if (optionalSize === "Small") {
		return "small";
	} else if (optionalSize === "Medium") {
		return "medium";
	} else if (optionalSize === "Large") {
		return "large";
	} else {
		logInternalError(`Invalid card rarity size: ${optionalSize}`);
		return "small";
	}
}