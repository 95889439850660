// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// -----------------------------------------------------------------------------
// Link back to home page.
// REVIEW
const LinkToTopCards = ({ children }) => (
	<Link to="/top/cards">
		{(children) ? children : "<missing>"}
	</Link>
);

LinkToTopCards.propTypes = {
	children: PropTypes.node.isRequired
};

export default LinkToTopCards;