// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import { joinString } from "global/utilities/format";

// -------------------------------------------------------------------------------------------------
// Resource map for format display name.
// REVIEW
const formatDisplayNameMap = {
	"standard": "Standard",
	"alchemy": "Alchemy",
	"historic": "Historic",
	"pioneer": "Pioneer",
	"explorer": "Explorer",
	"modern": "Modern",
	"modern-no-bans": "Modern (No Bans)",
	"legacy": "Legacy",
	"vintage": "Vintage",
	"pauper": "Pauper",
	"extended": "Extended",
	"frontier": "Frontier",
	"block": "Block",
	"old-school": "Old School",

	"draft": "Draft",
	"sealed": "Sealed",

	"online-duel-commander": "Commander 1v1",
	"edh-commander": "EDH Commander",
	"brawl": "Brawl",
	"historic-brawl": "Historic Brawl",
	"tiny-leaders": "Tiny Leaders",
	"gladiator": "Gladiator",

	"missing": "missing"
};

// -------------------------------------------------------------------------------------------------
export function getFormatDisplayName(formatId) {
	if (formatId && formatDisplayNameMap[formatId]) {
		return formatDisplayNameMap[formatId];
	} else {
		return "<unmapped>";
	}
}

// -------------------------------------------------------------------------------------------------
export function getFormatDisplayNames(formatIds) {
	if (formatIds && Array.isArray(formatIds) && formatIds.length > 0) {
		const formatDisplayNames = formatIds.map(formatId => getFormatDisplayName(formatId));
		return joinString(formatDisplayNames, " / ");
	} else {
		return "<unmapped>";
	}
}