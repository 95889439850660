// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

// -----------------------------------------------------------------------------
// Current state of fetch request.
// REVIEW
const fetchStateEnum = {
	initializing: "initializing",
	loading: "loading",
	loaded: "loaded",
	error: "error"
};

export {
	fetchStateEnum
};