// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// -----------------------------------------------------------------------------
// Link back to home page.
// REVIEW
const LinkToHomePage = ({ children }) => (
	<Link to="/">
		{(children) ? children : "<missing>"}
	</Link>
);

LinkToHomePage.propTypes = {
	children: PropTypes.node.isRequired
};

export default LinkToHomePage;