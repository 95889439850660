// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React, { Suspense, useState } from "react";
import { PropTypes } from "prop-types";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
//import { Link as RoutingNavLink } from "react-router-dom";
import { ButtonGroup, Button } from "reactstrap";
import { Container, Row, Col } from "reactstrap";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
//import { Nav, NavItem, NavLink } from "reactstrap";
//import { Button } from "reactstrap";
import { Helmet } from "react-helmet";

import PageErrorBoundary from "global/errors/PageErrorBoundary";
import ErrorLoadingSection from "global/errors/ErrorLoadingSection";
import LoadingSpinner from "global/loading/LoadingSpinner";
import LinkToHomePage from "common/links/LinkToHomePage";
import LinkToMetagameFormat from "common/links/LinkToMetagameFormat";
import MetagameFormatPeriodCardsTable from "./MetagameFormatPeriodCardsTable";
import CardElementImage from "common/CardElementImage";
import CardSetImage from "common/CardSetImage";

import { metagameFormatPeriodCardsSchema } from "apiSchemas";
import { constructGetMetagameFormatPeriodCardsUrl } from "api";
import { fetchResource } from "global/loading/fetchResource";
import { formatInteger } from "global/utilities/format";
import { parseUrlSearchParameters } from "global/utilities/parseUrlSearchParameters";
import { getFormatDisplayName } from "common/resources/formatNames";
import { getCurrentDateIso8601 } from "global/utilities/Date";

// -------------------------------------------------------------------------------------------------
// Page that shows list of archetypes
// CREATING
export default function MetagameFormatPeriodCardsPage() {
	const { formatId } = useParams();
	const location = useLocation();
	const urlFilterParameters = parseUrlSearchParameters(location.search);

	return (
		<PageErrorBoundary>
			<MetagameFormatPeriodCardsPageContents formatId={formatId} urlFilterParameters={urlFilterParameters} />
		</PageErrorBoundary>
	);
}

// -------------------------------------------------------------------------------------------------
export function MetagameFormatPeriodCardsPageContents({ formatId, urlFilterParameters, displayOptions }) {
	const formatPeriodId = urlFilterParameters.period;
	const getMetagameFormatPeriodCardsUrl = constructGetMetagameFormatPeriodCardsUrl(formatId, formatPeriodId);
	const metagameFormatPeriodCardsResource = fetchResource(getMetagameFormatPeriodCardsUrl);

	return (
		<Container>
			<Row>
				<Col>
					<Breadcrumbs formatId={formatId} />
				</Col>
			</Row>

			<Suspense fallback={<LoadingSpinner />}>
				<Row>
					<Col>
						<MetagameFormatPeriodCardsLoader
							metagameFormatPeriodCardsResource={metagameFormatPeriodCardsResource}
							displayOptions={displayOptions}
							url={getMetagameFormatPeriodCardsUrl} />
					</Col>
				</Row>
			</Suspense>
		</Container>
	);
}

MetagameFormatPeriodCardsPageContents.propTypes = {
	formatId: PropTypes.string,
	urlFilterParameters: PropTypes.object,
	displayOptions: PropTypes.object
};

// -------------------------------------------------------------------------------------------------
function MetagameFormatPeriodCardsLoader({ metagameFormatPeriodCardsResource, displayOptions, url }) {
	const [metagameFormatPeriodCards, setMetagameFormatPeriodCards] = useState();
	const [metagameFormatPeriodCardsState, setMetagameFormatPeriodCardsState] = useState();
	const [currentUrl, setCurrentUrl] = useState();

	if (currentUrl !== url) {
		setMetagameFormatPeriodCards(metagameFormatPeriodCardsResource.data);
		setMetagameFormatPeriodCardsState(metagameFormatPeriodCardsResource.state);
		setCurrentUrl(url);
	}

	if (!currentUrl) {
		return null;
	}

	return (
		<>
			{metagameFormatPeriodCards &&
				<MetagameFormatPeriodCards stats={metagameFormatPeriodCards} displayOptions={displayOptions} />}
			{metagameFormatPeriodCardsState && metagameFormatPeriodCardsState.isError &&
				<ErrorLoadingSection errorMessage={metagameFormatPeriodCardsState.errorMessage} />
			}
		</>
	);
}

MetagameFormatPeriodCardsLoader.propTypes = {
	url: PropTypes.string,
	metagameFormatPeriodCardsResource: PropTypes.object,
	displayOptions: PropTypes.object
};

// -------------------------------------------------------------------------------------------------
function MetagameFormatPeriodCards({ stats }) {
	const [currentTab, setCurrentTab] = useState("byEarliestSet");

	return (
		<>
			<div className="text-center">
				<ButtonGroup className="p-2">
					<Button style={{ cursor: "pointer" }} onClick={() => setCurrentTab("byEarliestSet")} active={currentTab === "byEarliestSet"}>By Earliest Set</Button>
					<Button style={{ cursor: "pointer" }} onClick={() => setCurrentTab("byLatestSet")} active={currentTab === "byLatestSet"}>By Latest Set</Button>
					<Button style={{ cursor: "pointer" }} onClick={() => setCurrentTab("byColor")} active={currentTab === "byColor"}>By Color</Button>
					<Button style={{ cursor: "pointer" }} onClick={() => setCurrentTab("byCardType")} active={currentTab === "byCardType"}>By Card Type</Button>
					<Button style={{ cursor: "pointer" }} onClick={() => setCurrentTab("byRarity")} active={currentTab === "byRarity"}>By Rarity</Button>
				</ButtonGroup>
			</div>

			{currentTab === "byEarliestSet" && <MetagameFormatPeriodCardsByEarliestSet stats={stats} />}
			{currentTab === "byLatestSet" && <MetagameFormatPeriodCardsByLatestSet stats={stats} />}
			{currentTab === "byColor" && <MetagameFormatPeriodCardsByColor stats={stats} />}
			{currentTab === "byCardType" && <MetagameFormatPeriodCardsByCardType stats={stats} />}
			{currentTab === "byRarity" && <MetagameFormatPeriodCardsByRarity stats={stats} />}
		</>
	);

}

MetagameFormatPeriodCards.propTypes = {
	stats: PropTypes.shape(metagameFormatPeriodCardsSchema).isRequired
};

// -------------------------------------------------------------------------------------------------
function MetagameFormatPeriodCardsByEarliestSet({ stats }) {
	return (
		<>
			{stats && stats.cardsByFirstSet && stats.cardsByFirstSet.length > 0 && stats.cardsByFirstSet.map(group =>
				<React.Fragment key={group.key.cardSetName}>
					<h3>
						{group.key.cardSetName}
						{" "}
						{"(" + formatInteger(group.values.length) + ")"}
						{" "}
						<CardSetImage cardSetId={group.key.cardSetId} style={{ maxWidth: 16, maxHeight: 16 }} />
					</h3>
					<MetagameFormatPeriodCardsTable cards={group.values} />
				</React.Fragment>

			)}
			{(!stats || !stats.cardsByFirstSet || stats.cardsByFirstSet.length === 0) && <p className="text-center">{"No stats available"}</p>}
		</>
	);
}

MetagameFormatPeriodCardsByEarliestSet.propTypes = {
	stats: PropTypes.shape(metagameFormatPeriodCardsSchema)
};

function MetagameFormatPeriodCardsByLatestSet({ stats }) {
	return (
		<>
			{stats && stats.cardsByLastSet && stats.cardsByLastSet.length > 0 && stats.cardsByLastSet.map(group =>
				<React.Fragment key={group.key.cardSetName}>
					<h3>
						{group.key.cardSetName}
						{" "}
						{"(" + formatInteger(group.values.length) + ")"}
						{" "}
						<CardSetImage cardSetId={group.key.cardSetId} style={{ maxWidth: 16, maxHeight: 16 }} />
					</h3>
					<MetagameFormatPeriodCardsTable cards={group.values} />
				</React.Fragment>

			)}
			{(!stats || !stats.cardsByLastSet || stats.cardsByLastSet.length === 0) && <p className="text-center">{"No stats available"}</p>}
		</>
	);
}

MetagameFormatPeriodCardsByLatestSet.propTypes = {
	stats: PropTypes.shape(metagameFormatPeriodCardsSchema)
};

function MetagameFormatPeriodCardsByColor({ stats }) {
	return (
		<>
			{stats && stats.cardsByColor && stats.cardsByColor.length > 0 && stats.cardsByColor.map(group =>
				<React.Fragment key={group.key.color}>
					<h3>
						{getColorDisplayName(group.key.color)}
						{" "}
						{"(" + formatInteger(group.values.length) + ")"}
						{" "}
						<CardElementImage symbol={"{" + group.key.color + "}"} size="Medium" />
					</h3>
					<MetagameFormatPeriodCardsTable cards={group.values} />
				</React.Fragment>
			)}
			{(!stats || !stats.cardsByColor || stats.cardsByColor.length === 0) && <p className="text-center">{"No stats available"}</p>}
		</>
	);
}

MetagameFormatPeriodCardsByColor.propTypes = {
	stats: PropTypes.shape(metagameFormatPeriodCardsSchema)
};

function getColorDisplayName(color) {
	if (color === "W") {
		return "White";
	} else if (color === "U") {
		return "Blue";
	} else if (color === "B") {
		return "Black";
	} else if (color === "R") {
		return "Red";
	} else if (color === "G") {
		return "Green";
	} else if (color === "M") {
		return "Multicolor";
	} else if (color === "C") {
		return "Colorless";
	} else {
		return "Unknown";
	}
}

function MetagameFormatPeriodCardsByCardType({ stats }) {
	return (
		<>
			{stats && stats.cardsByCardType && stats.cardsByCardType.length > 0 && stats.cardsByCardType.map(group =>
				<React.Fragment key={group.key.primaryCardType}>
					<h3>
						{getCardTypeName(group.key.primaryCardType)}
						{" "}
						{"(" + formatInteger(group.values.length) + ")"}
					</h3>
					<MetagameFormatPeriodCardsTable cards={group.values} />
				</React.Fragment>
			)}
			{(!stats || !stats.cardsByCardType || stats.cardsByCardType.length === 0) && <p className="text-center">{"No stats available"}</p>}
		</>
	);
}

MetagameFormatPeriodCardsByCardType.propTypes = {
	stats: PropTypes.shape(metagameFormatPeriodCardsSchema)
};

function getCardTypeName(cardType) {
	if (cardType === 1) {
		return "Land";
	} else if (cardType === 2) {
		return "Creature";
	} else if (cardType === 4) {
		return "Sorcery";
	} else if (cardType === 8) {
		return "Instant";
	} else if (cardType === 16) {
		return "Enchantment";
	} else if (cardType === 32) {
		return "Artifact";
	} else if (cardType === 64) {
		return "Planeswalker";
	} else if (cardType === 32768) {
		return "Battle";
	} else {
		return "<Unknown>";
	}
}
function MetagameFormatPeriodCardsByRarity({ stats }) {
	return (
		<>
			{stats && stats.cardsByRarity && stats.cardsByRarity.length > 0 && stats.cardsByRarity.map(group =>
				<React.Fragment key={group.key.rarity}>
					<h3>
						{getRarityDisplayName(group.key.rarity)}
						{" "}
						{"(" + formatInteger(group.values.length) + ")"}
					</h3>
					<MetagameFormatPeriodCardsTable cards={group.values} />
				</React.Fragment>
			)}
			{(!stats || !stats.cardsByRarity || stats.cardsByRarity.length === 0) && <p className="text-center">{"No stats available"}</p>}
		</>
	);
}

MetagameFormatPeriodCardsByRarity.propTypes = {
	stats: PropTypes.shape(metagameFormatPeriodCardsSchema)
};

function getRarityDisplayName(rarity) {
	if (rarity === "C") {
		return "Common";
	} else if (rarity === "U") {
		return "Uncommon";
	} else if (rarity === "R") {
		return "Rare";
	} else if (rarity === "M") {
		return "Mythic";
	} else {
		return "Other";
	}
}

// -------------------------------------------------------------------------------------------------
function Breadcrumbs({ formatId }) {
	const rootDomain = `${window.location.protocol}//${window.location.hostname}`;
	const formatName = getFormatDisplayName(formatId);
	const title = `Card Stats (${formatName}) | Magic the Gathering | MTG Top Decks`;
	const shortTitle = `Card Stats (${formatName})`;
	const description = `Cards statistics for archetypes in ${formatName} metagame, collected from recent Magic the Gathering tournaments`;
	const keywords = `Magic the Gathering, card stats, archetypes, metagame, ${formatName}, decks, tournaments`;
	const date = getCurrentDateIso8601();
	const breadcrumbsLdJson = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement:": [{
			"@type": "ListItem",
			"position": 1,
			"name": "Home",
			"item": rootDomain
		},
		{
			"@type": "ListItem",
			"position": 2,
			"name": "Metagames",
			"item": rootDomain + "/metagames"
		},
		{
			"@type": "ListItem",
			"position": 3,
			"name": formatName,
			"item": rootDomain + `/metagames/${formatId}`
		},
		{
			"@type": "ListItem",
			"position": 4,
			"name": "Cards"
		}]
	};

	const pageLdJson = {
		"@context": "https://schema.org",
		"@type": "Article",
		"description": description,
		"keywords": keywords,
		"dateCreated": date,
		"dateModified": date,
		"headline": title,
		"image": [
			rootDomain + "/assets/logo.png"
		],
		"author": [{
			"@type": "Organization",
			"name": "MTG Top Decks",
			"url": rootDomain
		}]
	};

	return (
		<>
			<Helmet>
				<title>{title}</title>
				<meta name="description" content={description} />
				<meta property="og:title" content={shortTitle} />
				<meta property="og:description" content={description} />
				<meta property="og:type" content="article" />
				<meta property="og:url" content={window.location.href} />
				<meta property="og:modified_time" content={date} />
				<meta property="og:site_name" content="MTG Top Decks" />
				<script type="application/ld+json">{JSON.stringify(breadcrumbsLdJson)}</script>
				<script type="application/ld+json">{JSON.stringify(pageLdJson)}</script>
			</Helmet>

			<Breadcrumb>
				<BreadcrumbItem><LinkToHomePage>Home</LinkToHomePage></BreadcrumbItem>
				<BreadcrumbItem><LinkToMetagameFormat formatId={formatId}>{formatId && getFormatDisplayName(formatId)}</LinkToMetagameFormat></BreadcrumbItem>
				<BreadcrumbItem active>Cards</BreadcrumbItem>
			</Breadcrumb>
		</>
	);
}

Breadcrumbs.propTypes = {
	formatId: PropTypes.string
};