// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React, { useState } from "react";
import PageErrorBoundary from "global/errors/PageErrorBoundary";
import { Form, FormGroup, Label, Input } from "reactstrap";
import { Container, Row } from "reactstrap";
import { Link as RoutingNavLink } from "react-router-dom";

import { isAnalyticsDisabled, setIsAnalyticsDisabled } from "global/utilities/analytics";

// -------------------------------------------------------------------------------------------------
// Page that shows tracking settings.
// REVIEW
export default function TrackingPage() {
	const [isAnalyticsDisabledUI, setIsAnalyticsDisabledUI] = useState(isAnalyticsDisabled());

	return (
		<PageErrorBoundary>
			<Container>
				<Row>
					<Form>
						<h2>Analytics Settings</h2>
						<p>
							You can read our <RoutingNavLink to="/cookies">Cookie Policy</RoutingNavLink> to learn more about how and why we collect analytics.
						</p>
						<FormGroup check>
							<Label check>
								{/* TODO: Replace with switch after moving to react-bootstrap */}
								<Input
									type="checkbox"
									checked={isAnalyticsDisabledUI}
									onChange={() => {
										const isDisabled = !isAnalyticsDisabledUI;
										setIsAnalyticsDisabled(isDisabled);
										setIsAnalyticsDisabledUI(isDisabled);
									}} />

								Disable Google Analytics
							</Label>
						</FormGroup>
					</Form>
				</Row>
			</Container>
		</PageErrorBoundary>
	);
}