// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { PropTypes } from "prop-types";

// -----------------------------------------------------------------------------
// Display error message for section failing to load.
// REVIEW
const ErrorLoadingSection = ({ errorMessage }) => (
	<div className="p-5 mb-4 bg-secondary rounded-3 text-center">
		<h3 className="text-primary">
			:-(
		</h3>

		<p>
			{"Oops, something went wrong:"}
		</p>

		<p>
			<code>{(errorMessage) ? errorMessage : "<no message>"}</code>
		</p>

		<p>
			This error has been logged and will be investigated by our administrators
			{" "}
			<span className="fas fa-bug" />
		</p>
	</div>
);

ErrorLoadingSection.propTypes = {
	errorMessage: PropTypes.string.isRequired
};

export default ErrorLoadingSection;