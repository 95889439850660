// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React, { Suspense } from "react";
import { PropTypes } from "prop-types";
import { Table } from "reactstrap";

import PageErrorBoundary from "global/errors/PageErrorBoundary";
import ErrorLoadingSection from "global/errors/ErrorLoadingSection";
import LoadingSpinner from "global/loading/LoadingSpinner";
import FormattedDate from "global/utilities/Date";
import LinkToCards from "common/links/LinkToCards";
import CardSetImage from "common/CardSetImage";
import CardRarityImage from "common/CardRarityImage";

import { constructGetCardSetsUrl } from "api";
import { cardSetSchema } from "apiSchemas";
import { fetchResource } from "global/loading/fetchResource";

// -------------------------------------------------------------------------------------------------
// Page that shows internal list of all card sets.
// REVIEW
export default function InternalCardPage() {
	return (
		<PageErrorBoundary>
			<InternalCardSetsPageContents />
		</PageErrorBoundary>
	);
}

// -------------------------------------------------------------------------------------------------
export function InternalCardSetsPageContents() {
	const getCardSetsUrl = constructGetCardSetsUrl();
	const cardSetsResource = fetchResource(getCardSetsUrl);

	return (
		<Suspense fallback={<LoadingSpinner />}>
			<InternalCardSetsPageContentsLoader cardSetsResource={cardSetsResource} />
		</Suspense>
	);
}

InternalCardSetsPageContents.propTypes = {
	displayOptions: PropTypes.object
};

// -------------------------------------------------------------------------------------------------
function InternalCardSetsPageContentsLoader({ cardSetsResource }) {
	const cardSets = cardSetsResource.data;
	const cardState = cardSetsResource.state;

	return (
		<>
			{cardSets &&
				<InternalCardSetsSection cardSets={cardSets} />
			}
			{cardState && cardState.isError &&
				<ErrorLoadingSection errorMessage={cardState.errorMessage} />
			}
		</>
	);
}

InternalCardSetsPageContentsLoader.propTypes = {
	cardSetsResource: PropTypes.object
};

// -------------------------------------------------------------------------------------------------
export function InternalCardSetsSection({ cardSets }) {
	return (
		cardSets &&
		<Table size="sm">
			<thead>
				<tr>
					<th>Set</th>
					<th>Type</th>
					<th>Legality</th>
					<th className="text-end"># of Cards</th>
					<th className="text-end">Order</th>
					<th>Symbols</th>
					<th>Release Date</th>
				</tr>
			</thead>

			<tbody>
				{cardSets.map(cardSet =>
					<InternalCardSetRow key={cardSet.setId} cardSet={cardSet} />
				)}
			</tbody>
		</Table>
	);
}

InternalCardSetsSection.propTypes = {
	cardSets: PropTypes.arrayOf(PropTypes.shape(cardSetSchema)).isRequired
};

// -------------------------------------------------------------------------------------------------
export function InternalCardSetRow({ cardSet }) {
	return (
		cardSet &&
		<tr>
			<td className={cardSet.parentCardSetId ? "ps-4" : ""}>
				<LinkToCards cardSetId={cardSet.cardSetId}>
					{"["}
					{cardSet.cardSetId}
					{"] "}
					{cardSet.cardSetName}
					{" ("}
					{cardSet.cardSetCode}
					{")"}
				</LinkToCards>
				{" "}
				<CardSetImage cardSetId={cardSet.cardSetId} style={{ width: 16, height: 16 }} />
			</td>
			<td>
				{cardSet.cardSetCategory}
			</td>
			<td>
				{cardSet.legalInFormats}
			</td>
			<td className="text-end">
				{cardSet.numberOfCards}
			</td>
			<td className="text-end">
				{cardSet.displayOrder}
				{" / "}
				{cardSet.latestPrintingPriorityOrder}
				{" / "}
				{cardSet.earliestPrintingPriorityOrder}
			</td>
			<td>
				<CardRarityImage cardId={cardSet.cardSetId} rarity="c" size="Small" style={{ maxHeight: 16, maxWidth: 24 }} />
				<CardRarityImage cardId={cardSet.cardSetId} rarity="u" size="Small" style={{ maxHeight: 16, maxWidth: 24 }} />
				<CardRarityImage cardId={cardSet.cardSetId} rarity="r" size="Small" style={{ maxHeight: 16, maxWidth: 24 }} />
				<CardRarityImage cardId={cardSet.cardSetId} rarity="m" size="Small" style={{ maxHeight: 16, maxWidth: 24 }} />
				<CardRarityImage cardId={cardSet.cardSetId} rarity="s" size="Small" style={{ maxHeight: 16, maxWidth: 24 }} />
				<CardRarityImage cardId={cardSet.cardSetId} rarity="b" size="Small" style={{ maxHeight: 16, maxWidth: 24 }} />
			</td>
			<td>
				<FormattedDate value={cardSet.releaseDate} />

				{cardSet.digitalReleaseDate &&
					<>
						{" / "}
						<FormattedDate value={cardSet.digitalReleaseDate} />
					</>
				}
			</td>
		</tr>
	);
}

InternalCardSetRow.propTypes = {
	cardSet: PropTypes.shape(cardSetSchema).isRequired
};