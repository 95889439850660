// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";

// -----------------------------------------------------------------------------
// Link to specific deck, outside the context of a tournament or metagame archetype.
// REVIEW
const LinkToDeck = ({ children, deck }) => (
	<Link to={getLinkUrl(deck)}>
		{getTagBody(deck, children)}
	</Link>
);

LinkToDeck.propTypes = {
	children: PropTypes.node,
	deck: PropTypes.object.isRequired
};

export default LinkToDeck;

// -----------------------------------------------------------------------------

function getLinkUrl(deck) {
	const deckId = (deck && deck.deckId) ? deck.deckId : "missing";
	return "/decks/" + encodeURIComponent(deckId);
}

function getTagBody(deck, children) {
	if (children) {
		return children;
	}
	else if (deck && deck.archetypeName) {
		return deck.archetypeName;
	} else if (deck && deck.deckName) {
		return deck.deckName;
	} else {
		return "<unknown>";
	}
}