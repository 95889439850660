// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import { apiEndpoints } from "apiEndpoints";
import { logInternalError } from "global/utilities/logging";

// -------------------------------------------------------------------------------------------------
// Set of helper functions to build REST API URLs
// REVIEW

// -------------------------------------------------------------------------------------------------
// FUTURE: Add support for preferred formats, price source
export function constructGetMetagameSummaryUrl(formatIds) {
	if (formatIds) {
		const filter = encodeURIComponent(formatIds.join(";"));
		return `${getApiEndpoints().metagames}/summary?formats=${filter}`;
	} else {
		return `${getApiEndpoints().metagames}/summary`;
	}
}

// -------------------------------------------------------------------------------------------------
export function constructGetMetagameFormatsUrl() {
	return `${getApiEndpoints().metagames}/formats`;
}

// -------------------------------------------------------------------------------------------------
export function constructGetMetagameFormatPeriodUrl(formatId, formatPeriodId) {
	if (formatPeriodId) {
		return `${getApiEndpoints().metagames}/formats/${encodeURIComponent(formatId)}?formatPeriod=${formatPeriodId}`;
	} else {
		return `${getApiEndpoints().metagames}/formats/${encodeURIComponent(formatId)}`;
	}
}

// -------------------------------------------------------------------------------------------------
export function constructGetMetagameFormatPeriodCardsUrl(formatId, formatPeriodId) {
	if (formatPeriodId) {
		return `${getApiEndpoints().metagames}/formats/${encodeURIComponent(formatId)}/cards?formatPeriod=${formatPeriodId}`;
	} else {
		return `${getApiEndpoints().metagames}/formats/${encodeURIComponent(formatId)}/cards`;
	}
}

// -------------------------------------------------------------------------------------------------
export function constructGetMetagameArchetypeUrl(archetypeId) {
	return `${getApiEndpoints().metagames}/archetypes/` +
		`${encodeURIComponent(archetypeId)}`;
}

// -------------------------------------------------------------------------------------------------
// FUTURE: Add parameter for price data source
export function constructGetMetagameArchetypeVariationUrl(formatId, formatPeriodId, archetypeId, archetypeVariationId) {
	return `${getApiEndpoints().metagame}/metagame/` +
		`formats/${encodeURIComponent(formatId)}/` +
		`${encodeURIComponent(formatPeriodId)}/` +
		`archetypes/${encodeURIComponent(archetypeId)}/` +
		`variations/${encodeURIComponent(archetypeVariationId)}`;
}

// -------------------------------------------------------------------------------------------------
export function constructGetTopNewCardsUrl(maxItems) {
	if (maxItems) {
		return `${getApiEndpoints().metagames}/top/cards?maxItems=${maxItems}`;
	} else {
		return `${getApiEndpoints().metagames}/top/cards`;
	}
}

// -------------------------------------------------------------------------------------------------
export function constructGetTournamentsUrl(filterParameters) {
	return `${getApiEndpoints().decks}/tournaments${constructFilterParameters(filterParameters)}`;
}

// -------------------------------------------------------------------------------------------------
// FUTURE: Add parameter for price data source
export function constructGetTournamentUrl(tournamentId) {
	return `${getApiEndpoints().decks}/tournaments/${encodeURIComponent(tournamentId)}`;
}

// -------------------------------------------------------------------------------------------------
export function constructGetDeckUrl(deckId) {
	return `${getApiEndpoints().decks}/decks/${encodeURIComponent(deckId)}`;
}

// -------------------------------------------------------------------------------------------------
export function constructGetDecksUrl(filterParameters) {
	return `${getApiEndpoints().decks}/decks${constructFilterParameters(filterParameters)}`;
}

// -------------------------------------------------------------------------------------------------
export function constructGetCardSetsUrl() {
	return `${getApiEndpoints().cards}/cardSets`;
}

// -------------------------------------------------------------------------------------------------
export function constructGetCardsUrl(cardSetId) {
	return `${getApiEndpoints().cards}/cards?cardSet=${cardSetId}`;
}

// -------------------------------------------------------------------------------------------------
export function constructGetCardUrl(cardId, cardVariation) {
	if (cardVariation) {
		return `${getApiEndpoints().cards}/cards/${cardId}/${cardVariation}`;
	} else {
		return `${getApiEndpoints().cards}/cards/${cardId}`;
	}
}

// -------------------------------------------------------------------------------------------------
function getApiEndpoints() {
	const stage = window.$deploymentStage;

	if (stage === "localhost") {
		return apiEndpoints.dev;
	} else if (stage === "dev") {
		return apiEndpoints.dev;
	} else if (stage === "prod") {
		return apiEndpoints.prod;
	} else {
		logInternalError(`Unexpected environment variable: ${stage}`);
		return apiEndpoints.dev;
	}
}

// -----------------------------------------------------------------------------
function constructFilterParameters(filterParameters) {
	if (!filterParameters || Object.keys(filterParameters).length === 0) {
		return "";
	}

	let output = "";

	for (const key of Object.keys(filterParameters)) {
		if (!output) {
			output = "?";
		} else {
			output += "&";
		}

		const value = filterParameters[key];

		if (value) {
			output += `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
		} else {
			output += `${encodeURIComponent(key)}`;
		}
	}

	return output;
}