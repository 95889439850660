// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { PropTypes } from "prop-types";
import { Popover, PopoverBody } from "reactstrap";

import CardImage from "./CardImage";

// -----------------------------------------------------------------------------
// REVIEW
export default class CardImagePopover extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			popoverOpen: false
		};
	}

	toggle() {
		this.setState({
			popoverOpen: !this.state.popoverOpen
		});
	}

	render() {
		const _deckLocation = this.props.deckLocation ? this.props.deckLocation : "none";
		const id = "po-" + _deckLocation + "-" + this.props.cardId;

		return (
			<span>
				<span style={{ cursor: "pointer" }} className="far fa-image" id={id} onClick={this.toggle} />
				<Popover placement="right" isOpen={this.state.popoverOpen} target={id} toggle={this.toggle}>
					<PopoverBody><CardImage cardId={this.props.cardId} /></PopoverBody>
				</Popover>
			</span>);
	}
}

CardImagePopover.propTypes = {
	cardId: PropTypes.number.isRequired,
	deckLocation: PropTypes.string.isRequired
};