// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React, { Suspense, useState } from "react";
import { PropTypes } from "prop-types";
import { useLocation } from "react-router-dom";
import { Link as RoutingNavLink } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Nav, NavItem, NavLink } from "reactstrap";
import { Button } from "reactstrap";
import { Helmet } from "react-helmet";

import PageErrorBoundary from "global/errors/PageErrorBoundary";
import ErrorLoadingSection from "global/errors/ErrorLoadingSection";
import LoadingSpinner from "global/loading/LoadingSpinner";
import LinkToHomePage from "common/links/LinkToHomePage";
import DecksTable from "./DecksTable";

import { constructGetDecksUrl } from "api";
import { fetchResource } from "global/loading/fetchResource";
import { parseUrlSearchParameters } from "global/utilities/parseUrlSearchParameters";
import { getFormatDisplayName } from "common/resources/formatNames";
import { getCurrentDateIso8601 } from "global/utilities/Date";

// -------------------------------------------------------------------------------------------------
// Page that shows filterable list of linked deck summaries.
// IDEAS: https://infinite.tcgplayer.com/article/Naya-Runes-Won-t-Be-the-Top-Deck-in-Standard-for-Much-Longer/a2f24989-b49a-4372-9c8a-9ce224ded041?src=wizards
// REVIEW
export default function DecksPage() {
	const location = useLocation();
	const urlFilterParameters = parseUrlSearchParameters(location.search);

	return (
		<PageErrorBoundary>
			<DecksPageContents urlFilterParameters={urlFilterParameters} />
		</PageErrorBoundary>
	);
}

// -------------------------------------------------------------------------------------------------
export function DecksPageContents({ urlFilterParameters, displayOptions }) {
	const apiFilterParameters = getApiFilterParameters(urlFilterParameters);
	const getDecksUrl = constructGetDecksUrl(apiFilterParameters);
	const decksResource = fetchResource(getDecksUrl);
	const uiFilterParameters = getUIFilterParameters(urlFilterParameters);

	return (
		<Container>
			<Row>
				<Col>
					<Breadcrumbs />
				</Col>
			</Row>

			<Row>
				<Col>
					<FilterBubbles uiFilterParameters={uiFilterParameters} />
				</Col>
			</Row>

			<Suspense fallback={<LoadingSpinner />}>
				<Row>
					<Col>
						{/* FUTURE: Pass callback for adding filter or pass existing link so we can construct filter links */}
						<DecksTableLoader
							decksResource={decksResource}
							displayOptions={displayOptions}
							url={getDecksUrl} />
					</Col>
				</Row>
			</Suspense>
		</Container>
	);
}

DecksPageContents.propTypes = {
	urlFilterParameters: PropTypes.object,
	displayOptions: PropTypes.object
};

// -------------------------------------------------------------------------------------------------
function DecksTableLoader({ decksResource, displayOptions, url }) {
	const [decks, setDecks] = useState();
	const [decksState, setDecksState] = useState();
	const [isLoadingMoreData, setIsLoadingMoreData] = useState();
	const [currentUrl, setCurrentUrl] = useState();

	if (currentUrl !== url) {
		setDecks(decksResource.data);
		setDecksState(decksResource.state);
		setCurrentUrl(url);
	}

	if (!currentUrl) {
		return null;
	}

	const loadMoreData = async () => {
		setIsLoadingMoreData(true);
		const moreDecksResource = await decksState.loadMoreData();
		setIsLoadingMoreData(false);

		if (url === currentUrl) {
			setDecks(decks.concat(moreDecksResource.data));
			setDecksState(moreDecksResource.state);
		}
	};

	return (
		<>
			{decks &&
				<DecksTable decks={decks} displayOptions={displayOptions} />}
			{decksState && decksState.hasMoreData && !isLoadingMoreData &&
				<div className="text-center">
					<Button style={{ cursor: "pointer" }} color="link" onClick={loadMoreData}>
						show more...
					</Button>
				</div>
			}
			{isLoadingMoreData && <LoadingSpinner />}
			{decksState && decksState.isError &&
				<ErrorLoadingSection errorMessage={decksState.errorMessage} />
			}
		</>
	);
}

DecksTableLoader.propTypes = {
	url: PropTypes.string,
	decksResource: PropTypes.object,
	displayOptions: PropTypes.object
};

// -----------------------------------------------------------------------------
// FUTURE: Calculate link so it can keep other filters rather than clearing all
function FilterBubbles({ uiFilterParameters }) {
	return (uiFilterParameters && uiFilterParameters.length > 0 &&
		<Nav pills className="m-2">
			{uiFilterParameters.map((uiFilterParameter, index) =>
				<NavItem key={index}>
					<NavLink to="/decks" tag={RoutingNavLink} active>
						<FilterBubble uiFilterParameter={uiFilterParameter} />
					</NavLink>
				</NavItem>)
			}
		</Nav>
	);
}

const uiFilterParameterSchema = {
	displayName: PropTypes.string.isRequired,
	displayValue: PropTypes.string,
	internalValue: PropTypes.string
};

FilterBubbles.propTypes = {
	uiFilterParameters: PropTypes.arrayOf(PropTypes.shape(uiFilterParameterSchema))
};

// -----------------------------------------------------------------------------
function FilterBubble({ uiFilterParameter }) {
	return (
		<>
			{uiFilterParameter.displayName}: {uiFilterParameter.displayValue ? uiFilterParameter.displayValue : "<unknown>"}
			{" "}
			<span className="fas fa-times-circle" />
		</>
	);
}

FilterBubble.propTypes = {
	uiFilterParameter: PropTypes.shape(uiFilterParameterSchema)
};

// -------------------------------------------------------------------------------------------------
function getApiFilterParameters(urlFilterParameters) {
	let apiFilterParameters = {
		maxItems: 25
	};

	if (urlFilterParameters && urlFilterParameters.formats) {
		apiFilterParameters.formatId = urlFilterParameters.formats;
	}

	return apiFilterParameters;
}

// -------------------------------------------------------------------------------------------------
function getUIFilterParameters(urlFilterParameters) {
	let filterParameters = [];

	if (urlFilterParameters && urlFilterParameters.formats) {
		// FUTURE: Handle collection of formats
		filterParameters.push({
			displayName: "Format",
			displayValue: getFormatDisplayName(urlFilterParameters.formats),
			internalValue: urlFilterParameters.formats
		});
	}

	return filterParameters;
}

// -------------------------------------------------------------------------------------------------
function Breadcrumbs() {
	const rootDomain = `${window.location.protocol}//${window.location.hostname}`;
	const title = "Decks | Magic the Gathering | MTG Top Decks";
	const shortTitle = "Decks";
	const description = "List of recent Magic the Gathering tournament decks";
	const keywords = `Magic the Gathering, cards, archetypes, metagame, decks, tournaments`;
	const date = getCurrentDateIso8601();
	const breadcrumbsLdJson = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement:": [{
			"@type": "ListItem",
			"position": 1,
			"name": "Home",
			"item": rootDomain
		},
		{
			"@type": "ListItem",
			"position": 2,
			"name": "Decks"
		}]
	};

	const pageLdJson = {
		"@context": "https://schema.org",
		"@type": "Article",
		"description": description,
		"keywords": keywords,
		"dateCreated": date,
		"dateModified": date,
		"headline": title,
		"image": [
			rootDomain + "/assets/logo.png"
		],
		"author": [{
			"@type": "Organization",
			"name": "MTG Top Decks",
			"url": rootDomain
		}]
	};

	return (
		<>
			<Helmet>
				<title>{title}</title>
				<meta name="description" content={description} />
				<meta property="og:title" content={shortTitle} />
				<meta property="og:description" content={description} />
				<meta property="og:type" content="article" />
				<meta property="og:url" content={window.location.href} />
				<meta property="og:modified_time" content={date} />
				<meta property="og:site_name" content="MTG Top Decks" />
				<script type="application/ld+json">{JSON.stringify(breadcrumbsLdJson)}</script>
				<script type="application/ld+json">{JSON.stringify(pageLdJson)}</script>
			</Helmet>

			<Breadcrumb>
				<BreadcrumbItem><LinkToHomePage>Home</LinkToHomePage></BreadcrumbItem>
				<BreadcrumbItem active>Decks</BreadcrumbItem>
			</Breadcrumb>
		</>
	);
}