// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { PropTypes } from "prop-types";

import CardElementImage from "./CardElementImage";
import { logInternalError } from "global/utilities/logging";

// -----------------------------------------------------------------------------
// A color identity for a deck.
// Ex: {W}{B}{R}/{G}
// REVIEW
const ColorIdentity = ({ identity }) => (
	<span>
		{identity ? splitByPriority(identity).map(getManaSymbols) : ""}
	</span>
);

ColorIdentity.propTypes = {
	identity: PropTypes.string.isRequired
};

export default ColorIdentity;

// -----------------------------------------------------------------------------
function splitByPriority(identity) {
	return identity.split("/");
}

// -----------------------------------------------------------------------------
function getManaSymbols(identity, index) {
	if (index === 0) {
		return splitManaSymbols(identity).map(getNormalManaSymbol);
	} else {
		return splitManaSymbols(identity).map(getSmallManaSymbol);
	}
}

// -----------------------------------------------------------------------------
function splitManaSymbols(manaCost) {
	let result = [];
	let startIndex = 0;

	if (manaCost) {
		for (let currentIndex = 0; currentIndex < manaCost.length; ++currentIndex) {
			if (manaCost[currentIndex] === "}") {
				result.push(manaCost.substr(startIndex, currentIndex - startIndex + 1));
				startIndex = currentIndex + 1;
			}
		}

		if (startIndex !== manaCost.length) {
			logInternalError(`Invalid mana cost: ${manaCost}`);
		}
	}

	return result;
}

// -----------------------------------------------------------------------------
function getNormalManaSymbol(manaSymbol) {
	return <CardElementImage key={manaSymbol} symbol={manaSymbol} size="Medium" style={{ maxHeight: 18 }} />;
}

// -----------------------------------------------------------------------------
function getSmallManaSymbol(manaSymbol) {
	return <CardElementImage key={manaSymbol} symbol={manaSymbol} size="Medium" style={{ maxHeight: 13 }} />;
}