// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import { UncontrolledTooltip } from "reactstrap";

import CardImage from "common/CardImage";

// -----------------------------------------------------------------------------
// Hyperlink to specific Magic card.
// REVIEW
const LinkToCard = ({ cardId, cardVariation, multiface, deckLocation, children }) => (
	<>
		<Link to={getLinkUrl(cardId, cardVariation)} id={getTooltipId(cardId, cardVariation, deckLocation)}>
			{children}
		</Link>

		{/* TODO: Move style to custom.css */}
		{/* TODO: Fix tooltip so it's not slightly transparent */}
		<UncontrolledTooltip
			style={{ height: "364px", maxWidth: "520px", padding: 12, opacity: 1, backgroundColor: "#FFF", border: "1px solid rgba(0, 0, 0, 0.2)" }}
			delay={{ show: 300, hide: 0 }}
			placement="right"
			target={getTooltipId(cardId, cardVariation, deckLocation)}>

			<CardImage cardId={cardId ? cardId : 0} cardVariation={cardVariation} multiface={multiface} />
		</UncontrolledTooltip>
	</>
);

LinkToCard.propTypes = {
	children: PropTypes.node,
	cardId: PropTypes.number.isRequired,
	cardVariation: PropTypes.string,
	multiface: PropTypes.number,
	deckLocation: PropTypes.string
};

export default LinkToCard;

// -----------------------------------------------------------------------------
function getTooltipId(cardId, cardVariation, deckLocation) {
	const _deckLocation = deckLocation ? deckLocation : "none";
	const _cardId = cardId ? cardId : "missing";
	const _cardVariation = cardVariation ? cardVariation : "a";

	return `cardimage-${_deckLocation}-${_cardId}-${_cardVariation}`;
}

function getLinkUrl(cardId, cardVariation) {
	const _cardId = cardId ? encodeURIComponent(cardId) : "missing";
	const _cardVariation = cardVariation ? encodeURIComponent(cardVariation) : "a";

	return `/cards/${_cardId}/${_cardVariation}`;
}