// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Container } from "reactstrap";
import { Table } from "reactstrap";
import { Badge } from "react-bootstrap";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Helmet } from "react-helmet";

import { constructGetTopNewCardsUrl } from "api";
import { metagameMultiFormatCardStats } from "apiSchemas";

import PageErrorBoundary from "global/errors/PageErrorBoundary";
import FetchSection from "global/loading/FetchSection";

//import LinkToMetagameFormats from "common/links/LinkToMetagameFormats";
//import LinkToMetagameFormatPeriod from "common/links/LinkToMetagameFormatPeriod";
import LinkToHomePage from "common/links/LinkToHomePage";
import { getFormatDisplayName } from "common/resources/formatNames";
import { formatInteger, formatPrice } from "global/utilities/format";
import LinkToCard from "common/links/LinkToCard";
//import TableCellItem from "common/TableCellItem";
//import ColorIdentities from "common/ColorIdentities";
//import ColorIdentity from "common/ColorIdentity";
import CardRarityImagePopover from "common/CardRarityImagePopover";
import ManaCost from "common/ManaCost";
import { getCurrentDateIso8601 } from "global/utilities/Date";

// -----------------------------------------------------------------------------
export default function MetagameTopCardsPage() {
	return (
		<PageErrorBoundary>
			<Container>
				<>
					<Row>
						<Col>
							<Breadcrumbs />
						</Col>
					</Row>

					<Row>
						<Col>
							<h2 className="mt-2">
								Top New Cards
							</h2>
						</Col>
					</Row>

					<FetchSection requestUrl={constructGetTopNewCardsUrl(100)}>
						{data =>
							<Row>
								<Col>
									{data && <MetagameTopCardsTable cards={data} />}
								</Col>
							</Row>
						}
					</FetchSection>
				</>
			</Container>
		</PageErrorBoundary>
	);
}

// -----------------------------------------------------------------------------
export const MetagameTopCardsTable = ({ cards }) => (
	<Table striped size="sm">
		<thead>
			<tr>
				<th>Card</th>
				<th className="text-center">Number of Copies</th>
				<th className="text-end d-none d-md-table-cell">Cost</th>
			</tr>
		</thead>

		<tbody>
			{(cards && cards.length > 0) ?
				cards.map(card =>
					<MetagameTopCardsRow
						key={card.cardId}
						card={card} />) :

				// No cards found
				<tr className="text-muted text-center">
					<td colSpan={3}>
						No cards
					</td>
				</tr>
			}
		</tbody>
	</Table>
);

MetagameTopCardsTable.propTypes = {
	cards: PropTypes.arrayOf(PropTypes.shape(metagameMultiFormatCardStats))
};

// -----------------------------------------------------------------------------
function MetagameTopCardsRow({ card }) {
	return (
		<tr>
			<td>
				<CardRarityImagePopover cardId={card.cardId} multiface={card.multiface} cardVariation={card.cardVariation} rarity={card.cardRarity} size="Small" style={{ maxHeight: 16, maxWidth: 24 }} deckLocation="cards" />
				{" "}
				<LinkToCard cardId={card.cardId} multiface={card.multiface}>
					{card.cardName}
				</LinkToCard>
				{" "}
				<ManaCost cost={card.cardManaCost} />
			</td>

			<td className="text-center">
				{card.numberOfDecks && Object.keys(card.numberOfCopies).map(key =>
					<Badge className="ms-1" bg="info" text="light" key={key}>{getFormatDisplayName(key)}{": "}{formatInteger(card.numberOfCopies[key])}</Badge>
				)}
			</td>

			<td className="text-end d-none d-md-table-cell" style={{ maxWidth: "150px" }}>
				{formatPrice(card.price)}
			</td>
		</tr>
	);
}

MetagameTopCardsRow.propTypes = {
	card: PropTypes.shape(metagameMultiFormatCardStats)
};

// -------------------------------------------------------------------------------------------------
function Breadcrumbs() {
	const rootDomain = `${window.location.protocol}//${window.location.hostname}`;
	const title = "Top New Cards | Magic the Gathering | MTG Top Decks";
	const shortTitle = "Top New Cards";
	const description = "List of most played cards in top decks from current Magic the Gathering set";
	const keywords = `Magic the Gathering, cards, metagame, decks, tournaments`;
	const date = getCurrentDateIso8601();
	const breadcrumbsLdJson = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement:": [{
			"@type": "ListItem",
			"position": 1,
			"name": "Home",
			"item": rootDomain
		},
		{
			"@type": "ListItem",
			"position": 2,
			"name": "Top New Cards"
		}]
	};

	const pageLdJson = {
		"@context": "https://schema.org",
		"@type": "Article",
		"description": description,
		"keywords": keywords,
		"dateCreated": date,
		"dateModified": date,
		"headline": title,
		"image": [
			rootDomain + "/assets/logo.png"
		],
		"author": [{
			"@type": "Organization",
			"name": "MTG Top Decks",
			"url": rootDomain
		}]
	};

	return (
		<>
			<Helmet>
				<title>{title}</title>
				<meta name="description" content={description} />
				<meta property="og:title" content={shortTitle} />
				<meta property="og:description" content={description} />
				<meta property="og:type" content="article" />
				<meta property="og:url" content={window.location.href} />
				<meta property="og:modified_time" content={date} />
				<meta property="og:site_name" content="MTG Top Decks" />
				<script type="application/ld+json">{JSON.stringify(breadcrumbsLdJson)}</script>
				<script type="application/ld+json">{JSON.stringify(pageLdJson)}</script>
			</Helmet>
			<Breadcrumb>
				<BreadcrumbItem><LinkToHomePage>Home</LinkToHomePage></BreadcrumbItem>
				<BreadcrumbItem active>Top New Cards</BreadcrumbItem>
			</Breadcrumb>
		</>
	);
}

Breadcrumbs.propTypes = {
	formatId: PropTypes.string
};