// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React, { Suspense, useState } from "react";
import { PropTypes } from "prop-types";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
//import { Link as RoutingNavLink } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
//import { Nav, NavItem, NavLink } from "reactstrap";
//import { Button } from "reactstrap";
import { Helmet } from "react-helmet";

import PageErrorBoundary from "global/errors/PageErrorBoundary";
import ErrorLoadingSection from "global/errors/ErrorLoadingSection";
import LoadingSpinner from "global/loading/LoadingSpinner";
import LinkToHomePage from "common/links/LinkToHomePage";
import LinkToMetagameFormatPeriod from "common/links/LinkToMetagameFormatPeriod";
import MetagameArchetypePanel from "./MetagameArchetypePanel";

import { constructGetMetagameArchetypeUrl } from "api";
import { fetchResource } from "global/loading/fetchResource";
import { parseUrlSearchParameters } from "global/utilities/parseUrlSearchParameters";
import { getFormatDisplayName } from "common/resources/formatNames";
import { getCurrentDateIso8601 } from "global/utilities/Date";

// -------------------------------------------------------------------------------------------------
// Page that shows list of archetypes
// CREATING
export default function MetagameArchetypePage() {
	const { archetypeId } = useParams();
	const location = useLocation();
	const urlFilterParameters = parseUrlSearchParameters(location.search);

	return (
		<PageErrorBoundary>
			<MetagameArchetypePageContents archetypeId={archetypeId} urlFilterParameters={urlFilterParameters} />
		</PageErrorBoundary>
	);
}

// -------------------------------------------------------------------------------------------------
export function MetagameArchetypePageContents({ archetypeId, displayOptions }) {
	const getMetagameArchetypeUrl = constructGetMetagameArchetypeUrl(archetypeId);
	const metagameArchetypeResource = fetchResource(getMetagameArchetypeUrl);

	return (
		<Container>
			<Suspense fallback={<LoadingSpinner />}>
				<Row>
					<Col>
						<Breadcrumbs
							metagameArchetypeResource={metagameArchetypeResource}
							url={getMetagameArchetypeUrl} />
					</Col>
				</Row>

				<Row>
					<Col>
						<MetagameArchetypePanelLoader
							metagameArchetypeResource={metagameArchetypeResource}
							displayOptions={displayOptions}
							url={getMetagameArchetypeUrl} />
					</Col>
				</Row>
			</Suspense>
		</Container>
	);
}

MetagameArchetypePageContents.propTypes = {
	archetypeId: PropTypes.string,
	urlFilterParameters: PropTypes.object,
	displayOptions: PropTypes.object
};

// -------------------------------------------------------------------------------------------------
function MetagameArchetypePanelLoader({ metagameArchetypeResource, displayOptions, url }) {
	const [metagameArchetype, setMetagameArchetype] = useState();
	const [metagameArchetypeState, setMetagameArchetypeState] = useState();
	const [currentUrl, setCurrentUrl] = useState();

	if (currentUrl !== url) {
		setMetagameArchetype(metagameArchetypeResource.data);
		setMetagameArchetypeState(metagameArchetypeResource.state);
		setCurrentUrl(url);
	}

	if (!currentUrl) {
		return null;
	}

	return (
		<>
			{metagameArchetype &&
				<MetagameArchetypePanel archetype={metagameArchetype} displayOptions={displayOptions} />}
			{metagameArchetypeState && metagameArchetypeState.isError &&
				<ErrorLoadingSection errorMessage={metagameArchetypeState.errorMessage} />
			}
		</>
	);
}

MetagameArchetypePanelLoader.propTypes = {
	url: PropTypes.string,
	metagameArchetypeResource: PropTypes.object,
	displayOptions: PropTypes.object
};

// -------------------------------------------------------------------------------------------------
function Breadcrumbs({ metagameArchetypeResource, url }) {
	const [metagameArchetype, setMetagameArchetype] = useState();
	const [, setMetagameArchetypeState] = useState();
	const [currentUrl, setCurrentUrl] = useState();

	if (currentUrl !== url) {
		setMetagameArchetype(metagameArchetypeResource.data);
		setMetagameArchetypeState(metagameArchetypeResource.state);
		setCurrentUrl(url);
	}

	if (!metagameArchetype) {
		return null;
	}

	const rootDomain = `${window.location.protocol}//${window.location.hostname}`;
	const archetypeName = metagameArchetype.archetypeName || "<unknown>";
	const formatName = getFormatDisplayName(metagameArchetype.formatId);
	const title = `${archetypeName} (${formatName}) | Magic the Gathering | MTG Top Decks`;
	const shortTitle = archetypeName;
	const description = `Average and aggregate deck details for ${archetypeName} (${formatName})`;
	const keywords = "Magic the Gathering, cards, archetypes, metagame, decks, tournaments";
	const date = getCurrentDateIso8601();
	const breadcrumbsLdJson = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement:": [{
			"@type": "ListItem",
			"position": 1,
			"name": "Home",
			"item": rootDomain
		},
		{
			"@type": "ListItem",
			"position": 2,
			"name": "Archetypes",
			"item": rootDomain + "/archetypes"
		},
		{
			"@type": "ListItem",
			"position": 3,
			"name": archetypeName
		}]
	};

	const pageLdJson = {
		"@context": "https://schema.org",
		"@type": "Article",
		"description": description,
		"keywords": keywords,
		"dateCreated": date,
		"dateModified": date,
		"headline": title,
		"image": [
			rootDomain + "/assets/logo.png"
		],
		"author": [{
			"@type": "Organization",
			"name": "MTG Top Decks",
			"url": rootDomain
		}]
	};

	return (
		<>
			<Helmet>
				<title>{title}</title>
				<meta name="description" content={description} />
				<meta property="og:title" content={shortTitle} />
				<meta property="og:description" content={description} />
				<meta property="og:type" content="article" />
				<meta property="og:url" content={window.location.href} />
				<meta property="og:modified_time" content={date} />
				<meta property="og:site_name" content="MTG Top Decks" />
				<script type="application/ld+json">{JSON.stringify(breadcrumbsLdJson)}</script>
				<script type="application/ld+json">{JSON.stringify(pageLdJson)}</script>
			</Helmet>

			<Breadcrumb>
				<BreadcrumbItem><LinkToHomePage>Home</LinkToHomePage></BreadcrumbItem>
				<BreadcrumbItem><LinkToMetagameFormatPeriod formatId={metagameArchetype.formatId} formatPeriodId={metagameArchetype.formatPeriodId}>
					{getFormatDisplayName(metagameArchetype.formatId)}
				</LinkToMetagameFormatPeriod></BreadcrumbItem>
				<BreadcrumbItem active>{metagameArchetype.archetypeName ? metagameArchetype.archetypeName : metagameArchetype.archetypeId}</BreadcrumbItem>
			</Breadcrumb>
		</>
	);
}

Breadcrumbs.propTypes = {
	metagameArchetypeResource: PropTypes.object,
	url: PropTypes.string,
};