// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import PropTypes from "prop-types";

// -------------------------------------------------------------------------------------------------
// Data type schema definitions for REST API

// -------------------------------------------------------------------------------------------------
// REVIEW
const cardFrequencySchema = {
	cardId: PropTypes.number.isRequired,
	minimumCount: PropTypes.number,
	maximumCount: PropTypes.number,
	name: PropTypes.string.isRequired,
	frequency: PropTypes.number.isRequired,
	manaCost: PropTypes.string,
	cmc: PropTypes.number,
	rarity: PropTypes.string.isRequired,
	category: PropTypes.string.isRequired,
	multifaceType: PropTypes.number,
	price: PropTypes.number,
};

// -------------------------------------------------------------------------------------------------
const deckCardSchema = {
	cardName: PropTypes.string.isRequired,
	count: PropTypes.number.isRequired,
	cardId: PropTypes.number.isRequired,
	cardVariation: PropTypes.string,
	manaCost: PropTypes.string,
	manaValue: PropTypes.number,
	rarity: PropTypes.string.isRequired,
	isReserveList: PropTypes.bool,
	cardSupertypes: PropTypes.number,
	cardTypes: PropTypes.number,
	additionalCardSupertypes: PropTypes.number,
	additionalCardTypes: PropTypes.number,
	multiface: PropTypes.number,
	price: PropTypes.number,
};

// -------------------------------------------------------------------------------------------------
const aggregateDeckCardSchema = {
	cardName: PropTypes.string.isRequired,
	cardId: PropTypes.number.isRequired,
	cardVariation: PropTypes.string,
	minimumCount: PropTypes.number.isRequired,
	maximumCount: PropTypes.number.isRequired,
	frequency: PropTypes.number.isRequired,
	manaCost: PropTypes.string,
	manaValue: PropTypes.number,
	rarity: PropTypes.string.isRequired,
	isReserveList: PropTypes.bool,
	cardSupertypes: PropTypes.number,
	cardTypes: PropTypes.number,
	multiface: PropTypes.number,
	price: PropTypes.number,
};

// -------------------------------------------------------------------------------------------------
const deckContentSchema = {
	commanders: PropTypes.arrayOf(PropTypes.shape(deckCardSchema)),
	companions: PropTypes.arrayOf(PropTypes.shape(deckCardSchema)),
	mainboard: PropTypes.arrayOf(PropTypes.shape(deckCardSchema)),
	sideboard: PropTypes.arrayOf(PropTypes.shape(deckCardSchema)),
	maybeboard: PropTypes.arrayOf(PropTypes.shape(deckCardSchema))
};

// -------------------------------------------------------------------------------------------------
const aggregateDeckContentSchema = {
	commanders: PropTypes.arrayOf(PropTypes.shape(aggregateDeckCardSchema)),
	companions: PropTypes.arrayOf(PropTypes.shape(aggregateDeckCardSchema)),
	mainboard: PropTypes.arrayOf(PropTypes.shape(aggregateDeckCardSchema)),
	sideboard: PropTypes.arrayOf(PropTypes.shape(aggregateDeckCardSchema)),
	maybeboard: PropTypes.arrayOf(PropTypes.shape(aggregateDeckCardSchema))
};

// -------------------------------------------------------------------------------------------------
const deckSchema = {
	deckId: PropTypes.string,
	tournamentId: PropTypes.string,
	tournamentName: PropTypes.string,
	tournamentOrganizerName: PropTypes.string,
	tournamentLevel: PropTypes.number,
	tournamentPlatform: PropTypes.number,
	playerName: PropTypes.string,
	teamName: PropTypes.string,
	date: PropTypes.string.isRequired,
	isSingleGameMatch: PropTypes.bool,
	formatId: PropTypes.string.isRequired,
	formatPeriodId: PropTypes.string.isRequired,
	formatPeriodName: PropTypes.string,
	matchRecord: PropTypes.string,
	gameRecord: PropTypes.string,
	overallMatchRecord: PropTypes.string,
	finish: PropTypes.string,
	archetypeId: PropTypes.string,
	archetypeName: PropTypes.string,
	archetypeVariationId: PropTypes.string,
	archetypeVariationName: PropTypes.string,
	overlapVersusArchetypeAverage: PropTypes.number,
	price: PropTypes.number,
	colorIdentity: PropTypes.string,
	colorQuantityIds: PropTypes.arrayOf(PropTypes.number.isRequired),
	sourceSiteUrl: PropTypes.string,
	sourceSiteName: PropTypes.string,
	cards: PropTypes.shape(deckContentSchema)
};

// -------------------------------------------------------------------------------------------------
// REVIEW
const aggregateDeckSchema = {
	formatId: PropTypes.string.isRequired,
	formatPeriodId: PropTypes.string.isRequired,
	formatPeriodName: PropTypes.string,
	archetypeId: PropTypes.string,
	archetypeName: PropTypes.string,
	archetypeVariationId: PropTypes.string,
	archetypeVariationName: PropTypes.string,
	price: PropTypes.number,
	colorIdentity: PropTypes.string,
	colorQuantityIds: PropTypes.arrayOf(PropTypes.string.isRequired),
	cards: PropTypes.shape(aggregateDeckContentSchema)
};

// -------------------------------------------------------------------------------------------------
// REVIEW
const metagameArchetypeVariationSchema = {
	archetypeVariationId: PropTypes.string.isRequired,
	archetypeVariationName: PropTypes.string.isRequired,
	cardSetName: PropTypes.string,
	colorIdentity: PropTypes.string,
	numberOfDecks: PropTypes.number,
	isNew: PropTypes.bool,
	decks: PropTypes.arrayOf(PropTypes.shape(deckSchema)),
	keyCards: PropTypes.arrayOf(PropTypes.shape(cardFrequencySchema)),
	uniqueCards: PropTypes.arrayOf(PropTypes.shape(cardFrequencySchema)),
	representativeDeck: PropTypes.shape(deckSchema),
	averageDeck: PropTypes.shape(deckSchema),
	aggregateDeck: PropTypes.shape(aggregateDeckSchema)
};

// -------------------------------------------------------------------------------------------------
// REVIEW
const metagameArchetypeSchema = {
	archetypeId: PropTypes.string.isRequired,
	archetypeName: PropTypes.string.isRequired,
	cardSetName: PropTypes.string,
	popularity: PropTypes.number,
	popularityDirection: PropTypes.number,
	lowestPrice: PropTypes.number,
	highestPrice: PropTypes.number,
	numberOfDecks: PropTypes.number,
	colorIdentities: PropTypes.arrayOf(PropTypes.string),
	variations: PropTypes.arrayOf(PropTypes.shape(metagameArchetypeVariationSchema)),
	keyCards: PropTypes.arrayOf(PropTypes.shape(cardFrequencySchema))
};

// -------------------------------------------------------------------------------------------------
// REVIEW
const metagameFormatPeriodSchema = {
	formatId: PropTypes.string,
	formatPeriodId: PropTypes.string,
	formatPeriodName: PropTypes.string,
	nextFormatPeriodId: PropTypes.string,
	nextFormatPeriodName: PropTypes.string,
	previousFormatPeriodId: PropTypes.string,
	previousFormatPeriodName: PropTypes.string,
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	archetypes: PropTypes.arrayOf(PropTypes.shape(metagameArchetypeSchema)),
	numberOfDecks: PropTypes.number,
};

// -------------------------------------------------------------------------------------------------

const metagameCardStats = {
	cardId: PropTypes.number,
	cardVariation: PropTypes.string,
	cardName: PropTypes.string,
	cardManaCost: PropTypes.string,
	cardRarity: PropTypes.string,
	cardMultiface: PropTypes.number,
	numberOfCopies: PropTypes.number,
	numberOfDecks: PropTypes.number,
	numberOfArchetypes: PropTypes.number,
	numberOfArchetypeVariations: PropTypes.number,
	price: PropTypes.number,
};

const metagameMultiFormatCardStats = {
	cardId: PropTypes.number,
	cardVariation: PropTypes.string,
	cardName: PropTypes.string,
	cardManaCost: PropTypes.string,
	cardRarity: PropTypes.string,
	numberOfCopies: PropTypes.object,
	numberOfDecks: PropTypes.object,
	numberOfArchetypes: PropTypes.object,
	numberOfArchetypeVariations: PropTypes.object,
	price: PropTypes.number,
};

const metagameFormatPeriodStatsBySet = {
	key: PropTypes.shape({
		setId: PropTypes.number,
		setDisplayName: PropTypes.string,
		setReleaseDate: PropTypes.string
	}),
	values: PropTypes.arrayOf(PropTypes.shape(metagameCardStats))
};

const metagameFormatPeriodStatsByCardType = {
	key: PropTypes.shape({ cardType: PropTypes.string }),
	values: PropTypes.arrayOf(PropTypes.shape(metagameCardStats))
};

const metagameFormatPeriodStatsByRarity = {
	key: PropTypes.shape({ rarity: PropTypes.string }),
	values: PropTypes.arrayOf(PropTypes.shape(metagameCardStats))
};

const metagameFormatPeriodStatsByColor = {
	key: PropTypes.shape({ color: PropTypes.string }),
	values: PropTypes.arrayOf(PropTypes.shape(metagameCardStats))
};

const metagameFormatPeriodCardsSchema = {
	formatId: PropTypes.string,
	formatPeriodId: PropTypes.string,
	formatPeriodName: PropTypes.string,
	nextFormatPeriodId: PropTypes.string,
	nextFormatPeriodName: PropTypes.string,
	previousFormatPeriodId: PropTypes.string,
	previousFormatPeriodName: PropTypes.string,
	cardsByFirstSet: PropTypes.arrayOf(PropTypes.shape(metagameFormatPeriodStatsBySet)),
	cardsByLastSet: PropTypes.arrayOf(PropTypes.shape(metagameFormatPeriodStatsBySet)),
	cardsByColor: PropTypes.arrayOf(PropTypes.shape(metagameFormatPeriodStatsByColor)),
	cardsByCardType: PropTypes.arrayOf(PropTypes.shape(metagameFormatPeriodStatsByCardType)),
	cardsByRarity: PropTypes.arrayOf(PropTypes.shape(metagameFormatPeriodStatsByRarity)),
};

// -------------------------------------------------------------------------------------------------
// REVIEW
//const metagameFormatPeriodSchemaOld = {
//	formatPeriodId: PropTypes.string.isRequired,
//	formatPeriodName: PropTypes.string.isRequired,
//	startDate: PropTypes.string.isRequired,
//	endDate: PropTypes.string,
//	numberOfLegalSets: PropTypes.number,
//	numberOfLegalCards: PropTypes.number,
//	numberOfDecks: PropTypes.number,
//	archetypes: PropTypes.arrayOf(PropTypes.shape(metagameArchetypeSchema)),
//};

// -------------------------------------------------------------------------------------------------
// REVIEW
//const metagameFormatSchemaOld = {
//	formatId: PropTypes.string.isRequired,
//	formatName: PropTypes.string.isRequired,
//	description: PropTypes.string.isRequired,
//	rulesUrl: PropTypes.string,
//	isRetired: PropTypes.bool,
//	formatPeriods: PropTypes.arrayOf(PropTypes.shape(metagameFormatPeriodSchema))
//};

// -------------------------------------------------------------------------------------------------
const tournamentOverallSchema = {
	tournamentId: PropTypes.string.isRequired,
	tournamentName: PropTypes.string.isRequired,
	date: PropTypes.string,
	formatIds: PropTypes.arrayOf(PropTypes.string).isRequired,
	isMultiformatTournament: PropTypes.bool,
	isTeam: PropTypes.bool,
	isSampleOfArchetypes: PropTypes.bool,
	level: PropTypes.number,
	organizerName: PropTypes.string,
	platform: PropTypes.number,
	numberOfPlayers: PropTypes.number,
	isApproximateNumberOfPlayers: PropTypes.bool,
	numberOfRounds: PropTypes.number,
	sourceSiteUrl: PropTypes.string,
	sourceSiteName: PropTypes.string
};

// -------------------------------------------------------------------------------------------------
const tournamentMatchResultSchema = {
	table: PropTypes.string,
	firstPlayerName: PropTypes.string.isRequired,
	firstPlayerId: PropTypes.string.isRequired,
	secondPlayerName: PropTypes.string,
	secondPlayerId: PropTypes.string,
	gameRecord: PropTypes.string,
	isBye: PropTypes.bool,
	isDraw: PropTypes.bool,
	firstPlayerWon: PropTypes.bool,
	secondPlayerWon: PropTypes.bool
};

// -------------------------------------------------------------------------------------------------
const tournamentRoundResultSchema = {
	round: PropTypes.string.isRequired,
	matchResults: PropTypes.arrayOf(PropTypes.shape(tournamentMatchResultSchema)).isRequired
};

// -------------------------------------------------------------------------------------------------
const tournamentSchema = {
	overall: PropTypes.shape(tournamentOverallSchema).isRequired,
	decks: PropTypes.arrayOf(PropTypes.shape(deckSchema)),
	roundResults: PropTypes.arrayOf(PropTypes.shape(tournamentRoundResultSchema))
};

// -------------------------------------------------------------------------------------------------
const cardSetSchema = {
	cardSetName: PropTypes.string.isRequired,
	cardSetId: PropTypes.number.isRequired,
	parentCardSetId: PropTypes.number,
	cardSetCode: PropTypes.string,
	parentCardSetCode: PropTypes.string,
	releaseDate: PropTypes.string.isRequired,
	digitalReleaseDate: PropTypes.string,
	cardSetCategory: PropTypes.string.isRequired,
	numberOfCards: PropTypes.number,
	legalInFormats: PropTypes.number,
	isSignificantSet: PropTypes.bool
};

// -------------------------------------------------------------------------------------------------
const cardSchema = {
	cardName: PropTypes.string.isRequired,
	cardId: PropTypes.number.isRequired,
	cardVariation: PropTypes.string,
	cardVariationName: PropTypes.string,
	isTokenCard: PropTypes.bool,
	rarity: PropTypes.string,
	collectorNumber: PropTypes.string,
	cardSetName: PropTypes.string,
	multiface: PropTypes.number,
	isReserveList: PropTypes.bool,
	typeLineText: PropTypes.string,
	manaCost: PropTypes.string,
	manaValue: PropTypes.number
};

// -------------------------------------------------------------------------------------------------
const cardDisplayStyleSummarySchema = {
	isFoil: PropTypes.bool,
	language: PropTypes.string,
	condition: PropTypes.string,
	price: PropTypes.number,
	quantityAvailable: PropTypes.number
};

// -------------------------------------------------------------------------------------------------
const cardDisplayVersionSummarySchema = {
	cardId: PropTypes.number.isRequired,
	cardVariation: PropTypes.string,
	cardVariationName: PropTypes.string,
	cardSetName: PropTypes.string,
	rarity: PropTypes.string,
	price: PropTypes.number,
	styles: PropTypes.arrayOf(PropTypes.shape(cardDisplayStyleSummarySchema))
};

// -------------------------------------------------------------------------------------------------
const cardDisplaySummarySchema = {
	currentVersion: PropTypes.shape(cardDisplayVersionSummarySchema).isRequired,
	fullCard: PropTypes.shape(cardSchema).isRequired,
	otherVersions: PropTypes.arrayOf(PropTypes.shape(cardDisplayVersionSummarySchema)),
	otherVariations: PropTypes.arrayOf(PropTypes.shape(cardDisplayVersionSummarySchema))
};

// -------------------------------------------------------------------------------------------------
export {
	metagameArchetypeVariationSchema,
	metagameArchetypeSchema,
	metagameFormatPeriodSchema,
	metagameFormatPeriodCardsSchema,
	metagameCardStats,
	metagameMultiFormatCardStats,
	cardFrequencySchema,

	deckSchema,
	deckCardSchema,
	deckContentSchema,
	aggregateDeckSchema,
	aggregateDeckCardSchema,
	aggregateDeckContentSchema,
	tournamentSchema,
	tournamentOverallSchema,
	tournamentRoundResultSchema,

	cardSetSchema,

	cardSchema,
	cardDisplayStyleSummarySchema,
	cardDisplayVersionSummarySchema,
	cardDisplaySummarySchema
};