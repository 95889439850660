// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

//import React from "react";
import PropTypes from "prop-types";
//import { Link } from "react-router-dom";

// -----------------------------------------------------------------------------
// Link to list of all metagame formats.
// REVIEW
const LinkToMetagameFormats = ({ children }) => (
	(children) ? children : "<missing>"
);

//const LinkToMetagameFormats = ({ children }) => (
//	<Link to={"/metagames"}>
//		{(children) ? children : "<missing>"}
//	</Link>
//);

LinkToMetagameFormats.propTypes = {
	children: PropTypes.node.isRequired
};

export default LinkToMetagameFormats;