// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import ReactGA from "react-ga4";
import { logInternalError } from "global/utilities/logging";
import { getLocalStorage, setLocalStorage } from "global/utilities/browserStorage";

const _disableAnalyticsStorageKeyName = "disableAnalytics";
var _isAnalyticsDisabled;

// REVIEW
function initializeAnalytics() {
	if (!window.location.href.includes("localhost")) {
		const id = window.$googleAnalyticsId;

		if (id) {
			ReactGA.initialize(id);
		} else {
			logInternalError("Missing Google Analytics ID");
		}
	}
}

function isAnalyticsDisabled() {
	if (_isAnalyticsDisabled === undefined) {
		_isAnalyticsDisabled = getLocalStorage(_disableAnalyticsStorageKeyName) === "true";
	}

	return _isAnalyticsDisabled;
}

function setIsAnalyticsDisabled(isDisabled) {
	_isAnalyticsDisabled = isDisabled;
	setLocalStorage(_disableAnalyticsStorageKeyName, isDisabled);
}

export {
	initializeAnalytics,
	setIsAnalyticsDisabled,
	isAnalyticsDisabled
};