// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { PropTypes } from "prop-types";

import { formatPrice } from "./format";

// -----------------------------------------------------------------------------
// Format given price with specified number of decimals places.
// REVIEW
const Price = ({ value, decimalPlaces }) =>
	<>
		{formatPrice(value, decimalPlaces)}
	</>;

Price.propTypes = {
	value: PropTypes.number,
	decimalPlaces: PropTypes.number
};

Price.defaultProps = {
	decimalPlaces: 2
};

export default Price;