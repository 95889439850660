// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Collapse, Navbar, NavItem, NavLink, NavbarToggler, NavbarBrand, Nav } from "reactstrap";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
//import { Form, Input, InputGroup, InputGroupAddon } from "reactstrap";
import { Button } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Badge } from "react-bootstrap";
//import { Container, Row, Col } from "reactstrap";
import { Link as RoutingNavLink } from "react-router-dom";

import ComingSoonSection from "global/errors/ComingSoonSection";
//import { logFeedback } from "global/utilities/logging";
import { logInternalError } from "global/utilities/logging";
import { getLocalStorage, setLocalStorage } from "global/utilities/browserStorage";

// -------------------------------------------------------------------------------------------------
// CREATING
// TODO: Find a way to hook routing, so header can be closed in 1 place rather than many
export default function SiteHeaderBar() {
	const [isHeaderBarOpen, setIsHeaderBarOpen] = useState(false);
	const [currentTheme, setCurrentTheme] = useState(getLocalStorage("currentTheme"));
	const [isDebugModeEnabled, setIsDebugModeEnabled] = useState(getLocalStorage("isDebugModeEnabled") == "true");
	const isAdmin = getLocalStorage("isAdmin") == "true";

	//const [isSendFeedbackOpen, setIsSendFeedbackOpen] = useState();
	//const [isUserPreferencesOpen, setIsUserPreferencesOpen] = useState();

	// eslint-disable-next-line
	let searchReference = React.createRef();

	applyAppTheme(currentTheme);

	const toggleTheme = () => {
		let currentThemeName;

		if (!currentTheme || currentTheme === "light") {
			currentThemeName = "dark";
		} else {
			currentThemeName = "light";
		}

		setLocalStorage("currentTheme", currentThemeName);
		setIsHeaderBarOpen(false);
		setCurrentTheme(currentThemeName);
	};

	const toggleIsDebugModeEnabled = () => {
		const newMode = !isDebugModeEnabled;
		setLocalStorage("isDebugModeEnabled", newMode);
		setIsDebugModeEnabled(newMode);
	};

	//const search = () => {
	//};

	return (
		<header>
			<Navbar dark color="primary" expand="lg">
				<NavbarBrand to="/" tag={RoutingNavLink} style={{ padding: 0 }} onClick={() => setIsHeaderBarOpen(false)}>
					<SiteHeaderBarBrand />
				</NavbarBrand>

				<NavbarToggler onClick={() => setIsHeaderBarOpen(!isHeaderBarOpen)} />

				{/* TODO: Include menus as child components rather than directly here */}
				<Collapse isOpen={isHeaderBarOpen} navbar>
					<Nav navbar>
						<MetagameMenu setIsHeaderBarOpen={setIsHeaderBarOpen} />
						<TournamentsMenu setIsHeaderBarOpen={setIsHeaderBarOpen} />
					</Nav>

					<Nav navbar className="ms-auto">
						<span className="p-1 text-light d-none d-lg-block">
							{appVersionNumber}
							{" "}
						</span>

						<Button className="d-none d-lg-block" color="primary" onClick={toggleTheme} size="sm" style={{ cursor: "pointer" }} >
							<span className="fas fa-moon" />
						</Button>
						<NavItem className="d-block d-lg-none" >
							<NavLink style={{ cursor: "pointer" }} onClick={toggleTheme}>
								Toggle Theme
							</NavLink>
						</NavItem>

						{isAdmin &&
							<>
								<Button className="d-none d-lg-block" color={isDebugModeEnabled ? "success" : "primary"} onClick={toggleIsDebugModeEnabled} size="sm" style={{ cursor: "pointer" }} >
									<span className="fas fa-tools" />
								</Button>
								<NavItem className="d-block d-lg-none" >
									<NavLink style={{ cursor: "pointer" }} onClick={toggleIsDebugModeEnabled}>
										{isDebugModeEnabled ? "Toggle Debug Mode Off" : "Toggle Debug Mode On"}
									</NavLink>
								</NavItem>
							</>
						}

						{/*
						<Button className="d-none d-lg-block" color="primary" onClick={() => setIsSendFeedbackOpen(true)} size="sm">
							<span className="fas fa-comment" />
							{" "} 
							<Badge color="info">1</Badge>
						</Button>
						<NavItem className="d-block d-lg-none" >
							<NavLink style={{ cursor: "pointer" }} onClick={() => setIsSendFeedbackOpen(true)}>
								Send Feedback
								{" "}
								<Badge color="info">1</Badge>
							</NavLink>
						</NavItem>
						<SendFeedbackDialog
							isOpen={isSendFeedbackOpen}
							setIsOpen={setIsSendFeedbackOpen} />

						<Button className="d-none d-lg-block" color="primary" onClick={() => setIsUserPreferencesOpen(true)} size="sm">
							<span className="fas fa-cog" />
						</Button>
						<NavItem className="d-block d-lg-none" >
							<NavLink style={{ cursor: "pointer" }} onClick={() => setIsUserPreferencesOpen(true)}>
								User Preferences
							</NavLink>
						</NavItem>
						<UserPreferencesDialog
							isOpen={isUserPreferencesOpen}
							setIsOpen={setIsUserPreferencesOpen} />
						*/}

						<NavItem className="d-block d-lg-none">
							<NavLink tag={RoutingNavLink} to="/about" onClick={() => setIsHeaderBarOpen(false)}>
								About
								{" ("}
								{appVersionNumber}
								{")"}
							</NavLink>
						</NavItem>

						{/*
						<Form onSubmit={search} style={{ marginLeft: 10 }} >
							<InputGroup size="sm">
								<Input innerRef={input => searchReference = input} type="search" placeholder="Card or deck or player" />
								<InputGroupAddon addonType="append">
									<Button type="submit" onClick={search}>
										<span className="fa fa-search" />
									</Button>
								</InputGroupAddon>
							</InputGroup>
						</Form>
						*/}
					</Nav>
				</Collapse>
			</Navbar>

			{/* TODO: Move padding to margin style */}
			<br />
		</header>
	);
}

const appVersionNumber = "v0.9";

// -------------------------------------------------------------------------------------------------
function applyAppTheme(themeName) {
	const stylesheetElement = document.getElementById("theme-stylesheet");

	if (stylesheetElement) {
		let cssPath;

		if (!themeName || themeName === "light") {
			cssPath = "/css/cerulean-bootstrap.css";
		} else if (themeName === "dark") {
			cssPath = "/css/slate-bootstrap.css";
		} else {
			logInternalError("Unknown theme name: " + themeName);
			cssPath = "/css/cerulean-bootstrap.css";
		}

		stylesheetElement.href = cssPath;
	}
}

// -------------------------------------------------------------------------------------------------
function SiteHeaderBarBrand() {
	return (
		<p className="text-info" style={{ marginBottom: 0, marginTop: 5, fontWeight: 800 }}>
			MTG Top Decks
			<img
				className="d-none d-sm-block"
				src="/assets/logo.png"
				alt="Logo"
				width={40}
				height={40}
				style={{ float: "left", marginTop: -7, marginRight: 10 }} />
			{" "}
			<Badge bg="secondary" text="dark">Beta</Badge>
		</p>
	);
}

// -------------------------------------------------------------------------------------------------
function MetagameMenu({ setIsHeaderBarOpen }) {
	return (
		<UncontrolledDropdown nav>
			<DropdownToggle nav caret>
				Metagames
			</DropdownToggle>

			{/* TODO: Build menu dynamically */}
			{/* TODO: Lighten menu header text */}
			<DropdownMenu onClick={() => setIsHeaderBarOpen(false)} >
				<DropdownItem header>Constructed</DropdownItem>
				<DropdownItem to="/metagames/standard" tag={RoutingNavLink}>Standard</DropdownItem>
				<DropdownItem to="/metagames/alchemy" tag={RoutingNavLink}>Alchemy</DropdownItem>
				<DropdownItem to="/metagames/historic" tag={RoutingNavLink}>Historic</DropdownItem>
				<DropdownItem to="/metagames/pioneer" tag={RoutingNavLink}>Pioneer</DropdownItem>
				<DropdownItem to="/metagames/explorer" tag={RoutingNavLink}>Explorer</DropdownItem>
				<DropdownItem to="/metagames/modern" tag={RoutingNavLink}>Modern</DropdownItem>
				<DropdownItem to="/metagames/legacy" tag={RoutingNavLink}>Legacy</DropdownItem>
				<DropdownItem to="/metagames/vintage" tag={RoutingNavLink}>Vintage</DropdownItem>
				<DropdownItem to="/metagames/pauper" tag={RoutingNavLink}>Pauper</DropdownItem>
				{/*	<DropdownItem divider />*/}
				{/*	<DropdownItem header>Limited</DropdownItem>*/}
				{/*	<DropdownItem to="/metagames/draft" tag={RoutingNavLink}>Draft</DropdownItem>*/}
				{/*	<DropdownItem to="/metagames/sealed" tag={RoutingNavLink}>Sealed</DropdownItem>*/}
				{/*	<DropdownItem divider />*/}
				{/*	<DropdownItem to="/metagames" tag={RoutingNavLink}>All Formats</DropdownItem>*/}
				{/*	<DropdownItem to="/archetypes?search" tag={RoutingNavLink}>Archetype Search</DropdownItem>*/}
			</DropdownMenu>
		</UncontrolledDropdown>
	);
}

MetagameMenu.propTypes = {
	setIsHeaderBarOpen: PropTypes.func.isRequired
};

// -------------------------------------------------------------------------------------------------
function TournamentsMenu({ setIsHeaderBarOpen }) {
	return (
		<UncontrolledDropdown nav>
			<DropdownToggle nav caret>
				Tournaments
			</DropdownToggle>

			{/* TODO: Build menu dynamically */}
			<DropdownMenu onClick={() => setIsHeaderBarOpen(false)}>
				<DropdownItem header>Tournaments</DropdownItem>
				<DropdownItem to="/tournaments?formats=standard" tag={RoutingNavLink}>Standard</DropdownItem>
				<DropdownItem to="/tournaments?formats=alchemy" tag={RoutingNavLink}>Alchemy</DropdownItem>
				<DropdownItem to="/tournaments?formats=historic" tag={RoutingNavLink}>Historic</DropdownItem>
				<DropdownItem to="/tournaments?formats=pioneer" tag={RoutingNavLink}>Pioneer</DropdownItem>
				<DropdownItem to="/tournaments?formats=explorer" tag={RoutingNavLink}>Explorer</DropdownItem>
				<DropdownItem to="/tournaments?formats=modern" tag={RoutingNavLink}>Modern</DropdownItem>
				<DropdownItem to="/tournaments?formats=legacy" tag={RoutingNavLink}>Legacy</DropdownItem>
				<DropdownItem to="/tournaments?formats=vintage" tag={RoutingNavLink}>Vintage</DropdownItem>
				<DropdownItem to="/tournaments?formats=pauper" tag={RoutingNavLink}>Pauper</DropdownItem>
				<DropdownItem to="/tournaments" tag={RoutingNavLink}>All Tournaments</DropdownItem>
				{/*<DropdownItem divider />*/}
				{/*<DropdownItem header>Search</DropdownItem>*/}
				{/*<DropdownItem to="/tournaments?search" tag={RoutingNavLink}>Tournament Search</DropdownItem>*/}
				{/*<DropdownItem to="/decks?search" tag={RoutingNavLink}>Deck Search</DropdownItem>*/}
				{/*<DropdownItem to="/players?search" tag={RoutingNavLink}>Player Search</DropdownItem>*/}
			</DropdownMenu>
		</UncontrolledDropdown>
	);
}

TournamentsMenu.propTypes = {
	setIsHeaderBarOpen: PropTypes.func.isRequired
};

// -------------------------------------------------------------------------------------------------
function SendFeedbackDialog({ isOpen, setIsOpen }) {
	const closeDialog = () => setIsOpen(false);

	return (
		<Modal isOpen={isOpen} toggle={closeDialog}>
			<ModalHeader toggle={closeDialog}>
				Feedback
			</ModalHeader>

			<ModalBody>
				<ComingSoonSection />
				{/* TODO: Think about scrolling / filtering or hiding old */}
				{/*<Container className="p-0">
					<Row className="m-1">
						<Col xs={{ size: 9 }} style={{ borderRadius: "10px", backgroundColor: "#e9ecef" }}>
							<small>
								{"Your page sucks! The metagame is wrong! There's no prices! Don't quit your day job!"}
							</small>
						</Col>
					</Row>
					<Row className="m-1">
						<Col xs={{ size: 9, offset: 3 }} style={{ borderRadius: "10px", backgroundColor: "#2FA4E7", color: "#FFF" }}>
							<small>
								{"Thank you for feedback. Come again!"}
							</small>
						</Col>
					</Row>
				</Container>*/}
			</ModalBody>

			<ModalFooter>
				<Button color="primary" onClick={closeDialog}>Send</Button>
				{" "}
				<Button color="secondary" onClick={closeDialog}>Cancel</Button>
			</ModalFooter>
		</Modal>
	);
}

SendFeedbackDialog.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired
};

//<Modal isOpen={this.state.isSendFeedbackOpen} toggle={toggleSendFeedback}>
//	<ModalHeader toggle={this.toggleSendFeedback}>Feedback</ModalHeader>
//	<ModalBody>
//		{/* <Container className="p-0">
//										<Row className="m-1">
//											<Col xs={{ size: 9 }} style={{ borderRadius: "10px", backgroundColor: "#e9ecef" }}>
//												<small>
//													{"Your page sucks! The metagame is wrong! There's no prices! Don't quit your day job!"}
//												</small>
//											</Col>
//										</Row>

//										<Row className="m-1">
//											<Col xs={{ size: 9, offset: 3 }} style={{ borderRadius: "10px", backgroundColor: "#2FA4E7", color: "#FFF" }}>
//												<small>
//													Thank you for feedback. Come again!
//												</small>
//											</Col>
//										</Row>
//									</Container>

//									<hr /> */}

//		<Form>
//			<FormGroup
//				className="m-0">

//				<Input
//					autoFocus
//					rows="4"
//					innerRef={input => this.feedbackReference = input}
//					type="textarea"
//					name="feedback"
//					id="feedbackText" />
//			</FormGroup>
//		</Form>
//	</ModalBody>

//	<ModalFooter>
//		<Button color="primary" onClick={this.onSendFeedback}>Send</Button>
//		{" "}
//		<Button color="secondary" onClick={this.toggleSendFeedback}>Cancel</Button>
//	</ModalFooter>
//</Modal>

// -------------------------------------------------------------------------------------------------
function UserPreferencesDialog({ isOpen, setIsOpen }) {
	const closeDialog = () => setIsOpen(false);

	return (
		<Modal isOpen={isOpen} toggle={closeDialog}>
			<ModalHeader toggle={closeDialog}>
				User Preferences
			</ModalHeader>

			<ModalBody>
				<p className="lead">Price Source</p>
				<ComingSoonSection />
			</ModalBody>

			<ModalFooter>
				<Button color="primary" onClick={closeDialog}>Save</Button>
				{" "}
				<Button color="secondary" onClick={closeDialog}>Cancel</Button>
			</ModalFooter>
		</Modal>
	);
}

UserPreferencesDialog.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired
};

//<Modal isOpen={this.state.isUserPreferencesOpen} toggle={toggleUserPreferences}>
//	<ModalHeader toggle={this.toggleUserPreferences}>User Preferences</ModalHeader>
//	<ModalBody>
//		<p className="lead">Price Source</p>
//		<ComingSoonSection />
//	</ModalBody>
//	<ModalFooter>
//		<Button color="primary" onClick={this.toggleUserPreferences}>Save</Button>
//		{" "}
//		<Button color="secondary" onClick={this.toggleUserPreferences}>Cancel</Button>
//	</ModalFooter>
//</Modal>
