// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

// -----------------------------------------------------------------------------
// Link to card stats within a specific format period.
// REVIEW
export const LinkToMetagameFormatPeriodCards = ({ children, formatId, formatPeriodId }) => (
	<Link to={getLinkUrl(formatId, formatPeriodId)}>
		{(children) ? children : "<missing>"}
	</Link>
);

LinkToMetagameFormatPeriodCards.propTypes = {
	children: PropTypes.node.isRequired,
	formatId: PropTypes.string.isRequired,
	formatPeriodId: PropTypes.string
};

// -----------------------------------------------------------------------------
export const ButtonToMetagameFormatPeriodCards = ({ children, formatId, formatPeriodId }) => (
	<Link to={getLinkUrl(formatId, formatPeriodId)}>
		<Button outline className="mt-3" color="primary">
			{(children) ? children : "<missing>"}
		</Button>
	</Link>
);

ButtonToMetagameFormatPeriodCards.propTypes = {
	children: PropTypes.node.isRequired,
	formatId: PropTypes.string.isRequired,
	formatPeriodId: PropTypes.string
};

// -----------------------------------------------------------------------------
function getLinkUrl(formatId, formatPeriodId) {
	const format = formatId ? encodeURIComponent(formatId) : "missing";
	const formatPeriod = formatPeriodId ? encodeURIComponent(formatPeriodId) : "";

	if (formatPeriod) {
		return `/metagames/${format}/cards?period=${formatPeriodId}`;
	} else {
		return `/metagames/${format}/cards`;
	}
}