// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// -----------------------------------------------------------------------------
// Link back to cards result page.
// REVIEW
const LinkToCards = ({ cardSetId, children }) => (
	<Link to={`/cards?set=${cardSetId}`} >
		{(children) ? children : "<missing>"}
	</Link>
);

LinkToCards.propTypes = {
	cardSetId: PropTypes.number.isRequired,
	children: PropTypes.node.isRequired
};

export default LinkToCards;