// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import unregister from "./registerServiceWorker";

// REVIEW
ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>, document.getElementById("root"));

//registerServiceWorker();
unregister();