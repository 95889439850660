// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { Badge } from "react-bootstrap";

import { constructGetMetagameSummaryUrl } from "api";
import { metagameFormatPeriodSchema } from "apiSchemas";

import FetchSection from "global/loading/FetchSection";

import LinkToMetagameFormats from "common/links/LinkToMetagameFormats";
import LinkToMetagameFormatPeriod from "common/links/LinkToMetagameFormatPeriod";
import { getFormatDisplayName } from "common/resources/formatNames";
import { getLocalStorage } from "global/utilities/browserStorage";

import HomePageMetagameTable from "./Old.HomePageMetagameTable";

const formatIds = ["standard", "pioneer", "historic", "modern", "pauper", "legacy"];

// -----------------------------------------------------------------------------
// Show metagame summary for the home page.
// REVIEW
// FUTURE: Only show preferred formats from user perferences
const HomePageMetagamePanel = () =>
	<>
		<Row>
			<Col>
				<h2 className="mt-2">
					<LinkToMetagameFormats>Top Decks</LinkToMetagameFormats>
				</h2>
			</Col>
		</Row>

		<FetchSection requestUrl={constructGetMetagameSummaryUrl(formatIds)}>
			{data =>
				<Row>
					{data && data.map(formatPeriod =>
						<MetagameFormat
							key={formatPeriod.formatPeriodId}
							formatPeriod={formatPeriod} />)
					}
				</Row>
			}
		</FetchSection>
	</>;

export default HomePageMetagamePanel;

// -----------------------------------------------------------------------------
function MetagameFormat({ formatPeriod }) {
	const isDebugModeEnabled = getLocalStorage("isDebugModeEnabled") == "true";

	return (
		<Col xs="12" lg="6" >
			<h5>
				<LinkToMetagameFormatPeriod
					formatId={formatPeriod.formatId} >

					{getFormatDisplayName(formatPeriod.formatId)}
				</LinkToMetagameFormatPeriod>
				{isDebugModeEnabled &&
					<Badge className="m-1" bg="warning" text="light">{formatPeriod.formatPeriodId}</Badge>
				}
			</h5>

			<HomePageMetagameTable formatPeriod={formatPeriod} />
		</Col>
	);

}

MetagameFormat.propTypes = {
	formatPeriod: PropTypes.shape(metagameFormatPeriodSchema).isRequired
};