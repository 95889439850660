// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

// -------------------------------------------------------------------------------------------------
// REVIEW
export function getCardSetId(cardId) {
	const setId = cardId & 0xFFF;
	return setId;
}

// -------------------------------------------------------------------------------------------------
export function getCardVariationId(cardId, variation) {
	if (variation) {
		return cardId + variation;
	} else {
		return cardId + "a";
	}
}