// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { PropTypes } from "prop-types";
import { Table } from "reactstrap";

import FormattedDate from "global/utilities/Date";
import LinkToTournament from "common/links/LinkToTournament";
import LinkToTournamentSearchResults from "common/links/LinkToTournamentSearchResults";
import TableCellItem from "common/TableCellItem";

import { tournamentSchema, tournamentOverallSchema } from "apiSchemas";
import { formatInteger } from "global/utilities/format";
import { getFormatDisplayName } from "common/resources/formatNames";

// -------------------------------------------------------------------------------------------------
// Table that shows list of linked tournament summaries.
// REVIEW
export default function TournamentsTable({ tournaments, displayOptions }) {
	const tableNotStriped = displayOptions && displayOptions.showTableNotStriped;
	const tableBordered = displayOptions && displayOptions.showTableBordered;

	return (
		<Table striped={!tableNotStriped} bordered={tableBordered} size="sm">
			<thead>
				<tr>
					<th>Tournament</th>
					<th style={{ maxWidth: "75px" }}>Date</th>
					<th className="d-none d-sm-table-cell" style={{ maxWidth: "75px" }}>Format</th>
					<th className="text-end d-none d-lg-table-cell" style={{ maxWidth: "30px" }}>Players</th>
					<th className="text-end d-none d-lg-table-cell" style={{ maxWidth: "50px" }}>Platform</th>
				</tr>
			</thead>

			<tbody>
				{tournaments && tournaments.length > 0 && tournaments.map(tournament =>
					<TournamentsTableRow
						key={tournament.overall.tournamentId}
						tournamentOverall={tournament.overall} />
				)}
				{(!tournaments || tournaments.length === 0) &&
					<tr>
						<td className="text-muted text-center" colSpan={5}>
							No tournaments found
						</td>
					</tr>
				}
			</tbody>
		</Table>
	);
}

TournamentsTable.propTypes = {
	tournaments: PropTypes.arrayOf(PropTypes.shape(tournamentSchema)).isRequired,
	displayOptions: PropTypes.object
};

// -------------------------------------------------------------------------------------------------
function TournamentsTableRow({ tournamentOverall }) {
	return (
		<tr>
			<td style={{ maxWidth: "200px" }}>
				<LinkToTournament tournamentId={tournamentOverall.tournamentId}>
					<TableCellItem>
						{tournamentOverall.tournamentName}
					</TableCellItem>
				</LinkToTournament>
			</td>

			<td className="text-nowrap" style={{ maxWidth: "75px", minWidth: "75px" }}>
				<TableCellItem>
					<FormattedDate value={tournamentOverall.date} />
				</TableCellItem>
			</td>

			<td className="text-nowrap d-none d-sm-table-cell" style={{ maxWidth: "75px", minWidth: "75px" }}>
				<TableCellItem>
					{tournamentOverall.formatIds.map(formatId =>
						<LinkToTournamentSearchResults key={formatId} formatIdFilter={formatId}>
							{getFormatDisplayName(formatId)}
							{" "}
						</LinkToTournamentSearchResults>
					)}
				</TableCellItem>
			</td>

			<td className="text-nowrap text-end d-none d-lg-table-cell" style={{ maxWidth: "50px", minWidth: "50px" }}>
				<TableCellItem>
					{getNumberOfPlayersDisplayValue(tournamentOverall)}
				</TableCellItem>
			</td>

			<td className="text-nowrap text-end d-none d-lg-table-cell" style={{ maxWidth: "100px", minWidth: "100px" }}>
				<TableCellItem>
					{getPlatformDisplayValue(tournamentOverall)}
				</TableCellItem>
			</td>
		</tr>
	);
}

TournamentsTableRow.propTypes = {
	tournamentOverall: PropTypes.shape(tournamentOverallSchema).isRequired
};

// -------------------------------------------------------------------------------------------------
function getNumberOfPlayersDisplayValue(tournamentOverall) {
	if (tournamentOverall && tournamentOverall.numberOfPlayers) {
		const players = formatInteger(tournamentOverall.numberOfPlayers);

		if (tournamentOverall.isApproximateNumberOfPlayers) {
			return "~" + players;
		} else {
			return players;
		}
	} else {
		return "-";
	}
}

function getPlatformDisplayValue(tournamentOverall) {
	if (tournamentOverall && tournamentOverall.platform) {
		if (tournamentOverall.platform === 1) {
			return "Paper";
		} else if (tournamentOverall.platform === 2) {
			return "MTGO";
		} else if (tournamentOverall.platform === 3) {
			return "MTG Arena";
		}
	}

	return "-";
}