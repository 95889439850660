// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { PropTypes } from "prop-types";

import CardElementImage from "./CardElementImage";
import { logInternalError } from "global/utilities/logging";

// -----------------------------------------------------------------------------
// A card casting cost.
// Ex: {12}{W}{W} or {2}{R} // {2}{W} or {G/W}{G/W} // {G/W}//{G/W}
// TODO: Support size
// REVIEW
const ManaCost = ({ cost, size }) => (
	<span className="text-nowrap">
		{cost ? getManaCost(cost, size) : ""}
	</span>
);

ManaCost.propTypes = {
	cost: PropTypes.string,
	size: PropTypes.string
};

export default ManaCost;

// -----------------------------------------------------------------------------
function getManaCost(manaCost, size) {
	let result = [];
	let startIndex = -1;
	let hasSecondCost = false;

	if (manaCost) {
		for (let currentIndex = 0; currentIndex < manaCost.length; ++currentIndex) {
			if (manaCost[currentIndex] === "{") {
				startIndex = currentIndex;
			} else if (manaCost[currentIndex] === "}") {
				result.push(manaCost.substr(startIndex, currentIndex - startIndex + 1));
				startIndex = -1;
			} else if (startIndex < 0 && manaCost[currentIndex] === "/") {
				if (!hasSecondCost) {
					result.push("/");
					hasSecondCost = true;
				}
			}

		}

		if (startIndex !== -1) {
			logInternalError(`Invalid mana cost: ${manaCost}`);
		}
	}

	return result.map(getManaSymbolFunction(size));
}

// -----------------------------------------------------------------------------
function getManaSymbolFunction(size) {
	if (size === "Small") {
		return getSmallManaSymbol;
	} else if (size === "Medium") {
		return getMediumManaSymbol;
	} else if (size === "Large") {
		return getLargeManaSymbol;
	} else {
		return getSmallManaSymbol;
	}
}

// -----------------------------------------------------------------------------
function getSmallManaSymbol(manaSymbol, index) {
	if (manaSymbol === "/") {
		return <React.Fragment key={index}>{"/"}</React.Fragment>;
	} else {
		return <CardElementImage key={index} symbol={manaSymbol} size="Small" />;
	}
}

// -----------------------------------------------------------------------------
function getMediumManaSymbol(manaSymbol, index) {
	if (manaSymbol === "/") {
		return <React.Fragment key={index}>{"/"}</React.Fragment>;
	} else {
		return <CardElementImage key={index} symbol={manaSymbol} size="Medium" />;
	}
}

// -----------------------------------------------------------------------------
function getLargeManaSymbol(manaSymbol, index) {
	if (manaSymbol === "/") {
		return <React.Fragment key={index}>{"/"}</React.Fragment>;
	} else {
		return <CardElementImage key={index} symbol={manaSymbol} size="Large" />;
	}
}