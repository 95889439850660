// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import PropTypes from "prop-types";
import { Table } from "reactstrap";
//import { Badge } from "reactstrap";

import { metagameFormatPeriodSchema, metagameArchetypeSchema } from "apiSchemas";

import PriceRange from "global/utilities/PriceRange";
import { formatPercentage } from "global/utilities/format";

import ColorIdentities from "common/ColorIdentities";
import LinkToMetagameArchetype from "common/links/LinkToMetagameArchetype";
import PopularityDirectionIndicator from "common/PopularityDirectionIndicator";

// -----------------------------------------------------------------------------
// Show summary of archetypes for specific format.
// REVIEW
const HomePageMetagameTable = ({ formatPeriod }) => (
	<Table striped size="sm">
		<thead>
			<tr>
				<th>Archetype</th>
				<th className="text-center" style={{ maxWidth: "50px" }}>Popularity</th>
				<th className="text-end d-none d-sm-table-cell" style={{ maxWidth: "75px" }}>Cost</th>
			</tr>
		</thead>

		<tbody>
			{(formatPeriod && formatPeriod.archetypes && formatPeriod.archetypes.length > 0) ?
				formatPeriod.archetypes.map(archetype =>
					<ArchetypeRow
						key={archetype.archetypeId}
						archetype={archetype} />) :

				// No archetypes found
				<tr className="text-muted text-center">
					<td colSpan={3}>
						No archetypes
					</td>
				</tr>
			}
		</tbody>
	</Table>
);

HomePageMetagameTable.propTypes = {
	formatPeriod: PropTypes.shape(metagameFormatPeriodSchema).isRequired
};

export default HomePageMetagameTable;

// -----------------------------------------------------------------------------
const ArchetypeRow = ({ archetype }) => (
	<tr key={archetype.archetypeId}>
		<td style={{ maxWidth: "150px" }}>
			<span style={{ display: "block", maxWidth: "100%" }} className="text-truncate">
				<LinkToMetagameArchetype
					formatId={archetype.formatId}
					formatPeriodId={archetype.formatPeriodId}
					archetypeId={archetype.archetypeId}>

					{archetype.archetypeName ? archetype.archetypeName : "<missing>"}
				</LinkToMetagameArchetype>
				{" "}
				<ColorIdentities
					identities={archetype.colorIdentities} />
			</span>
		</td>

		<td className="text-center" style={{ maxWidth: "40px" }}>
			{formatPercentage(archetype.popularity)}
			{archetype.popularityDirection ?
				<>
					{" "}
					<PopularityDirectionIndicator
						value={archetype.popularityDirection} />
				</> : null
			}
		</td>

		<td className="text-end d-none d-sm-table-cell" style={{ maxWidth: "75px" }}>
			<PriceRange
				lowerValue={archetype.lowestPrice}
				upperValue={archetype.highestPrice}
				decimalPlaces={0} />
		</td>
	</tr>
);

ArchetypeRow.propTypes = {
	archetype: PropTypes.shape(metagameArchetypeSchema).isRequired
};