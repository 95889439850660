// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import UnknownRoutePage from "global/errors/UnknownRoutePage";
import PageChangeActions from "global/utilities/PageChangeActions";
import ComingSoonPage from "global/errors/ComingSoonPage";

import SiteHeaderBar from "common/SiteHeaderBar";
import SiteFooterBar from "common/SiteFooterBar";
import AboutPage from "common/AboutPage";
import AboutPrivacyPage from "common/AboutPrivacyPage";
import AboutTermsPage from "common/AboutTermsPage";
import AboutCookiesPage from "common/AboutCookiesPage";
import AcceptCookiesPrompt from "common/AboutCookiesPrompt";

import HomePage from "modules/home/Old.HomePage";
import TournamentsPage from "modules/tournaments/TournamentsPage";
import TournamentPage from "modules/tournaments/TournamentPage";
import DeckPage from "modules/decks/DeckPage";
import DecksPage from "modules/decks/DecksPage";
import MetagameFormatPeriodPage from "modules/metagame/MetagameFormatPeriodPage";
import MetagameFormatPeriodCardsPage from "modules/metagame/MetagameFormatPeriodCardsPage";
import MetagameArchetypePage from "modules/metagame/MetagameArchetypePage";
import MetagameTopCardsPage from "modules/metagame/MetagameTopCardsPage";
//import PageMetagameFormats from "modules/metagame/PageMetagameFormats";
//import PageMetagameFormat from "modules/metagame/PageMetagameFormat";
//import PageMetagameFormatPeriod from "modules/metagame/PageMetagameFormatPeriod";
//import PageMetagameArchetype from "modules/metagame/PageMetagameArchetype";
import CardsPage from "modules/cards/CardsPage";
import CardPage from "modules/cards/CardPage";
import CardSetsPage from "modules/cards/CardSetsPage";
import InternalCardSetsPage from "modules/cards/InternalCardSetsPage";
import TrackingPage from "modules/admin/TrackingPage";

import "css/custom.css";
import "@fortawesome/fontawesome-free/css/all.css";

const queryClient = new QueryClient({ defaultOptions: { queries: { suspense: true } } });

// -----------------------------------------------------------------------------
// REVIEW
// https://blog.axlight.com/posts/diving-into-react-suspense-render-as-you-fetch-for-rest-apis/
// https://blog.logrocket.com/react-suspense-for-data-fetching/
// https://css-tricks.com/reacts-experimental-suspense-api-will-rock-for-fallback-ui-during-data-fetches/
// https://css-tricks.com/react-suspense-in-practice/
// https://rahmanfadhil.com/fetch-data-with-react-suspense/
// https://reactjs.org/docs/concurrent-mode-suspense.html
// https://jahed.dev/2020/05/17/why-you-dont-need-proptypes/
// https://create-react-app.dev/docs/adding-typescript/
// https://www.typescriptlang.org/docs/handbook/react.html
// We use cookies and other technologies to enable core functionality on our website(s) and to provide you with a personalized experience. For more information on cookies and how to manage your settings, visit the XXX Privacy Policy.
// We serve cookies on this site to analyze traffic, remember your preferences, and optimize your experience. (more details)(ok)
// ES6 - https://medium.com/@bretcameron/11-ways-to-make-your-es6-javascript-more-concise-a8b67087f06
// TypeScript - https://www.educative.io/blog/react-and-typescript
// TypeScript & React - https://github.com/typescript-cheatsheets/react#reacttypescript-cheatsheets
export default function App() {
	const cacheRegex = /_cache/;
	if (cacheRegex.test(window.location.pathname)) {
		return <div />;
	}

	return (
		<BrowserRouter>
			<QueryClientProvider client={queryClient}>
				<PageChangeActions enableAnalytics={true} enableScrollToTop={true} />
				<SiteHeaderBar />

				<main className="pageContentWrapper">
					<Routes>
						<Route path="/" element={<HomePage/>} />

						<Route path="/tournaments" element={<TournamentsPage/>} />
						<Route path="/tournaments/:tournamentId" element={<TournamentPage/>} />

						<Route path="/decks" element={<DecksPage/>} />
						<Route path="/decks/:deckId" element={<DeckPage/>} />

						<Route path="/cards" element={<CardsPage/>} />
						<Route path="/cards/:cardId" element={<CardPage/>} />
						<Route path="/cards/:cardId/:variation" element={<CardPage/>} />

						<Route path="/sets" element={<CardSetsPage/>} />
						<Route path="/sets/:setId" element={<ComingSoonPage/>} />

						<Route path="/metagames" element={<ComingSoonPage/>} />
						<Route path="/metagames/:formatId" element={<MetagameFormatPeriodPage/>} />
						<Route path="/metagames/:formatId/cards" element={<MetagameFormatPeriodCardsPage/>} />
						<Route path="/metagames/:formatId/periods" element={<ComingSoonPage/>} />
						<Route path="/metagames/:formatId/stats" element={<ComingSoonPage/>} />
						<Route path="/metagames/:formatId/history" element={<ComingSoonPage/>} />
						<Route path="/metagames/:formatId/prices" element={<ComingSoonPage/>} />

						<Route path="/top/cards" element={<MetagameTopCardsPage/>} />

						<Route path="/archetypes" element={<ComingSoonPage/>} />
						<Route path="/archetypes/:archetypeId" element={<MetagameArchetypePage/>} />

						<Route path="/feedback" element={<ComingSoonPage/>} />
						<Route path="/preferences" element={<ComingSoonPage/>} />
						<Route path="/about" element={<AboutPage/>} />
						<Route path="/privacy-policy" element={<AboutPrivacyPage/>} />
						<Route path="/terms-of-use" element={<AboutTermsPage/>} />
						<Route path="/cookies" element={<AboutCookiesPage/>} />
						<Route path="/tracking" element={<TrackingPage/>} />

						<Route path="/internal/sets" element={<InternalCardSetsPage/>} />

						<Route path="*" element={<UnknownRoutePage/>} />
					</Routes>
				</main>

				<SiteFooterBar />
				<AcceptCookiesPrompt/>
			</QueryClientProvider>
		</BrowserRouter>
	);
}