// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { PropTypes } from "prop-types";

// -----------------------------------------------------------------------------
// Wrapper class for <img> tag that supports a fallback image.
// https://stackoverflow.com/questions/34097560/react-js-replace-img-src-onerror
// https://github.com/alakarteio/react-img-fallback/blob/master/src/core.jsx
// REVIEW
class Image extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.onSourceError = this.onSourceError.bind(this);
		this.onFallbackError = this.onFallbackError.bind(this);
		this.state = {
			invalidSourceImage: false,
			invalidFallbackImage: false,
		};
	}

	onSourceError() {
		this.setState({
			invalidSourceImage: true,
		});
	}

	onFallbackError() {
		this.setState({
			invalidFallbackImage: true,
		});
	}

	render() {
		const { src, fallbackSrc, alt, ...commonProps } = this.props;
		let { invalidSourceImage, invalidFallbackImage } = this.state;

		if (!src) {
			invalidSourceImage = true;
		}

		if (!fallbackSrc) {
			invalidFallbackImage = true;
		}

		if (!invalidSourceImage) {
			return <img
				{...commonProps}
				src={src}
				alt={alt}
				onError={this.onSourceError}
			/>;
		} else if (!invalidFallbackImage) {
			return <img
				{...commonProps}
				src={fallbackSrc}
				alt={alt}
				onError={this.onFallbackError}
			/>;
		} else {
			return null;
		}
	}
}

Image.propTypes = {
	src: PropTypes.string,
	fallbackSrc: PropTypes.string,
	alt: PropTypes.string
};

export default Image;