// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { PropTypes } from "prop-types";

// -------------------------------------------------------------------------------------------------
// REVIEW
// TODO: Extract CSS, https://www.robinwieruch.de/react-css-styling
export default function TableCellItem(props) {
	return (
		<span style={{ display: "inline-block", maxWidth: "100%", verticalAlign: "bottom" }} className="text-truncate">
			{props.children}
		</span>
	);
}

TableCellItem.propTypes = {
	children: PropTypes.node.isRequired
};