// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { PropTypes } from "prop-types";
import axios from "axios";

import { logInternalError } from "global/utilities/logging";
import { fetchStateEnum } from "./fetchStateEnum";

// -----------------------------------------------------------------------------
// Helper class for loading external data asynchronously.
// REVIEW
class Fetch extends React.Component {
	constructor(props) {
		super(props);

		this._cancellationToken = axios.CancelToken;
		this._cancellationTokenSource = this._cancellationToken.source();

		this.onRequestMoreData = this.onRequestMoreData.bind(this);

		this.state = {
			fetchState: fetchStateEnum.initializing,
			data: null,
			response: null,
			errorResponse: null,
			haveMoreData: false,
			previousRequestUrl: props.requestUrl,
			previousRequestParams: props.requestParams
		};
	}

	static getDerivedStateFromProps(props, state) {
		if (props.requestUrl !== state.previousRequestUrl ||
			props.requestParams !== state.previousRequestParams) {

			return {
				fetchState: fetchStateEnum.initializing,
				data: null,
				response: null,
				errorResponse: null,
				haveMoreData: false,
				previousRequestUrl: props.requestUrl,
				previousRequestParams: props.requestParams
			};
		}

		return null;
	}

	componentDidMount() {
		this.loadAsyncData(null);
	}

	/* eslint-disable no-unused-vars */
	componentDidUpdate(previousProps, previousState) {
		if (this.props.requestUrl !== previousProps.requestUrl ||
			this.props.requestParams !== previousProps.requestParams) {

			this.loadAsyncData(null);
		}
	}

	componentWillUnmount() {
		this._cancellationTokenSource.cancel("Unmounting component");
	}

	onRequestMoreData() {
		if (this.state.response &&
			this.state.response.headers &&
			this.state.response.headers["continuation-token"]) {

			const continuationToken = this.state.response.headers["continuation-token"];

			this.loadAsyncData(continuationToken);
		}
	}

	render() {
		const { fetchState, data, errorResponse, haveMoreData } = this.state;

		return this.props.children(
			fetchState, data, errorResponse, haveMoreData, this.onRequestMoreData);
	}

	loadAsyncData(continuationToken) {
		if (!this.props.requestUrl) {
			this.setState({ fetchState: fetchStateEnum.initializing });
			return;
		}

		if (this.state.fetchState === fetchStateEnum.loading) {
			// Props changed while already loading
			this._cancellationTokenSource.cancel("Interrupting existing load");
			this._cancellationTokenSource = this._cancellationToken.source();
		} else {
			this.setState({ fetchState: fetchStateEnum.loading });
		}

		let headers = this.props.requestHeaders;

		if (continuationToken) {
			if (headers) {
				headers = this.props.requestHeaders.slice(0);
			} else {
				headers = {};
			}

			headers["Continuation-Token"] = continuationToken;
		}

		const request = {
			method: "get",
			url: this.props.requestUrl,
			params: this.props.requestParams,
			headers: headers,
			cancelToken: this._cancellationTokenSource.token
		};

		axios(request)
			.then(response => {
				this.setState({
					fetchState: fetchStateEnum.loaded,
					response: response,
					data: this.appendNewDataIfNecessary(response.data, this.state.data),
					haveMoreData: this.haveMoreData(response),
					errorResponse: null
				});
			})
			.catch(errorResponse => {
				if (!axios.isCancel(errorResponse)) {
					logInternalError(errorResponse ? errorResponse.message : "<no error response>");

					this.setState({
						fetchState: fetchStateEnum.error,
						data: null,
						response: null,
						haveMoreData: false,
						errorResponse: errorResponse
					});
				}
			});
	}

	haveMoreData(response) {
		if (response && response.headers && response.headers["continuation-token"]) {
			return true;
		} else {
			return false;
		}
	}

	appendNewDataIfNecessary(newData, currentData) {
		if (currentData && Array.isArray(currentData)) {
			if (newData) {
				return this.state.data.concat(newData);
			} else {
				return currentData;
			}
		} else {
			return newData;
		}
	}
}

Fetch.propTypes = {
	children: PropTypes.func.isRequired,
	requestUrl: PropTypes.string,
	requestParams: PropTypes.object,
	requestHeaders: PropTypes.object
};

export default Fetch;