// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { PropTypes } from "prop-types";
import { Row, Col } from "reactstrap";

import Fetch from "./Fetch";
import LoadingSpinner from "./LoadingSpinner";
import ErrorLoadingSection from "global/errors/ErrorLoadingSection";
import { fetchStateEnum } from "./fetchStateEnum";

// -----------------------------------------------------------------------------
// Helper class for loading section that need to fetch external data asynchronously.
// NOTE: Expected to be inside Container, not Row or Col - need to add Row to Col content
// REVIEW
const FetchSection = (props) => (
	<Fetch
		requestUrl={props.requestUrl}
		requestParams={props.requestParams}
		requestHeaders={props.requestHeaders}>

		{/* eslint-disable no-unused-vars */}
		{(fetchState, data, errorResponse, haveMoreData, requestMoreData) =>
			getContent(props.children, fetchState, data, errorResponse)
		}
	</Fetch>
);

FetchSection.propTypes = {
	children: PropTypes.func.isRequired,
	requestUrl: PropTypes.string,
	requestParams: PropTypes.object,
	requestHeaders: PropTypes.object
};

export default FetchSection;

// -----------------------------------------------------------------------------
function getContent(children, fetchState, data, errorResponse) {
	if (fetchState === fetchStateEnum.error) {
		return <Row>
			<Col>
				<ErrorLoadingSection errorMessage={errorResponse ? errorResponse.message : "<no error response>"} />
			</Col>
		</Row>;
	} else if (fetchState === fetchStateEnum.loading) {
		return <Row>
			<Col>
				<LoadingSpinner />
			</Col>
		</Row>;
	} else if (fetchState === fetchStateEnum.loaded) {
		return <React.Fragment>
			{children && children(data)}
		</React.Fragment>;
	} else {
		return null;
	}
}