// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React, { Suspense, useState } from "react";
import { PropTypes } from "prop-types";
import { Row, Col } from "reactstrap";
//import useFetch from "fetch-suspense";
//import { prefetch } from "react-suspense-fetch";

import { constructGetTournamentsUrl } from "api";
import ErrorLoadingSection from "global/errors/ErrorLoadingSection";

//import FetchSection from "global/loading/FetchSection";
import { fetchResource } from "global/loading/fetchResource";
import LoadingSpinner from "global/loading/LoadingSpinner";

import LinkToTournamentSearchResults from "common/links/LinkToTournamentSearchResults";
import { getFormatDisplayName } from "common/resources/formatNames";

import TournamentsTable from "modules/tournaments/TournamentsTable";

// -----------------------------------------------------------------------------
//const fetchData = async () => {
//	const result = await fetch(constructGetTournamentsUrl());
//	return await result.json();
//};

// -----------------------------------------------------------------------------
// Show tournaments summary for home page.
// REVIEW
export default function HomePageTournamentsPanel() {
	//const fetchFunc = async () => (await fetch(getTournamentSearchResultsApi())).json();
	//const tournaments = prefetch(fetchData);
	const apiFilterParameters = {
		maxItems: 10
	};

	const getTournamentsUrl = constructGetTournamentsUrl(apiFilterParameters);
	const tournamentsResource = fetchResource(getTournamentsUrl);
	// const [tournamentData, setTournamentData] = useState(null);

	// useEffect(() => {
	// 	(async () => {
	// 		setTournamentData(await fetchFunc());
	// 	})();
	// }, []);

	return (
		<>
			<Row>
				<Col>
					<h2 className="mt-2">
						<LinkToTournamentSearchResults>
							Recent Tournaments
						</LinkToTournamentSearchResults>
					</h2>
				</Col>
			</Row>

			<Row>
				<Col>
					<FormatSelectionBar />
				</Col>
			</Row>

			<Row>
				<Col>
					<Suspense fallback={<LoadingSpinner />}>
						{<HomePageTournamentsPanelLoader tournamentsResource={tournamentsResource} url={getTournamentsUrl} />}
					</Suspense>
				</Col>
			</Row>
			{/* <FetchSection requestUrl={getTournamentSearchResultsApi()} requestParams={queryFilters}>
				{data =>
					<Row>
						<Col>
							<TournamentSearchResults tournaments={data} />
						</Col>
					</Row>
				}
			</FetchSection> */}
		</>
	);
}

// -------------------------------------------------------------------------------------------------
function HomePageTournamentsPanelLoader({ tournamentsResource, url }) {
	const [tournaments, setTournaments] = useState();
	const [tournamentsState, setTournamentsState] = useState();
	const [currentUrl, setCurrentUrl] = useState();

	if (currentUrl !== url) {
		setTournaments(tournamentsResource.data);
		setTournamentsState(tournamentsResource.state);
		setCurrentUrl(url);
	}

	if (!currentUrl) {
		return null;
	}

	return (
		<>
			{tournaments &&
				<TournamentsTable tournaments={tournaments} />}
			{tournamentsState && tournamentsState.isError &&
				<ErrorLoadingSection errorMessage={tournamentsState.errorMessage} />
			}
		</>
	);
}

HomePageTournamentsPanelLoader.propTypes = {
	url: PropTypes.string,
	tournamentsResource: PropTypes.object
};

// -----------------------------------------------------------------------------
function FormatSelectionBar() {
	return (
		<div className="p-2">
			{popularTournamentFormats.map((formatId, index) =>
				<span key={index}>
					{(index > 0) ? " | " : null}
					<LinkToTournamentSearchResults formatIdFilter={formatId}>
						{getFormatDisplayName(formatId)}
					</LinkToTournamentSearchResults>
				</span>)
			}
		</div>
	);
}

const popularTournamentFormats = [
	"standard",
	"alchemy",
	"historic",
	"pioneer",
	"explorer",
	"modern",
	"legacy",
	"vintage",
	"pauper",
];