// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React, { Suspense, useState } from "react";
import { PropTypes } from "prop-types";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
//import { Link as RoutingNavLink } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
//import { Nav, NavItem, NavLink } from "reactstrap";
import { Button } from "reactstrap";
import { Helmet } from "react-helmet";

import FormattedDate from "global/utilities/Date";
import PageErrorBoundary from "global/errors/PageErrorBoundary";
import ErrorLoadingSection from "global/errors/ErrorLoadingSection";
import LoadingSpinner from "global/loading/LoadingSpinner";
import LinkToHomePage from "common/links/LinkToHomePage";
import { ButtonToMetagameFormatPeriodCards } from "common/links/LinkToMetagameFormatPeriodCards";
import LinkToMetagameFormatPeriod from "common/links/LinkToMetagameFormatPeriod";
import ArchetypesTable from "./ArchetypesTable";

import { constructGetMetagameFormatPeriodUrl } from "api";
import { fetchResource } from "global/loading/fetchResource";
import { parseUrlSearchParameters } from "global/utilities/parseUrlSearchParameters";
import { getFormatDisplayName } from "common/resources/formatNames";
import { metagameFormatPeriodSchema } from "apiSchemas";
import { formatInteger } from "global/utilities/format";
import { getCurrentDateIso8601 } from "global/utilities/Date";

// -------------------------------------------------------------------------------------------------
// Page that shows list of archetypes
// CREATING
export default function MetagameFormatPeriodPage() {
	const { formatId } = useParams();
	const location = useLocation();
	const urlFilterParameters = parseUrlSearchParameters(location.search);

	return (
		<PageErrorBoundary>
			<MetagameFormatPeriodPageContents formatId={formatId} urlFilterParameters={urlFilterParameters} />
		</PageErrorBoundary>
	);
}

// -------------------------------------------------------------------------------------------------
export function MetagameFormatPeriodPageContents({ formatId, urlFilterParameters, displayOptions }) {
	const userFormatPeriodId = urlFilterParameters ? urlFilterParameters.period : null;
	const getMetagameFormatPeriodUrl = constructGetMetagameFormatPeriodUrl(formatId, userFormatPeriodId);
	const metagameFormatPeriodResource = fetchResource(getMetagameFormatPeriodUrl);

	return (
		<Container>
			<Row>
				<Col>
					<Breadcrumbs formatId={formatId} />
				</Col>
			</Row>

			<Suspense fallback={<LoadingSpinner />}>
				<Row>
					<Col>
						<MetagameFormatPeriodTableLoader
							metagameFormatPeriodResource={metagameFormatPeriodResource}
							displayOptions={displayOptions}
							url={getMetagameFormatPeriodUrl}
							userFormatPeriodId={userFormatPeriodId} />
					</Col>
				</Row>
			</Suspense>
		</Container>
	);
}

MetagameFormatPeriodPageContents.propTypes = {
	formatId: PropTypes.string,
	urlFilterParameters: PropTypes.object,
	displayOptions: PropTypes.object
};

// -------------------------------------------------------------------------------------------------
function MetagameFormatPeriodTableLoader({ metagameFormatPeriodResource, displayOptions, url, userFormatPeriodId }) {
	const [metagameFormatPeriod, setMetagameFormatPeriod] = useState();
	const [metagameFormatPeriodState, setMetagameFormatPeriodState] = useState();
	const [currentUrl, setCurrentUrl] = useState();
	const [hideHistorical, setHideHistorical] = useState(true);
	const [hasHistorical, setHasHistorical] = useState(false);

	if (currentUrl !== url) {
		setMetagameFormatPeriod(metagameFormatPeriodResource.data);
		setMetagameFormatPeriodState(metagameFormatPeriodResource.state);
		setCurrentUrl(url);
		setHasHistorical(metagameFormatPeriodResource.data &&
			metagameFormatPeriodResource.data.archetypes &&
			metagameFormatPeriodResource.data.archetypes.reduce((previous, archetype) => previous || archetype.popularity === 0, false));
	}

	if (!currentUrl) {
		return null;
	}

	return (
		<>
			{metagameFormatPeriod &&
				<MetagameFormatPeriodSummary formatPeriod={metagameFormatPeriod} userFormatPeriodId={userFormatPeriodId} />}
			{metagameFormatPeriod &&
				<ArchetypesTable formatPeriod={metagameFormatPeriod} displayOptions={displayOptions} hideHistorical={hideHistorical} />}
			{metagameFormatPeriodState && metagameFormatPeriodState.isError &&
				<ErrorLoadingSection errorMessage={metagameFormatPeriodState.errorMessage} />
			}
			{hasHistorical ?
				hideHistorical ?
					<div className="text-center">
						<Button style={{ cursor: "pointer" }} color="link" onClick={() => setHideHistorical(false)}>
							show more...
						</Button>
					</div > :
					<div className="text-center">
						<Button style={{ cursor: "pointer" }} color="link" onClick={() => setHideHistorical(true)}>
							show less...
						</Button>
					</div>
				: null
			}
		</>
	);
}

MetagameFormatPeriodTableLoader.propTypes = {
	url: PropTypes.string,
	metagameFormatPeriodResource: PropTypes.object,
	displayOptions: PropTypes.object,
	userFormatPeriodId: PropTypes.string
};

// -------------------------------------------------------------------------------------------------
function MetagameFormatPeriodSummary({ formatPeriod, userFormatPeriodId }) {
	return (
		<>
			<ul className="list-unstyled">
				<li>
					<strong>Format:</strong>
					{" "}
					{getFormatDisplayName(formatPeriod.formatId)}
				</li>

				<li>
					<strong>Period:</strong>
					{" "}
					{formatPeriod.formatPeriodName ? formatPeriod.formatPeriodName : "<unknown>"}
					{(formatPeriod.previousFormatPeriodId || formatPeriod.nextFormatPeriodId) &&
						<>
							{" ("}
							{formatPeriod.previousFormatPeriodId &&
								<LinkToMetagameFormatPeriod formatId={formatPeriod.formatId} formatPeriodId={formatPeriod.previousFormatPeriodId}>
									previous period
								</LinkToMetagameFormatPeriod>
							}
							{formatPeriod.nextFormatPeriodId &&
								<>
									{formatPeriod.previousFormatPeriodId && " | "}
									<LinkToMetagameFormatPeriod formatId={formatPeriod.formatId} formatPeriodId={formatPeriod.nextFormatPeriodId}>
										next period
									</LinkToMetagameFormatPeriod>
								</>
							}
							{")"}
						</>
					}
				</li>

				<li>
					<strong>Date Range:</strong>
					{" "}
					<FormattedDate value={formatPeriod.formatPeriodStartDate} />
					{" - "}
					<FormattedDate value={formatPeriod.formatPeriodEndDate} />
				</li>

				<li>
					<strong>Number of Decks:</strong>
					{" "}
					{formatInteger(formatPeriod.numberOfDecks)}
				</li>

				<li>
					<ButtonToMetagameFormatPeriodCards
						formatId={formatPeriod.formatId}
						formatPeriodId={userFormatPeriodId}>
						<span className="fa fa-chart-bar" />{" "}View Archetype Statistics
					</ButtonToMetagameFormatPeriodCards>
				</li>
			</ul>
		</>
	);
}

MetagameFormatPeriodSummary.propTypes = {
	formatPeriod: PropTypes.shape(metagameFormatPeriodSchema),
	userFormatPeriodId: PropTypes.string
};

// -------------------------------------------------------------------------------------------------
function Breadcrumbs({ formatId }) {
	const formatName = getFormatDisplayName(formatId);
	const rootDomain = `${window.location.protocol}//${window.location.hostname}`;
	const title = `Metagame (${formatName}) | Magic the Gathering | MTG Top Decks`;
	const shortTitle = `Metagame (${formatName})`;
	const description = `List of archetypes for Magic the Gathering tournaments in ${formatName} format`;
	const keywords = `Magic the Gathering, cards, archetypes, metagame, decks, tournaments`;
	const date = getCurrentDateIso8601();
	const breadcrumbsLdJson = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement:": [{
			"@type": "ListItem",
			"position": 1,
			"name": "Home",
			"item": rootDomain
		},
		{
			"@type": "ListItem",
			"position": 2,
			"name": "Metagames",
			"item": rootDomain + "/metagames"
		},
		{
			"@type": "ListItem",
			"position": 3,
			"name": formatName
		}]
	};

	const pageLdJson = {
		"@context": "https://schema.org",
		"@type": "Article",
		"description": description,
		"keywords": keywords,
		"dateCreated": date,
		"dateModified": date,
		"headline": title,
		"image": [
			rootDomain + "/assets/logo.png"
		],
		"author": [{
			"@type": "Organization",
			"name": "MTG Top Decks",
			"url": rootDomain
		}]
	};

	return (
		<>
			<Helmet>
				<title>{title}</title>
				<meta name="description" content={description} />
				<meta property="og:title" content={shortTitle} />
				<meta property="og:description" content={description} />
				<meta property="og:type" content="article" />
				<meta property="og:url" content={window.location.href} />
				<meta property="og:modified_time" content={date} />
				<meta property="og:site_name" content="MTG Top Decks" />
				<script type="application/ld+json">{JSON.stringify(breadcrumbsLdJson)}</script>
				<script type="application/ld+json">{JSON.stringify(pageLdJson)}</script>
			</Helmet>

			<Breadcrumb>
				<BreadcrumbItem><LinkToHomePage>Home</LinkToHomePage></BreadcrumbItem>
				<BreadcrumbItem active>{formatId && getFormatDisplayName(formatId)}</BreadcrumbItem>
			</Breadcrumb>
		</>
	);
}

Breadcrumbs.propTypes = {
	formatId: PropTypes.string
};