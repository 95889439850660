// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { PropTypes } from "prop-types";

import ColorIdentity from "./ColorIdentity";

// -----------------------------------------------------------------------------
// List of color identities that represent a deck archetype.
// REVIEW
// Ex: {W}{B}, +{R}
const ColorIdentities = ({ identities }) => (
	<span>
		{identities ? identities.map(getColorIdentity) : ""}
	</span>
);

ColorIdentities.propTypes = {
	identities: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default ColorIdentities;

// -----------------------------------------------------------------------------
function getColorIdentity(identity, index) {
	if (index === 0) {
		return <ColorIdentity key={index} identity={identity} />;
	} else if (identity && identity[0] === "+") {
		return <span key={index}>{"("}<ColorIdentity identity={identity.substring(1)} />{")"}</span>;
	} else {
		return <span key={index}>{"/"}<ColorIdentity identity={identity} /></span>;
	}
}