// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";

// -----------------------------------------------------------------------------
// Loading indicator for pages or panels
// REVIEW
const LoadingSpinner = () => (
	<div className="text-center mb-5 mt-3">
		<span className="fas fa-spinner fa-spin" />
		{" "}
		Loading...
	</div>
);

export default LoadingSpinner;