// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import PropTypes from "prop-types";

import { formatDate } from "./format";
import { parseDate } from "./parse";

// -----------------------------------------------------------------------------
// Format given date into user selected date format.
// REVIEW
export default function FormattedDate({ value }) {
	return (
		formatDate(value)
	);
}

FormattedDate.propTypes = {
	value: PropTypes.string
};

// -----------------------------------------------------------------------------
export function compareDates(date1, date2) {
	const convertedDate1 = parseDate(date1);
	const convertedDate2 = parseDate(date2);

	if (!convertedDate1) {
		if (!convertedDate2) {
			return 0;
		} else {
			return -1;
		}
	}

	const difference = convertedDate1.getTime() - convertedDate2.getTime();

	if (difference === 0) {
		return 0;
	} else if (difference > 0) {
		return 1;
	} else {
		return -1;
	}
}

// -----------------------------------------------------------------------------
export function getCurrentDateIso8601() {
	const today = new Date();
	const year = String(today.getFullYear());
	const month = String(today.getMonth() + 1).padStart(2, "0");
	const day = String(today.getDate()).padStart(2, "0");
	return `${year}-${month}-${day}`;
}