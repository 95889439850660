// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import PropTypes from "prop-types";

// -----------------------------------------------------------------------------
// Display green rising, red falling, or not indicator.
// REVIEW
function PopularityDirectionIndicator({ value }) {
	if (value) {
		if (value > 0) {
			return <span className="text-success fas fa-angle-up" />;
		} else if (value < 0) {
			return <span className="text-danger fas fa-angle-down" />;
		}
	}

	return null;
}

PopularityDirectionIndicator.propTypes = {
	value: PropTypes.number
};

export default PopularityDirectionIndicator;