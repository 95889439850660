// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { PropTypes } from "prop-types";
import { Container, Row, Col } from "reactstrap";

// -----------------------------------------------------------------------------
// Display error message for page failing to load.
// REVIEW
const ErrorLoadingPage = ({ errorMessage }) => (
	<Container>
		<Row>
			<Col>
				<div className="p-5 mb-4 bg-secondary rounded-3 text-center">
					<h1 className="display-3 text-primary">
						:-(
					</h1>
					<p className="lead">
						Oops, something went wrong:
					</p>
					<p>
						<code>{(errorMessage) ? errorMessage : "<no message>"}</code>
					</p>
					<p>
						This error has been logged and will be investigated by our administrators
						{" "}
						<span className="fas fa-bug" />
					</p>
				</div>
			</Col>
		</Row>
	</Container>
);

ErrorLoadingPage.propTypes = {
	errorMessage: PropTypes.string.isRequired
};

export default ErrorLoadingPage;