// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// -----------------------------------------------------------------------------
// Link to an archetype within a specific format period.
// REVIEW
const LinkToMetagameArchetype = ({ children, archetypeId, archetypeVariationId, deckId }) => (
	<Link to={getLinkUrl(archetypeId, archetypeVariationId, deckId)}>
		{(children) ? children : "<missing>"}
	</Link>
);

LinkToMetagameArchetype.propTypes = {
	children: PropTypes.node.isRequired,
	archetypeId: PropTypes.string.isRequired,
	archetypeVariationId: PropTypes.string,
	deckId: PropTypes.string
};

export default LinkToMetagameArchetype;

// -----------------------------------------------------------------------------
function getLinkUrl(archetypeId, archetypeVariationId, deckId) {
	const archetype = archetypeId ? encodeURIComponent(archetypeId) : "missing";

	const url = `/archetypes/${archetype}`;

	if (archetypeVariationId) {
		if (deckId) {
			return `${url}?variation=${encodeURIComponent(archetypeVariationId)}&deck=${encodeURIComponent(deckId)}`;
		} else {
			return `${url}?variation=${encodeURIComponent(archetypeVariationId)}`;
		}
	} else {
		return url;
	}
}