// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React, { Suspense } from "react";
import { PropTypes } from "prop-types";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Badge } from "react-bootstrap";
import { Helmet } from "react-helmet";

import PageErrorBoundary from "global/errors/PageErrorBoundary";
import ErrorLoadingSection from "global/errors/ErrorLoadingSection";
import LoadingSpinner from "global/loading/LoadingSpinner";
import Price from "global/utilities/Price";
import LinkToHomePage from "common/links/LinkToHomePage";
import LinkToCards from "common/links/LinkToCards";
import LinkToCard from "common/links/LinkToCard";
import CardImage from "common/CardImage";
import CardRarityImagePopover from "common/CardRarityImagePopover";
import ManaCost from "common/ManaCost";

import { constructGetCardUrl } from "api";
import { cardDisplaySummarySchema, cardDisplayVersionSummarySchema, cardSchema } from "apiSchemas";
import { fetchResource } from "global/loading/fetchResource";
import { getCurrentDateIso8601 } from "global/utilities/Date";

// -------------------------------------------------------------------------------------------------
// Page that shows specific version of card, and list of other versions.
// REVIEW
export default function CardPage() {
	const { cardId, variation } = useParams();

	// FUTURE: Read optional price data source name
	//const location = useLocation();
	//const urlFilterParameters = parseUrlSearchParameters(location.search);

	return (
		<PageErrorBoundary>
			<CardPageContents cardId={cardId} cardVariation={variation} />
		</PageErrorBoundary>
	);
}

// -------------------------------------------------------------------------------------------------
export function CardPageContents({ cardId, cardVariation }) {
	const getCardUrl = constructGetCardUrl(cardId, cardVariation);
	const cardResource = fetchResource(getCardUrl);

	return (
		<Container>
			<Suspense fallback={<LoadingSpinner />}>
				<CardPageContentsLoader cardResource={cardResource} />
			</Suspense>
		</Container>
	);
}

CardPageContents.propTypes = {
	cardId: PropTypes.string.isRequired,
	cardVariation: PropTypes.string,
	displayOptions: PropTypes.object
};

// -------------------------------------------------------------------------------------------------
function CardPageContentsLoader({ cardResource }) {
	const card = cardResource.data;
	const cardState = cardResource.state;

	return (
		<>
			{card && card.fullCard &&
				<Row>
					<Col>
						<Breadcrumbs fullCard={card.fullCard} />
					</Col>
				</Row>
			}
			{card &&
				<CardDetailsSections card={card} />
			}
			{cardState && cardState.isError &&
				<ErrorLoadingSection errorMessage={cardState.errorMessage} />
			}
		</>
	);
}

CardPageContentsLoader.propTypes = {
	cardResource: PropTypes.object
};

// -------------------------------------------------------------------------------------------------
export function CardDetailsSections({ card }) {
	return (
		<>
			{card && card.fullCard && card.currentVersion &&
				<Row>
					<Col>
						<CardCurrentVersionSection fullCard={card.fullCard} currentVersion={card.currentVersion} />
					</Col>
				</Row>
			}
			{card && card.fullCard && card.otherVariations && card.otherVariations.length > 0 &&
				<Row>
					<Col>
						<CardOtherVariationsSection fullCard={card.fullCard} otherVariations={card.otherVariations} />
					</Col>
				</Row>
			}
			{card && card.fullCard && card.otherVersions && card.otherVersions.length > 0 &&
				<Row>
					<Col>
						<CardOtherVersionsSection fullCard={card.fullCard} otherVersions={card.otherVersions} />
					</Col>
				</Row>
			}
		</>
	);
}

CardDetailsSections.propTypes = {
	card: PropTypes.shape(cardDisplaySummarySchema).isRequired
};

// -------------------------------------------------------------------------------------------------
function CardCurrentVersionSection({ fullCard, currentVersion }) {
	return (
		<ul className="list-unstyled">
			<li>
				<h1>
					{fullCard.cardName}
					{" "}
					<ManaCost cost={fullCard.manaCost} size="Medium" />
					{fullCard.isReserveList && <Badge pill bg="secondary" text="dark">reserved list</Badge>}
				</h1>
			</li>

			<li>
				<CardImage
					cardId={fullCard.cardId}
					variation={fullCard.cardVariation}
					multiface={fullCard.multiface} />
			</li>

			<li>
				<CardRarityImagePopover
					cardId={currentVersion.cardId}
					multiface={fullCard.multiface}
					cardVariation={currentVersion.cardVariation}
					rarity={currentVersion.rarity}
					size="Small"
					style={{ maxHeight: 16, maxWidth: 24 }}
					deckLocation="card" />
				{" "}
				{currentVersion.cardSetName}
				{currentVersion.cardVariation ? " (" + currentVersion.cardVariation + ")" : null}
				{" - "}
				<Price value={currentVersion.price} />
			</li>
		</ul>
	);
}

CardCurrentVersionSection.propTypes = {
	fullCard: PropTypes.shape(cardSchema).isRequired,
	currentVersion: PropTypes.shape(cardDisplayVersionSummarySchema).isRequired,
};

// -------------------------------------------------------------------------------------------------
function CardOtherVariationsSection({ fullCard, otherVariations }) {
	return (
		<>
			<h4>Other Variations</h4>
			<ul className="list-unstyled">
				{otherVariations.map(otherVariation =>
					<CardVersionRow
						key={otherVariation.cardVariation}
						fullCard={fullCard}
						currentVersion={otherVariation} />
				)}
			</ul>
		</>
	);
}

CardOtherVariationsSection.propTypes = {
	fullCard: PropTypes.shape(cardSchema).isRequired,
	otherVariations: PropTypes.arrayOf(PropTypes.shape(cardDisplayVersionSummarySchema)).isRequired
};

// -------------------------------------------------------------------------------------------------
function CardOtherVersionsSection({ fullCard, otherVersions }) {
	return (
		<>
			<h4>Other Sets</h4>
			<ul className="list-unstyled">
				{otherVersions.map(otherVersion =>
					<CardVersionRow
						key={otherVersion.cardId}
						fullCard={fullCard}
						currentVersion={otherVersion} />
				)}
			</ul>
		</>
	);
}

CardOtherVersionsSection.propTypes = {
	fullCard: PropTypes.shape(cardSchema).isRequired,
	otherVersions: PropTypes.arrayOf(PropTypes.shape(cardDisplayVersionSummarySchema)).isRequired
};

// -------------------------------------------------------------------------------------------------
function CardVersionRow({ fullCard, currentVersion }) {
	return (
		<li>
			<CardRarityImagePopover
				cardId={currentVersion.cardId}
				multiface={fullCard.multiface}
				cardVariation={currentVersion.cardVariation}
				rarity={currentVersion.rarity}
				size="Small"
				style={{ maxHeight: 16, maxWidth: 24 }}
				deckLocation="card" />
			{" "}
			<LinkToCard
				cardId={currentVersion.cardId}
				multiface={fullCard.multiface}
				cardVariation={currentVersion.cardVariation}>

				{currentVersion.cardSetName}
				{currentVersion.cardVariation ? " (" + currentVersion.cardVariation + ")" : null}
			</LinkToCard>
			{" - "}
			<Price
				value={currentVersion.price} />
		</li>
	);
}

CardVersionRow.propTypes = {
	fullCard: PropTypes.shape(cardSchema).isRequired,
	currentVersion: PropTypes.shape(cardDisplayVersionSummarySchema).isRequired,
};

// -------------------------------------------------------------------------------------------------
function Breadcrumbs({ fullCard }) {
	const cardSetId = fullCard.cardId & 0xFFF;
	const cardName = fullCard && fullCard.cardName ? fullCard.cardName : "<missing>";
	const cardSetName = fullCard && fullCard.cardSetName ? fullCard.cardSetName : "<missing>";
	const rootDomain = `${window.location.protocol}//${window.location.hostname}`;
	const title = `${cardName} (${cardSetName}) | Magic the Gathering | MTG Top Decks`;
	const shortTitle = `${cardName} (${cardSetName})`;
	const description = `Card details for ${cardName} (${cardSetName})`;
	const date = getCurrentDateIso8601();
	const breadcrumbsLdJson = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement:": [{
			"@type": "ListItem",
			"position": 1,
			"name": "Home",
			"item": rootDomain
		},
		{
			"@type": "ListItem",
			"position": 2,
			"name": "Home",
			"item": `${rootDomain}/cards?set=${cardSetId}`
		},
		{
			"@type": "ListItem",
			"position": 3,
			"name": cardName
		}]
	};

	return (
		<>
			<Helmet>
				<title>{title}</title>
				<meta name="description" content={description} />
				<meta property="og:title" content={shortTitle} />
				<meta property="og:description" content={description} />
				<meta property="og:url" content={window.location.href} />
				<meta property="og:modified_time" content={date} />
				<meta property="og:site_name" content="MTG Top Decks" />
				<script type="application/ld+json">{JSON.stringify(breadcrumbsLdJson)}</script>
			</Helmet>

			<Breadcrumb>
				<BreadcrumbItem><LinkToHomePage>Home</LinkToHomePage></BreadcrumbItem>
				<BreadcrumbItem><LinkToCards cardSetId={cardSetId}>Cards</LinkToCards></BreadcrumbItem>
				<BreadcrumbItem>{fullCard && fullCard.cardName ? fullCard.cardName : "<missing>"}</BreadcrumbItem>
			</Breadcrumb>
		</>
	);
}

Breadcrumbs.propTypes = {
	fullCard: PropTypes.shape(cardSchema).isRequired,
};