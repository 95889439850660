// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { PropTypes } from "prop-types";

import ErrorLoadingPage from "./ErrorLoadingPage";
import { logInternalError } from "global/utilities/logging";

// -----------------------------------------------------------------------------
// Top-level wrapper for each to catch unexpected errors to prevent page from blowing up.
// REVIEW
export default class PageErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch(error, errorInfo) {
		const userMessage = (error ? error.toString() : "<missing error message>");
		const callStack = (errorInfo && errorInfo.componentStack) ? errorInfo.componentStack : "<no call stack>";
		const debuggingMessage = userMessage + " - " + callStack;

		logInternalError(debuggingMessage);

		this.setState({
			hasError: true,
			errorMessage: userMessage
		});
	}

	render() {
		if (this.state.hasError) {
			return <ErrorLoadingPage errorMessage={this.state.errorMessage} />;
		}

		return this.props.children;
	}
}

PageErrorBoundary.propTypes = {
	children: PropTypes.node
};